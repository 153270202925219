<ds-modal #tpl [ds-modal-trigger-for]="tpl" [isModalOpen]="koreaDisclaimerState?.visible" [isDismissable]="false">
  <ds-box-header>이용 약관 (Terms & Conditions)</ds-box-header>
  <ds-box-content>
    <p>
      Aftersales Workplace (AWP)에서 사용할 수 있는 차량 정보는 개인정보로서 정보 보호와 관련된 법령상의 제한을 받습니다. Aftersales Workplace (AWP)
      에 포함된 정보는 개인정보 주체인 차량 소유자 또는 사용자가 딜러사의 서비스를 요청하고 개인정보를 딜러사에게 제공하는 데 동의하는 경우에 한하여 BMW 그룹 제품에 대한
      유지 보수 및 수리 작업을 올바르게 수행하기 위한 목적으로 사용할 수 있습니다. Aftersales Workplace (AWP)에 포함된 정보는 사적인 목적으로 사용할 수
      없으며, 합법성에 대한 증거와 해당 소유자 또는 차량 사용자의 동의 없이는 승인되지 않은 제3자에게 전달할 수 없습니다.
      위의 내용에 동의하고 Aftersales Workplace (AWP)을 실행함으로써 귀하는 본 BMW IT 시스템 사용에 적용되는 이용 약관을 읽고 이해했으며 이를 준수할 것임을
      서약합니다. 위의 이용 약관을 위반할 경우 시정 조치를 하여야 하며 이를 위반한 딜러사와 그 직원은 법에 따라 민형사상 책임을 져야 합니다.
    </p>

    <div class="mt-6x mb-3x">
      <em>--- English version ---</em>
    </div>

    <p>
      The vehicle data available in Aftersales Workplace (AWP) legally qualifies as personal data and is
      therefore subject to the legal requirements in relation to data protection. The information contained
      in Aftersales Workplace (AWP) is to be used for the correct execution of maintenance and repair
      work on BMW Group products, only with respective owner or vehicle user’s request for Dealer’s services
      and consent for provision of personal data to Dealer. Use of private purposes is not permitted. The
      data may therefore not be passed on to unauthorized third parties without proof of legitimacy and the
      consent of the respective owner or vehicle user.
      By agreeing to the above and proceeding with Aftersales Workplace (AWP), you acknowledge that you
      have read, understood and will adhere to the terms and conditions governing the use of this BMW IT
      system. Any violation of the above terms and conditions is subject to corrective actions and violating
      Dealer and its personnel shall bear civil and criminal liability in accordance with the law.
    </p>
  </ds-box-content>
  <ds-box-footer>
    <button class="mx-3x" ds-button variant="outline" (click)="reject()">Reject</button>
    <button ds-button (click)="accept()">Agree</button>
  </ds-box-footer>
</ds-modal>