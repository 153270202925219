import {Injectable} from '@angular/core';
import {CampaignLoader} from '../../campaign/services/campaign.loader';
import {CustomerLoader} from '../../customer/services/customer.loader';
import {MileageLoader} from '../../mileage/services/mileage.loader';
import {ServiceCase} from '../../service-case/models/service-case';
import {ServiceContractLoader} from '../../service-contract/services/service-contract.loader';
import {WarrantyRestrictionLoader} from '../../warranty-restriction/services/warranty-restriction.loader';

@Injectable({
  providedIn: 'root'
})
export class VehicleDataLoader {
  constructor(private campaignLoader: CampaignLoader,
              private customerLoader: CustomerLoader,
              private serviceContractLoader: ServiceContractLoader,
              private mileageLoader: MileageLoader,
              private warrantyRestrictionLoader: WarrantyRestrictionLoader) {
  }

  loadVehicleRelatedData(serviceCase: ServiceCase, skipCustomerLanguage?: boolean): Promise<boolean> {
    return new Promise(resolve => {
      // mileage needs to be loaded first, because it is relevant for service contract warnings
      this.mileageLoader.loadMileage(serviceCase)
        .then(mileageChanged => {
          Promise.all([
            this.campaignLoader.loadCampaignsInServiceCase(serviceCase),
            this.serviceContractLoader.loadServiceContractsInServiceCase(serviceCase),
            this.warrantyRestrictionLoader.loadWarrantyRestrictionsInServiceCase(serviceCase),
            skipCustomerLanguage ?
              Promise.resolve(false) :
              this.customerLoader.loadCustomerLanguageInServiceCase(serviceCase),
            this.customerLoader.loadCustomersInServiceCase(serviceCase),
            this.customerLoader.loadLicensePlateInServiceCase(serviceCase)
          ])
            .then(metadataChanged =>
              resolve(mileageChanged || metadataChanged.some(changed => changed))
            );
        });
    });
  }
}
