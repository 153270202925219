@if (serviceCase && user) {
  @if (showTabImage()) {
    <div class="tab-image">
      <img [src]="serviceCase.getSmallCosyImageUrl()" alt=""/>
      @if (serviceCase.getHasWarnings() || serviceCase.getHasSalesStop()) {
        <div id="webapp__tab-bar__warning-icons" class="warning-icons m-1x"
        >
          @if (serviceCase.getHasWarnings()) {
            <ds-icon icon="warning" [title]="getWarningsTooltip()"></ds-icon>
          }
          @if (serviceCase.getHasSalesStop()) {
            <ds-icon icon="alert" [title]="getSalesStopTooltip()"></ds-icon>
          }
        </div>
      }
      @if (serviceCase.saveEventIds.size > 0) {
        <div id="webapp__tab-bar__saving-icon" class="saving-icon m-1x">
          <ds-progress-circle variant="indeterminate"></ds-progress-circle>
        </div>
      }
    </div>
  }
  @if (serviceCase.vehicleDataMissing()) {
    <div class="reload-button">
      <button ds-button icon="refresh" size="sm" variant="ghost-muted" title="{{'common.reload' | translate}}"
              (click)="onReloadClick()"></button>
    </div>
  }
  @if (!serviceCase.loadingError) {
    <div class="tab-text">
      @if (user.getCaseTabShowTitle() || user.getServiceCaseTabLineCount() === 0) {
        <div class="text-label"
             [title]="serviceCase.getTitle() || serviceCase.getExternalId() || ('case.unsavedCase' | translate)">
          {{ serviceCase.getTitle() || serviceCase.getExternalId() || "case.unsavedCase" | translate }}
        </div>
      }
      @if (user.getCaseTabShowCustomer()) {
        <div class="text-label">
          {{ serviceCase.getCustomerName() || '-' }}
        </div>
      }
      @if (user.getCaseTabShowModel() && serviceCase.getModelDesignation() && !serviceCase.vehicleDataMissing()) {
        <div class="text-label">
          <span>{{ serviceCase.getModelDesignation() }}</span>
          @if (serviceCase.getLicensePlate()) {
            <em class="license-plate" [title]="serviceCase.getLicensePlate()">
              - {{ serviceCase.getLicensePlate() }}
            </em>
          } @else {
            <span class="text-label" [title]="serviceCase.getDevelopmentCode()">
              ({{ serviceCase.getDevelopmentCode() }})
            </span>
          }
        </div>
      }
      @if (user.getCaseTabShowVin() && serviceCase.getVinShort()) {
        <div class="text-label">
          <span>{{ serviceCase.getVinShort() }} </span>
          @if (!serviceCase.vehicleDataMissing() && serviceCase.getMileage()) {
            <em class="mileage"
                [title]="getMileageSourceText(serviceCase.getCurrentMileageSource())">
              - {{ serviceCase.getMileage() }}</em>
          }
        </div>
      }

      @if (serviceCase.vehicleDataMissing()) {
        <div class="text-label vehicle-error" title="{{'case.vehicleErrorTooltip' | translate}}">
          {{ 'case.vehicleError' | translate }}
        </div>
      }
    </div>
  }
}

@if (!serviceCase && !serviceCase.loadingError) {
  <div class="loading-indicator">
    <ds-progress-circle></ds-progress-circle>
  </div>
}

@if (serviceCase.loadingError) {
  <div class="error-indicator m-3x">
    <ds-icon icon="close" size="sm" [title]="serviceCase.loadingError"></ds-icon>
    <span class="text-label"
          [title]="('case.notificationLoadError' | translate) + ' (' + serviceCase.loadingError + ')'">
      {{ 'case.notificationLoadError' | translate }}
    </span>
  </div>
}

@if (!isTooltip) {
  <div class="tab-button">
    <button ds-button icon="close" size="sm" variant="ghost-muted" (click)="onCloseClick()">
    </button>
  </div>
  <app-service-case-reload-overlay #reloadOverlay></app-service-case-reload-overlay>
}
