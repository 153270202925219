<ds-modal #tpl
          [ds-modal-trigger-for]="tpl"
          [(isModalOpen)]="state.settingsDialogVisible"
          (modalDismiss)="closeDropdown()"
          size="lg">
  <ds-box-header>{{ 'user.settings' | translate }}</ds-box-header>
  <ds-box-content>
    <ds-tabs>

      <!-- GENERAL USER SETTINGS -->

      <ds-tabs-label for="common-settings">{{ 'user.commonSettings' | translate }}</ds-tabs-label>
      <ds-tabs-content id="common-settings">
        <form name="user-settings-common container-fluid g-4x">

          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label for="defaultApp">{{ 'user.defaultApp' | translate }}</label>
                <ds-select id="defaultApp" name="defaultApp" required
                           [(ngModel)]="userSettings.defaultApp"
                           [options]="defaultAppOptions"
                           [disabled]="backendCallPending"
                           [isFilterable]="false"
                ></ds-select>
              </ds-form-field>
            </div>
            <div class="col">
              <ds-form-field>
                <label ds-label for="systemLanguage">{{ 'user.language' | translate }}</label>
                <ds-select id="systemLanguage" name="systemLanguage" required
                           [(ngModel)]="userSettings.systemLanguage"
                           [options]="systemLanguageOptions"
                           [disabled]="backendCallPending"
                ></ds-select>
              </ds-form-field>
            </div>
          </div>

          <!-- selectedCountry and selectedBrands can only be set in B2E -->
          @if (userService.userSubject.getValue()?.getContext() === 'B2E') {
            <div class="row">
              <div class="col">
                <ds-form-field>
                  <label ds-label for="country">{{ 'user.selectedCountry' | translate }}</label>
                  <ds-select id="country" name="country" required
                             [(ngModel)]="userSettings.selectedCountry"
                             [options]="countryOptions"
                             [disabled]="backendCallPending"
                  ></ds-select>
                </ds-form-field>
              </div>
              <div class="col">
                <ds-form-field>
                  <label ds-label for="brands">{{ 'user.selectedBrands' | translate }}</label>
                  <ds-select id="brands" name="brands" required
                             [(ngModel)]="userSettings.selectedBrands"
                             [options]="brandOptions"
                             [isMultiselect]="true"
                             [isFilterable]="false"
                             [disabled]="backendCallPending"
                  ></ds-select>
                </ds-form-field>
                @if (hasBrandsError()) {
                  <ds-message
                    class="brands-warning"
                    tone="caution"
                    variant="slim"
                    [isDismissable]="false"
                    [messageText]="'user.selectedBrandsError' | translate"
                  ></ds-message>
                }
              </div>
            </div>
          }

          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label for="checkForExistingServiceCases"
                >{{ 'user.checkForExistingServiceCases' | translate }}</label>
                <ds-radio-group id="checkForExistingServiceCases" variant="inline">
                  <label ds-label for="yes">{{ 'common.yes' | translate }}</label>
                  <input ds-input id="yes" type="radio" name="checkForExistingServiceCases" required
                         [value]="false"
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.newCaseAfterVehicleIdentification"/>
                  <label ds-label for="no">{{ 'common.no' | translate }}</label>
                  <input ds-input id="no" type="radio" name="checkForExistingServiceCases" required
                         [value]="true"
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.newCaseAfterVehicleIdentification"/>
                </ds-radio-group>
              </ds-form-field>
            </div>
            <div class="col">
              <ds-form-field>
                <label ds-label for="mileageUnit">{{ 'user.mileageUnit' | translate }}</label>
                <ds-radio-group id="mileageUnit" variant="inline">
                  <label ds-label for="km">km</label>
                  <input ds-input id="km" type="radio" name="mileageUnit" value="km" required
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.mileageUnit"/>
                  <label ds-label for="mi">mi</label>
                  <input ds-input id="mi" type="radio" name="mileageUnit" value="mi" required
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.mileageUnit"/>
                </ds-radio-group>
              </ds-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label for="keyReaderId">{{ 'user.keyReaderId' | translate }}</label>
                <input ds-input id="keyReaderId" type="text" name="keyReaderId"
                       [disabled]="backendCallPending"
                       [(ngModel)]="userSettings.keyReaderId"/>
              </ds-form-field>
            </div>
            <div class="col">
              <ds-form-field>
                <label ds-label for="temperatureUnit">{{ 'user.temperatureUnit' | translate }}</label>
                <ds-radio-group id="temperatureUnit" variant="inline">
                  <label ds-label for="C">°C</label>
                  <input ds-input id="C" type="radio" name="temperatureUnit" value="C" required
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.temperatureUnit"/>
                  <label ds-label for="F">°F</label>
                  <input ds-input id="F" type="radio" name="temperatureUnit" value="F" required
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.temperatureUnit"/>
                </ds-radio-group>
              </ds-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label>{{ 'user.tabContent' | translate }}</label>
                <ds-checkbox-group>
                  <input ds-input type="checkbox" id="caseTitle" name="caseTitle"
                         [(ngModel)]="userSettings.caseTabShowTitle"/>
                  <label ds-label for="caseTitle">{{ 'case.title' | translate }}</label>
                  <input ds-input type="checkbox" id="caseCustomer" name="caseCustomer"
                         [(ngModel)]="userSettings.caseTabShowCustomer"/>
                  <label ds-label for="caseCustomer">{{ 'case.customerName' | translate }}</label>
                  <input ds-input type="checkbox" id="caseModel" name="caseModel"
                         [(ngModel)]="userSettings.caseTabShowModel"/>
                  <label ds-label for="caseModel">{{ 'user.caseTabShowModel' | translate }}</label>
                  <input ds-input type="checkbox" id="caseVin" name="caseVin"
                         [(ngModel)]="userSettings.caseTabShowVin"/>
                  <label ds-label for="caseVin">{{ 'user.caseTabShowVin' | translate }}</label>
                </ds-checkbox-group>
              </ds-form-field>
            </div>
            <div class="col">
              <ds-form-field>
                <label ds-label for="volumeUnit">{{ 'user.volumeUnit' | translate }}</label>
                <ds-radio-group id="volumeUnit" variant="inline">
                  <label ds-label for="l">l</label>
                  <input ds-input id="l" type="radio" name="volumeUnit" value="l" required
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.volumeUnit"/>
                  <label ds-label for="us_gal">US.liq.gal</label>
                  <input ds-input id="us_gal" type="radio" name="volumeUnit" value="us_gal" required
                         [disabled]="backendCallPending"
                         [(ngModel)]="userSettings.volumeUnit"/>
                </ds-radio-group>
              </ds-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button ds-button icon="bin" ds-loading id="delete"
                      (click)="deleteSettings()"
                      [isLoading]="backendCallPending"
                      [disabled]="backendCallPending">{{ 'user.deleteData' | translate }}
              </button>
            </div>
          </div>
        </form>
      </ds-tabs-content>

      <!-- DMS USER SETTINGS -->

      <ds-tabs-label for="dms-settings">{{ 'user.dmsSettings' | translate }}</ds-tabs-label>
      <ds-tabs-content id="dms-settings">
        <form name="user-settings-dms container-fluid gx-10x">
          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label for="dmsUsernameInput">{{ 'user.dmsUsername' | translate }}</label>
                <input ds-input id="dmsUsernameInput" type="text" name="dmsUsernameInput"
                       [disabled]="backendCallPending"
                       [(ngModel)]="userSettings.dmsUserId"/>
              </ds-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label for="dmsPasswordInput">{{ 'user.dmsPassword' | translate }}</label>
                <input ds-input id="dmsPasswordInput" type="text" name="dmsPasswordInput"
                       [disabled]="backendCallPending"
                       [(ngModel)]="userSettings.dmsUserPassword"/>
              </ds-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ds-form-field>
                <label ds-label for="dmsServiceAdvisorIdInput">{{ 'user.dmsServiceAdvisorId' | translate }}</label>
                <input ds-input id="dmsServiceAdvisorIdInput" type="text" name="dmsServiceAdvisorIdInput"
                       [disabled]="backendCallPending"
                       [(ngModel)]="userSettings.dmsServiceAdvisorId"/>
              </ds-form-field>
            </div>
          </div>
        </form>
      </ds-tabs-content>
    </ds-tabs>

    @if (!backendCallPending && errorMessage) {
      <ds-message
        tone="critical"
        variant="slim"
        [isDismissable]="true"
        [messageText]="errorMessage"
      ></ds-message>
    }

  </ds-box-content>

  <ds-box-footer>
    <button ds-button variant="outline" class="m-3x" id="cancel"
            (click)="closeDropdown()">{{ 'common.cancel' | translate }}
    </button>
    <button ds-button ds-loading id="save"
            (click)="saveSettings()"
            [isLoading]="backendCallPending"
            [disabled]="hasBrandsError() || backendCallPending"
    >{{ 'common.save' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
