import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBusinessPartner} from '@service-and-repairs/awpintegrationlib';
import {VehicleCampaignCollection} from '../interfaces/vehicle-campaign-collection';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private supportedHeaders = [
    'accept-encoding',
    'accept-language',
    'access_token',
    'auth-app-id',
    'auth-brands',
    'auth-buno',
    'auth-country',
    'auth-dist-partner',
    'auth-outlet',
    'auth-session',
    'authorization',
    'authscheme',
    'authtype',
    'cache-control',
    'cavors-request-id',
    'cavors-session-id',
    'content-type',
    'cookie',
    'cssl-authorization',
    'origin',
    'pragma',
    'referer',
    'user-agent',
    'x-app-authorization',
    'x-requesting-application'
  ];

  private headers = new HttpHeaders();

  constructor(private httpClient: HttpClient) {
    this.addHeadersIfSupported({
      'AuthType': 'WEN_TOKEN',
      'Content-Type': 'application/json',
      'Auth-App-Id': 'awp'
    });
  }

  getCampaigns(vin: string,
               context: string,
               language: string,
               country: string,
               businessPartner?: IBusinessPartner): Promise<VehicleCampaignCollection> {
    this.setHeader(context, country, businessPartner);
    return new Promise((resolve, reject) => {
      this.httpClient.get(
        this.getUrl(context) + `?vin=${vin}&language=${language}&appId=awp`,
        {
          headers: this.headers,
          withCredentials: true
        }
      )
        .subscribe({
          next: (campaigns: VehicleCampaignCollection) => resolve(campaigns),
          error: reason => reject(reason)
        });
    });
  }

  private getUrl(context: string): string {
    return '/api/air-services/technical-campaigns/' + (context === 'B2D' ? 'b2xinternet' : 'b2xintranet');
  }

  private setHeader(context: string, country: string, businessPartner?: IBusinessPartner): void {
    this.addHeadersIfSupported({
      'AuthScheme': context
    });

    switch (context) {
      case 'B2D':
      case 'B2D_INTERNAL': {
        this.addHeadersIfSupported({
          'Auth-Dist-Partner': businessPartner.getDistributionPartnerNumber(),
          'Auth-Outlet': businessPartner.getOutletNumber()
        });
        break;
      }
      case 'B2E': {
        this.addHeadersIfSupported({
          'Auth-Brands': 'ALL', // selected Brands are not accepted from AIR
          'Auth-Country': country
        });
        break;
      }
    }
  }

  private addHeadersIfSupported(headers: {}): void {
    Object.keys(headers).forEach(key => {
      if (this.supportedHeaders.indexOf(key.toLowerCase()) >= 0) {
        this.headers = this.headers.set(key, headers[key]);
      }
    });
  }
}
