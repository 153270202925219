import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  outletConfigurationVisible: boolean;

  constructor(private userService: UserService) {
    userService.userSubject.subscribe(() => this.outletConfigurationVisible = this.userService.userIsDealerAdmin());
  }
}
