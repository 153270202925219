import {Injectable} from '@angular/core';
import {ServiceCase} from '../../service-case/models/service-case';
import {UserService} from '../../user/services/user.service';
import {VehicleWarningLoader} from '../../vehicle-warning/services/vehicle-warning.loader';
import {WarrantyRestriction} from '../interfaces/warranty-restriction';
import {WarrantyRestrictionService} from './warranty-restriction.service';

@Injectable({
  providedIn: 'root'
})
export class WarrantyRestrictionLoader {

  constructor(private warrantyRestrictionService: WarrantyRestrictionService,
              private userService: UserService,
              private vehicleWarningLoader: VehicleWarningLoader) {
  }

  loadWarrantyRestrictionsInServiceCase(serviceCase: ServiceCase): Promise<boolean> {
    if (!serviceCase.getVinLong()) {
      return Promise.resolve(this.updateWarrantyRestrictionsInServiceCase(serviceCase, null));
    }

    const user = this.userService.userSubject.getValue();
    return new Promise(resolve => {
      this.warrantyRestrictionService.getWarrantyRestrictions(
        serviceCase.getVinLong(),
        user.getLocale(),
        user.getB2XCountryCode()
      )
        .then(warrantyRestrictions =>
          resolve(this.updateWarrantyRestrictionsInServiceCase(
            serviceCase,
            warrantyRestrictions
          ))
        )
        .catch(errors => {
          this.logBackendErrors(errors);
          resolve(this.updateWarrantyRestrictionsInServiceCase(serviceCase, undefined));
        });
    });
  }

  private updateWarrantyRestrictionsInServiceCase(serviceCase: ServiceCase,
                                                  warrantyRestrictions: WarrantyRestriction[]): boolean {
    serviceCase.setWarrantyRestrictions(warrantyRestrictions);
    return this.vehicleWarningLoader.addWarrantyRestrictionWarningsToServiceCase(serviceCase);
  }

  private logBackendErrors(errors: any[]): void {
    errors?.forEach((error: any) => console.log(error.message));
  }
}
