import {Injectable} from '@angular/core';
import {Brand, IOutletConfiguration} from '@service-and-repairs/awpintegrationlib';
import {ConfigurationLoader} from '../core/configuration/services/configuration.loader';
import {UserData} from '../core/user/models/user-data';
import {UserService} from '../core/user/services/user.service';
import {ThinClientService} from './thin-client.service';

@Injectable({
  providedIn: 'root'
})
export class RouteQueryParamService {
  constructor(private configurationLoader: ConfigurationLoader,
              private userService: UserService) {
  }

  static getAirBrands(user: UserData): string {
    const brands = user?.getSelectedBrands();
    if (brands === null || brands === undefined) {
      return '';
    }
    return brands
      .filter(b => b && b !== Brand.UNKNOWN)
      .map(RouteQueryParamService.toAirBrand)
      .join(',');
  }

  private static addParamIfAbsent(params: any, key: string, value: string | boolean): void {
    if (!params[key]) {
      Object.assign(params, {[key]: value});
    }
  }

  private static toAirBrand(brand: Brand): string {
    switch (brand) {
      case Brand.BMW:
        return 'BMW';
      case Brand.MINI:
        return 'MINI';
      case Brand.MOT:
        return 'BMW MOTORRAD';
      case Brand.BMW_i:
        return 'BMWi';
      case Brand.RR:
        return 'ROLLS ROYCE';
      case Brand.C_1:
        return 'C_1';
      case Brand.ZINORO:
        return 'ZINORO';
      case Brand.TOYOTA:
        return 'TOYOTA';
      case Brand.HUSQVARNA:
        return 'HUSQVARNA';
      case Brand.UNKNOWN:
        throw new Error('Brand.UNKNOWN cannot be mapped to an AIR brand');
    }
  }

  private static getAirMileageUnit(user: UserData): string {
    if (user?.getMileageUnit() === 'mi') {
      return 'MILE';
    } else {
      return 'KILOMETER';
    }
  }

  private static getAirRoles(outletConfig: IOutletConfiguration): string {
    const airRoles: string[] = [];
    if (outletConfig.getHasDmsWriteAccess()) {
      airRoles.push('R32');
    }
    if (outletConfig.getHasDmsReadAccess()) {
      airRoles.push('R33');
    }
    if (outletConfig.getIsDmsSearchForLicensePlateEnabled()) {
      airRoles.push('R36');
    }

    return airRoles.join(',');
  }

  addMandatoryAirParams(airParams: any): void {
    const user = this.userService.userSubject.getValue();
    RouteQueryParamService.addParamIfAbsent(airParams, 'LANGUAGE', user?.getLocale());
    RouteQueryParamService.addParamIfAbsent(airParams, 'CALLING_APPLICATION', 'AFTERSALES_WORKPLACE');
    RouteQueryParamService.addParamIfAbsent(airParams, 'DISTANCE_UNIT', RouteQueryParamService.getAirMileageUnit(user));
    if (ThinClientService.thinClientMode) {
      RouteQueryParamService.addParamIfAbsent(airParams, 'PROFILE', 'AWPCLIENT');
    } else {
      RouteQueryParamService.addParamIfAbsent(airParams, 'PROFILE', 'AWP');
    }
    if (user?.getContext() !== 'B2E') {
      RouteQueryParamService.addParamIfAbsent(
        airParams,
        'VPNR',
        user?.getBusinessPartner()?.getDistributionPartnerNumber()
      );
      RouteQueryParamService.addParamIfAbsent(airParams, 'OUTLET', user?.getBusinessPartner()?.getOutletNumber());
    } else {
      RouteQueryParamService.addParamIfAbsent(airParams, 'BRANDS', RouteQueryParamService.getAirBrands(user));
      RouteQueryParamService.addParamIfAbsent(airParams, 'COUNTRY', user?.getSelectedCountry());
    }
    this.applyOutletConfigToAirParams(airParams);
  }

  private applyOutletConfigToAirParams(airParams: any): void {
    const outletConfig = this.configurationLoader.outletConfigurationForClientLib;
    if (outletConfig) {
      RouteQueryParamService.addParamIfAbsent(airParams, 'ALLOW_FRU_VALUE', outletConfig.getShowFlatRateUnitAmount());
      RouteQueryParamService.addParamIfAbsent(airParams, 'ALLOW_GDPR', outletConfig.getIsGdprEnabled());
      RouteQueryParamService.addParamIfAbsent(
        airParams,
        'ALLOW_SERVICE_HISTORY_DELETION',
        outletConfig.getIsServiceHistoryDeletionEnabled()
      );
      if (ThinClientService.thinClientMode) {
        RouteQueryParamService.addParamIfAbsent(airParams, 'ROLES', RouteQueryParamService.getAirRoles(outletConfig));
      }
    }
  }
}
