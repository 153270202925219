import {IOidcToken, OidcToken as LibOidcToken} from '@service-and-repairs/awpintegrationlib';

/**
 * This class fully resembles how we store WEN OIDC tokens in the browser's local storage.
 * The field names match the JSON structure from WEN, so the snake_case is intentional here.
 */
export class OidcToken {
  access_token: string; // Around 25-30 characters
  access_token_timestamp_ms: number; // Timestamp in milliseconds the access token was stored at (AWP specific)
  scope: string; // Something like "address b2d openid organization profile bmwids email b2xroles"
  id_token: string; // JWT holding basic identity information, but no roles
  token_type: string; // "Bearer"
  expires_in: number; // Seconds
  refresh_token: string; // Around 25-30 characters
  refresh_token_timestamp_ms: number;  // Timestamp in milliseconds the refresh token was stored at (AWP specific)
  business_context: string; // B2D | B2D_INTERNAL | B2E (AWP specific)

  /**
   * Reads the OIDC token for the currently active business context from local storage
   * and returns it as an instance of OidcToken.
   * @param b2xContext B2D | B2D_INTERNAL | B2E
   * @return The OIDC token from local storage. If no such token exists, an empty token is returned, i.e. not null and not undefined.
   */
  static readFromStorage(b2xContext: string): OidcToken {
    const itemKey = 'oidcToken_' + b2xContext;
    const stringItemFromStorage = window.sessionStorage.getItem(itemKey);
    const parsedItemFromStorage = JSON.parse(stringItemFromStorage);
    return Object.assign(new OidcToken(), parsedItemFromStorage);
  }

  static deleteFromStorage(b2xContext: string): void {
    window.sessionStorage.removeItem('oidcToken_' + b2xContext);
  }

  writeToStorage(): void {
    const storageKey = 'oidcToken_' + this.business_context;
    const storageValue = JSON.stringify(this);
    window.sessionStorage.setItem(storageKey, storageValue);
  }

  deleteFromStorage(): void {
    OidcToken.deleteFromStorage(this.business_context);
  }

  getHeaders(): { [key: string]: string; } {
    const headers = {};
    headers['Auth-App-Id'] = 'awp';
    if (this.business_context) {
      headers['BusinessContext'] = this.business_context;
      headers['AuthScheme'] = this.business_context.substring(0, 3);
      headers['AuthRealm'] = this.business_context === 'B2D' ? 'internetb2x' : 'intranetb2x';
    }
    if (this.access_token) {
      headers['authorization'] = 'Bearer ' + this.access_token;
      headers['access_token'] = this.access_token; // No "Bearer" prefix to avoid conflicts with CSSL
      headers['AuthType'] = 'WEN_TOKEN';
    }
    return headers;
  }

  getAuthorizationHeader(): { [key: string]: string; } {
    return {authorization: 'Bearer ' + this.access_token};
  }

  toLibToken(): IOidcToken {
    const output: IOidcToken = new LibOidcToken();
    output.setAccessToken(this.access_token);
    output.setIdToken(this.id_token);
    output.setBusinessContext(this.business_context);
    output.setScopes(this.scope);
    return output;
  }
}
