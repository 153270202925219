@if (selectedTab.showLoadingIndicator || selectedTab.showErrorIndicator) {
  <div class="message-container p-3x">
    @if (selectedTab.showLoadingIndicator) {
      <ds-progress-circle class="mx-3x"></ds-progress-circle>
      <p>{{ 'common.viewLoading' | translate }}</p>
    } @else if (selectedTab.showErrorIndicator) {
      <ds-message
        tone="critical"
        variant="slim"
        class="my-4x"
        [isDismissable]="false"
        [messageText]="(selectedTab.titleResource | translate) + ': ' + ('common.viewLoadingError' | translate)"
      ></ds-message>
    }
  </div>
}

<ng-content></ng-content>
