<ds-navigation-bar [(isExpanded)]="expanded"
                   (isExpandedChange)="onExpandedChange()"
                   [items]="navigationItems">
</ds-navigation-bar>

@if (expanded) {
  <div class="support-information p-1x">
    <div
      class="session-id text-smallprint">{{ supportInformationService.cavorsSessionId }}
    </div>
    <div class="date-and-time text-smallprint">{{ supportInformationService.currentDateAndTime?.replace('GMT', '') }}
    </div>
  </div>
}
