import {TranslateService} from '@ngx-translate/core';
import {ColDef, ICellRendererFunc, ValueFormatterParams} from 'ag-grid-community';
import {KeyData} from '../../../../core/keydata/interfaces/key-data';

export function getDefaultColumnDefinition(translate: TranslateService,
                                           isKeyDataOutdated: (keyData: KeyData) => boolean,
                                           cellRenderer: ICellRendererFunc): ColDef {
  return {
    resizable: false,
    sortable: true,
    filter: false,
    lockPosition: true,
    cellClass: params => isKeyDataOutdated(params.data)
      ? 'outdated-key-data'
      : undefined,
    tooltipValueGetter: params => isKeyDataOutdated(params.data)
      ? translate.instant('keyPool.outdatedTooltip')
      : undefined,
    cellRenderer: cellRenderer
  };
}

export function getColumnDefinitions(translate: TranslateService,
                                     mileageUnit: string,
                                     shortDateFormatter: (params: ValueFormatterParams) => string,
                                     shortDateTimeFormatter: (params: ValueFormatterParams) => string,
                                     dateComparator: (date1: string, date2: string) => number,
                                     keyOriginMapper: (params: ValueFormatterParams) => string,
                                     isKeyDataOutdated: (keyData: KeyData) => boolean): ColDef[] {
  return [
    {
      field: 'vin',
      headerName: translate.instant('vehicle.vin'),
      headerTooltip: translate.instant('vehicle.vin'),
      maxWidth: 170,
      minWidth: 170
    },
    {
      field: 'brand',
      headerName: translate.instant('case.brand'),
      headerTooltip: translate.instant('case.brand'),
      maxWidth: 140,
      minWidth: 140
    },
    {
      field: 'modelDesignation',
      headerName: translate.instant('vehicle.model'),
      headerTooltip: translate.instant('vehicle.model'),
      maxWidth: 155,
      minWidth: 155
    },
    {
      field: 'productionDate',
      headerName: translate.instant('vehicle.productionDate'),
      headerTooltip: translate.instant('vehicle.productionDate'),
      valueFormatter: shortDateFormatter,
      comparator: dateComparator,
      maxWidth: 100,
      minWidth: 100
    },
    {
      field: 'developmentSeries',
      headerName: translate.instant('vehicle.engineeringSeries'),
      headerTooltip: translate.instant('vehicle.engineeringSeries'),
      maxWidth: 85,
      minWidth: 85
    },
    {
      field: 'typeCode',
      headerName: translate.instant('vehicle.type'),
      headerTooltip: translate.instant('vehicle.type'),
      maxWidth: 80,
      minWidth: 80
    },
    {
      field: 'colorCode',
      headerName: translate.instant('keyPool.colorCode'),
      headerTooltip: translate.instant('keyPool.colorCode'),
      maxWidth: 60,
      minWidth: 60
    },
    {
      field: 'upholsteryCode',
      headerName: translate.instant('keyPool.upholsteryCode'),
      headerTooltip: translate.instant('keyPool.upholsteryCode'),
      maxWidth: 80,
      minWidth: 80
    },
    {
      field: 'totalDistance',
      headerName: mileageUnit,
      headerTooltip: mileageUnit,
      comparator: (_valueA, _valueB, nodeA, nodeB) =>
        nodeA.data.totalDistanceInKm - nodeB.data.totalDistanceInKm,
      maxWidth: 100,
      minWidth: 100
    },
    {
      field: 'readInDate',
      sort: 'desc',
      headerName: translate.instant('keyPool.readInDate'),
      headerTooltip: translate.instant('keyPool.readInDate'),
      valueFormatter: shortDateTimeFormatter,
      comparator: dateComparator,
      cellClass: params => isKeyDataOutdated(params.data)
        ? 'outdated-read-date outdated-key-data'
        : undefined,
      maxWidth: 130,
      minWidth: 130
    },
    {
      field: 'keyReaderId',
      comparator: (id1: string, id2: string) => {
        // catch missing data
        if (!id1 && !id2) {
          return 0;
        } else if (!id1) {
          return 1;
        } else if (!id2) {
          return -1;
        }
        // by default sort would be case-sensitive, which is not what we want
        return id1.toLowerCase().localeCompare(id2.toLowerCase());
      },
      resizable: true,
      flex: 1,
      headerName: translate.instant('keyPool.keyReaderId'),
      headerTooltip: translate.instant('keyPool.keyReaderId'),
      minWidth: 100
    },
    {
      field: 'keyOrigin',
      headerName: translate.instant('keyPool.keyOrigin'),
      headerTooltip: translate.instant('keyPool.keyOrigin'),
      valueFormatter: keyOriginMapper,
      maxWidth: 120,
      minWidth: 120
    }
  ];
}
