import {Component, Input} from '@angular/core';
import {ISearchOverlayTab} from '../ISearchOverlayTab';

@Component({
  selector: 'app-search-overlay-tab',
  templateUrl: './search-overlay-tab.component.html',
  styleUrls: ['./search-overlay-tab.component.scss']
})
export class SearchOverlayTabComponent {

  @Input()
  selectedTab: ISearchOverlayTab;
}
