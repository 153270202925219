import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IServerApi, MileageDataSource} from '@service-and-repairs/awpintegrationlib';
import {Observable, ReplaySubject} from 'rxjs';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {MileageRecord} from '../../mileage/interfaces/mileage-record';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {KeyData} from '../interfaces/key-data';
import {KeyDataService} from './key-data.service';

@Injectable({
  providedIn: 'root'
})
export class KeyDataLoader {

  awpLib: IServerApi;

  private keyData: KeyData[];
  keyDataChanged = new Observable<KeyData[]>();
  private keyDataChangedSubject = new ReplaySubject<KeyData[]>(1);

  private distributionPartnerNumber: string;
  private outletNumber: string;

  constructor(awpClientLibService: AwpClientLibService,
              private keyDataService: KeyDataService,
              private translate: TranslateService,
              private userService: UserService) {
    this.awpLib = awpClientLibService.serverApi;

    this.keyDataChanged = this.keyDataChangedSubject.asObservable();

    userService.userSubject.subscribe(this.updateKeyPoolIfOutletHasChanged.bind(this));
  }

  getKeyDataForVin(vin: string): KeyData | undefined {
    return this.keyData?.find(kd => kd.vin === vin);
  }

  getMileageForVin(vin: string): MileageRecord {
    const keyData = this.keyData?.find(kd => kd.vin === vin);

    return keyData
      ? {
        value: keyData.totalDistanceInKm,
        source: MileageDataSource.KEY_READER,
        vin: vin,
        recordedAt: keyData.readInDate,
        createdAt: keyData.readInDate,
        lastUpdatedAt: keyData.readInDate,
        errors: []
      }
      : undefined;
  }

  reloadKeyData(): void {
    const user = this.userService.userSubject.getValue();
    this.keyDataService.getKeyData(user.getContext(), user.getBusinessPartner())
      .then(keyData => {
        this.keyData = keyData;
        this.keyDataChangedSubject.next(keyData);
      })
      .catch(reason => {
        this.keyDataChangedSubject.error(reason);
        this.showErrorMessageOnceTranslationExists('common.keyPoolError');
      });
  }

  private updateKeyPoolIfOutletHasChanged(user: UserData): void {
    if (user?.getBusinessPartner() && this.hasOutletChanged(user)) {
      this.distributionPartnerNumber = user.getBusinessPartner().getDistributionPartnerNumber();
      this.outletNumber = user.getBusinessPartner().getOutletNumber();

      this.reloadKeyData();
    }
  }

  private hasOutletChanged(user: UserData): boolean {
    const newDistributionPartnerNumber = user?.getBusinessPartner()?.getDistributionPartnerNumber();
    const newOutletNumber = user?.getBusinessPartner()?.getOutletNumber();

    return newDistributionPartnerNumber !== this.distributionPartnerNumber ||
      newOutletNumber !== this.outletNumber;
  }

  private showErrorMessageOnceTranslationExists(key: string): void {
    this.translate.get(key).subscribe((translation: string) => {
      this.awpLib.notifyError(translation);
    });
  }
}
