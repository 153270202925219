import {Injectable} from '@angular/core';
import {skip, take} from 'rxjs/operators';
import {AuthInfoFactory} from '../../auth/auth-info-factory';
import {AuthenticationTokenBearerService} from '../../auth/authentication-token-bearer.service';
import {KeyData} from '../../core/keydata/interfaces/key-data';
import {KeyDataLoader} from '../../core/keydata/services/key-data.loader';
import {ServiceCase} from '../../core/service-case/models/service-case';
import {ServiceCaseHolder} from '../../core/service-case/models/service-case-holder';
import {UserService} from '../../core/user/services/user.service';
import {IWebComponentApp} from '../../interfaces/IApp';
import {Util} from '../../util/util';
import {DmsService} from '../dms-search/dms.service';
import {WebComponentApp} from './web-component.app';
import {WebComponentInstance} from './web-component.instance';

@Injectable({
  providedIn: 'root'
})
/**
 * This service manages web components. Web component creation and visibility is managed by WebComponentComponent,
 * which is instantiated by Angular routing each time a web component app is opened. Web components are not destroyed
 * once they have been created. Separate web components are created for all service cases.
 */
export class WebComponentService {

  // map of web component apps which have already been initialized, with their routingPath as key
  private apps: {[routingPath: string]: WebComponentApp} = {};

  private reloadTimeout: number;

  constructor(private dmsService: DmsService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService,
              private serviceCaseHolder: ServiceCaseHolder,
              private keyDataLoader: KeyDataLoader,
              private userService: UserService) {
    this.createSubscriptions();
  }

  /**
   * Check if an app is active (i.e. the user has not navigated away).
   * @param appConfiguration The configuration of the app to be checked.
   */
  static isAppActive(appConfiguration: IWebComponentApp): boolean {
    return window.location.pathname.includes(appConfiguration.routingPath);
  }

  private static deleteWebComponent(app: WebComponentApp, serviceCaseId: string) {
    app.webComponentElements[serviceCaseId].destroy();
    delete app.webComponentElements[serviceCaseId];
  }

  /**
   * Show a web component app. Will also create the web component if it does not yet exist.
   * @param serviceCaseId The ID of the service case to show.
   * @param appConfiguration The configuration of the app that should be shown.
   * @param additionalAttributes Additional attributes passed to NavigationService.navigateTo()
   */
  showApp(serviceCaseId: string, appConfiguration: IWebComponentApp, additionalAttributes?: object): void {
    this.hideAllApps();

    // for each service case, a separate web component of an app created
    // instances are identified by their service case ID, use 0 for apps without case bar so only one instance is
    // created
    if (appConfiguration.hideCaseBar) {
      serviceCaseId = '0';
    }

    if (this.userService.userSubject.getValue().isB2E()) {
      this.createOrShowWebComponent(serviceCaseId, appConfiguration, additionalAttributes);
    } else {
      // In B2D wait for configuration to load to prevent WC reload directly after the creation because of config
      // change. configChanged is a replay subject and will also send a value if config has already been loaded.
      this.dmsService.dmsSettingsChanged
        .pipe(take(1))
        .subscribe(() => this.createOrShowWebComponent(serviceCaseId, appConfiguration, additionalAttributes));
    }
  }

  private createOrShowWebComponent(serviceCaseId: string,
                                   appConfiguration: IWebComponentApp,
                                   additionalAttributes: object) {
    this.createWebComponentIfItDoesNotExist(serviceCaseId, appConfiguration, additionalAttributes);
    if (WebComponentService.isAppActive(appConfiguration)) {
      this.setWebComponentVisibility(serviceCaseId, appConfiguration.routingPath, true);
    } else {
      this.setWebComponentVisibility(serviceCaseId, appConfiguration.routingPath, false);
    }
  }

  /**
   * Hide all web component apps.
   */
  hideAllApps(): void {
    Object.values(this.apps).forEach(app =>
      Object.keys(app.webComponentElements).forEach(serviceCaseId => this.setWebComponentVisibility(
        serviceCaseId,
        app.config.routingPath,
        false
      ))
    );
  }

  /**
   * Delete all web components which belong to a certain service case.
   * @param serviceCaseId The external ID of the service case whose web components should be deleted.
   */
  deleteWebComponentsOfCase(serviceCaseId: string): void {
    Object.values(this.apps).forEach(app =>
      Object.keys(app.webComponentElements).forEach(caseId => {
        if (serviceCaseId === caseId) {
          WebComponentService.deleteWebComponent(app, caseId);
        }
      })
    );
  }

  /**
   * Init a web component app. This will register the app in this service, load source(s) and create the web component
   * in the DOM. The app will not be visible initially.
   * @param config The web component app config.
   * @param webComponentUrls The web component's source URLs.
   * @param eventListeners An object containing event listeners which should be registered on the web component.
   * @param getAttributes A function which returns attributes which should be set on the web component.
   * @return A promise which is resolved if the source(s) finished loading or rejects if loading failed.
   */
  initApp(config: IWebComponentApp,
          webComponentUrls: string[],
          eventListeners?: any,
          getAttributes?: any): Promise<void> {
    if (!this.apps[config.routingPath]) {
      this.apps[config.routingPath] = {
        config,
        webComponentUrls,
        eventListeners,
        getAttributes,
        webComponentElements: {}
      };
    }
    const app = this.apps[config.routingPath];
    if (app.appInitiating === undefined) {
      app.appInitiating = this.loadWebComponentSources(app);
    }
    return app.appInitiating;
  }

  /**
   * Load web component source(s).
   * @param app The web component app instance.
   * @return A promise which is resolved if the source(s) finished loading or rejects if loading failed.
   */
  private loadWebComponentSources(app: WebComponentApp): Promise<void> {
    return new Promise<void>(((resolve, reject) => {
      app.targetDocument = document;
      this.attachScripts(app.webComponentUrls, app.targetDocument)
        .then(() => {
          this.loadAdditionalSources(app);
          resolve();
        })
        .catch(reject);
    }));
  }

  /**
   * Retrieve additional sources and attach them to the DOM.
   * @param app The web component instance.
   */
  private loadAdditionalSources(app: WebComponentApp): void {
    app.sourceLoadingErrors = [];
    this.getAdditionalSourceUrls(app.config)
      .forEach(src => {
        this.attachScripts([src], app.targetDocument)
          .catch(() => {
            if (src.includes('live-diagnosis')) {
              app.sourceLoadingErrors.push('showLiveDiagnosisError');
            } else if (src.includes('tyre-service')) {
              app.sourceLoadingErrors.push('showTyreServiceError');
            } else if (src.includes('service-contracts')) {
              app.sourceLoadingErrors.push('showServiceContractsError');
            } else if (src.includes('check-control-messages')) {
              app.sourceLoadingErrors.push('showCheckControlMessagesError');
            } else if (src.includes('remote-key-read')) {
              app.sourceLoadingErrors.push('showRemoteKeyReadError');
            } else if (src.includes('battery-health')) {
              app.sourceLoadingErrors.push('showBatteryHealthError');
            } else if (src.includes('service-ride')) {
              app.sourceLoadingErrors.push('showServiceRideError');
            } else if (src.includes('fault-codes')) {
              app.sourceLoadingErrors.push('showFaultCodesError');
            }
          });
      });
  }

  /**
   * Returns the additional source URLs that need to be loaded.
   * @param appConfig The app configuration.
   */
  private getAdditionalSourceUrls(appConfig: IWebComponentApp): string[] {
    const context = this.userService.userSubject.getValue()?.getContext();
    let sources: string[];
    if (context === 'B2D') {
      sources = appConfig.additionalSources?.b2d;
    } else if (context === 'B2D_INTERNAL') {
      sources = appConfig.additionalSources?.b2dIntranet;
    } else {
      sources = appConfig.additionalSources?.b2e;
    }
    return sources || [];
  }

  /**
   * Create the web component if it does not yet exist.
   * @param serviceCaseId The ID of the service case to show.
   * @param appConfig The app configuration.
   * @param additionalAttributes attributes from NavigationService.navigateTo() as additional attributes on the web
   *   component
   */
  private createWebComponentIfItDoesNotExist(serviceCaseId: string,
                                             appConfig: IWebComponentApp,
                                             additionalAttributes?: object): void {
    const app = this.apps[appConfig.routingPath];
    if (!app.webComponentElements[serviceCaseId]) {
      app.webComponentElements[serviceCaseId] = new WebComponentInstance(app, additionalAttributes);
    }
  }

  /**
   * Show or hide a web component.
   * @param serviceCaseId The ID of the service case to show.
   * @param routingPath The app's routing path.
   * @param visible True if the app should be shown.
   */
  private setWebComponentVisibility(serviceCaseId: string, routingPath: string, visible: boolean): void {
    const webComponentInstance = this.apps[routingPath];
    if (webComponentInstance?.webComponentElements[serviceCaseId]) {
      webComponentInstance?.webComponentElements[serviceCaseId].setVisibility(visible);
    }
  }

  /**
   * Create subscriptions. Mind that subscriptions are also active while the web component is hidden.
   */
  private createSubscriptions(): void {
    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(() => {
      const user = this.userService.userSubject.getValue();
      if (user) {
        this.setAttributeOnWebComponents('auth-info', AuthInfoFactory.makeAuthInfo(user));
      }
      this.setAttributeOnWebComponents('headers', this.getHeaders());
    });

    this.authenticationTokenBearerService.csslTokenSubject.subscribe(() => {
      const user = this.userService.userSubject.getValue();
      if (user) {
        this.setAttributeOnWebComponents('auth-info', AuthInfoFactory.makeAuthInfo(user));
      }
    });

    if (this.userService.userSubject.getValue()?.getContext() !== 'B2E') {
      // skip first change to avoid immediate WC reload on first load
      this.dmsService.dmsSettingsChanged
        .pipe(skip(1))
        .subscribe(() => this.reloadWebComponentsThrottled());
    }

    // userSubject is a BehaviorSubject and will send a value immediately. We're only interested in changes, so skip
    // the first value.
    this.userService.userSubject.pipe(skip(1)).subscribe(() => this.reloadWebComponentsThrottled());

    this.keyDataLoader.keyDataChanged.subscribe({
      next: this.updateKeyDataAttributes.bind(this),
      error: () => {
        // do nothing if loading key data fails
      }
    });

    this.serviceCaseHolder.activeServiceCaseChanged.subscribe(() => this.onActiveCaseChange());
  }

  private onActiveCaseChange(): void {
    // vehicle related data need to be updated on vehicle details web components, as they might not
    // be available when the web components are created
    const activeServiceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    if (!activeServiceCase) {
      return;
    }

    // TODO: Is there a generic way to update (changed) attributes?
    this.updateServiceCaseOnVehicleDetails(activeServiceCase);
    this.updateServiceCaseOnServiceCaseDetails(activeServiceCase);
    this.updateServiceCaseOnLeadAndDemandOverview(activeServiceCase);
    this.updateServiceCaseOnServicePreparation(activeServiceCase);
  }

  private updateServiceCaseOnVehicleDetails(activeServiceCase: ServiceCase): void {
    const vehicleDetailsApp: WebComponentApp = Object.values(this.apps)
      .find(app => app.config.routingPath === 'vehicledetails');
    Object.keys(vehicleDetailsApp?.webComponentElements || {})
      .filter(serviceCaseId => serviceCaseId === activeServiceCase.getExternalId())
      .forEach(serviceCaseId => {
        vehicleDetailsApp.webComponentElements[serviceCaseId].setAttribute(
          'campaigns',
          activeServiceCase.getCampaigns()
        );
        vehicleDetailsApp.webComponentElements[serviceCaseId].setAttribute(
          'service-contracts',
          activeServiceCase.getServiceContracts()
        );
        vehicleDetailsApp.webComponentElements[serviceCaseId].setAttribute(
          'warranty-restrictions',
          activeServiceCase.getWarrantyRestrictions()
        );
        vehicleDetailsApp.webComponentElements[serviceCaseId].setAttribute(
          'vehicle-warnings',
          activeServiceCase.getWarnings()
        );
      });
  }

  private updateServiceCaseOnServiceCaseDetails(activeServiceCase: ServiceCase): void {
    const serviceCaseDetailsApp: WebComponentApp = Object.values(this.apps)
      .find(app => app.config.routingPath === 'casedetails');
    Object.keys(serviceCaseDetailsApp?.webComponentElements || {})
      .filter(serviceCaseId => serviceCaseId === activeServiceCase.getExternalId())
      .forEach(serviceCaseId => serviceCaseDetailsApp.webComponentElements[serviceCaseId].setAttribute(
        'active-case-data', activeServiceCase
      ));
  }

  private updateServiceCaseOnServicePreparation(activeServiceCase: ServiceCase): void {
    const servicePrepApp: WebComponentApp = Object.values(this.apps)
      .find(app => app.config.routingPath === 'servicepreparation');
    Object.keys(servicePrepApp?.webComponentElements || {})
      .filter(serviceCaseId => serviceCaseId === activeServiceCase.getExternalId())
      .forEach(serviceCaseId => servicePrepApp.webComponentElements[serviceCaseId].setAttribute(
        'gCID',
        activeServiceCase?.getGcdmCustomerId()
      ));
  }

  private updateServiceCaseOnLeadAndDemandOverview(activeServiceCase: ServiceCase): void {
    const ladApp: WebComponentApp = Object.values(this.apps)
      .find(app => app.config.routingPath === 'servicedemand');
    Object.keys(ladApp?.webComponentElements || {})
      .filter(serviceCaseId => serviceCaseId === activeServiceCase.getExternalId())
      .forEach(serviceCaseId => {
        ladApp.webComponentElements[serviceCaseId].setAttribute(
          'mileage',
          activeServiceCase?.getMileageRecord()
        );
        ladApp.webComponentElements[serviceCaseId].setAttribute(
          'mileage-in-kilometers',
          activeServiceCase?.getCurrentMileageInKilometers()
        );
      });
  }

  private setAttributeOnWebComponents(name: string, value: any): void {
    Object.values(this.apps).forEach(app =>
      Object.values(app.webComponentElements).forEach(webComponent => webComponent.setAttribute(name, value))
    );
  }

  private updateKeyDataAttributes(keyData: KeyData[]) {
    Object.values(this.apps).forEach(app => {
      if (app.config.routingPath === 'vehicledetails') {
        Object.values(app.webComponentElements).forEach(webComponent => {
          const vehicle: string = webComponent.getAttribute('vehicle');
          if (vehicle) {
            const vin: string = JSON.parse(vehicle).vin;
            const latestKeyData: KeyData = keyData?.find(extract => extract.vin === vin);
            webComponent.setAttribute('key-data-read-date', latestKeyData?.readInDate || '');
          }
        });
      }
    });
  }

  /**
   * Returns the headers a web component should add to its requests.
   */
  private getHeaders(): {[key: string]: string} {
    const headers = this.authenticationTokenBearerService.oidcTokenSubject.getValue().getHeaders();
    headers['Auth-App-Id'] = 'awp';
    headers['Cavors-Session-Id'] = sessionStorage.getItem('cavors-session-id') || '';
    return headers;
  }

  private reloadWebComponentsThrottled(): void {
    this.reloadTimeout && clearTimeout(this.reloadTimeout);
    this.reloadTimeout = window.setTimeout(() => this.reloadWebComponents(), 400);
  }

  /**
   * Destroy and recreate all web components.
   */
  private reloadWebComponents(): void {
    Object.values(this.apps).forEach((app: WebComponentApp) =>
      Object.keys(app.webComponentElements).forEach((serviceCaseId: string) => {
        if (serviceCaseId === '0' || serviceCaseId === this.serviceCaseHolder.getActiveCase()?.getExternalId()) {
          // reload active web component or web components without case immediately
          app.webComponentElements[serviceCaseId].reload();
        } else {
          // simply delete web components in other tabs, they will be reloaded once they're shown again
          WebComponentService.deleteWebComponent(app, serviceCaseId);
        }
      })
    );
  }

  /**
   * Attach script elements to the DOM, unless they already exist.
   * @param sources The source URLs of the script elements as a string array.
   * @param targetDocument The document to append the script to.
   * @return A void promise which is resolved once the scripts have loaded and fails if loading fails.
   */
  private attachScripts(sources: string[], targetDocument: Document): Promise<any[]> {
    const scriptPromises = sources.map(src => Util.attachScript(src, targetDocument));
    return Promise.all(scriptPromises);
  }
}
