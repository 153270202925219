import {Injectable} from '@angular/core';

import {IServerApi, ServerApi} from '@service-and-repairs/awpintegrationlib';

@Injectable({
  providedIn: 'root'
})
export class AwpClientLibService {
  readonly serverApi: IServerApi = new ServerApi();
}
