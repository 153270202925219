import {Queue, QueueAddOptions} from 'p-queue';
import {RunFunction} from 'p-queue/dist/queue';

export interface TaskOptions extends QueueAddOptions {
  id: string;
}

export class TaskQueue implements Queue<RunFunction, TaskOptions> {
  private queue: Array<TaskOptions & {runFunction: RunFunction}> = [];

  enqueue(runFunction: RunFunction, options?: Partial<TaskOptions>): void {
    this.removeExistingTasksForSameId(options.id);
    this.queue.push({
      id: options.id,
      runFunction
    });
  }

  dequeue(): RunFunction | undefined {
    const item = this.queue.shift();
    return item?.runFunction;
  }

  filter(_options): any[] {
    return this.queue;
  }

  get size(): number {
    return this.queue.length;
  }

  private removeExistingTasksForSameId(id: string): void {
    this.queue = this.queue.filter((task) => task.id !== id);
  }
}
