import {Injectable} from '@angular/core';
import {ActorId, IServerApi, Topic} from '@service-and-repairs/awpintegrationlib';
import {AuthenticationTokenBearerService} from '../auth/authentication-token-bearer.service';
import {ConfigurationLoader} from '../core/configuration/services/configuration.loader';
import {ServiceCaseHolder} from '../core/service-case/models/service-case-holder';
import {UserService} from '../core/user/services/user.service';
import {AwpClientLibService} from './awp-client-lib.service';

@Injectable({
  providedIn: 'root'
})
export class AwpClientLibRepublishService {
  private awpLib: IServerApi;

  constructor(libService: AwpClientLibService,
              private configurationLoader: ConfigurationLoader,
              private serviceCaseHolder: ServiceCaseHolder,
              private userService: UserService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService) {
    this.awpLib = libService.serverApi;
  }

  public init(): void {
    this.awpLib.subscribeToRequestToRepublish(
      (topic: Topic, destination: ActorId) => this.handleRepublishRequest(topic, destination)
    );
  }

  private handleRepublishRequest(topic: Topic, destination: ActorId): void {
    if (topic === Topic.ACTIVE_CASE) {
      this.awpLib.publishActiveCase(this.serviceCaseHolder.getActiveCase(), destination);
    } else if (topic === Topic.ACTIVE_USER) {
      this.awpLib.publishActiveUser(this.userService.userSubject.getValue(), destination);
    } else if (topic === Topic.OUTLET_CONFIGURATION) {
      this.awpLib.publishOutletConfiguration(
        this.configurationLoader.outletConfigurationForClientLib,
        destination
      );
    } else if (topic === Topic.OIDC_TOKEN) {
      this.awpLib.publishOidcToken(
        this.authenticationTokenBearerService.oidcTokenSubject.getValue().toLibToken(), destination);
    }
  }
}
