import {Component} from '@angular/core';
import {OrderInfo} from '@service-and-repairs/dms-api';
import {ServiceCase} from '../../../../core/service-case/models/service-case';

@Component({
  selector: 'app-case-selection',
  templateUrl: './case-selection-overlay.component.html',
  styleUrls: ['./case-selection-overlay.component.scss']
})
export class CaseSelectionOverlayComponent {
  caseList: ServiceCase[] = [];
  orderList: OrderInfo[] = [];

  private caseSelectedCallback: (serviceCase: ServiceCase) => void;
  private newCaseCallback: (newServiceCaseWithDmsData?: ServiceCase) => void;
  private cancelCallback: () => void;

  show(caseList: ServiceCase[], dmsOrders: OrderInfo[]): Promise<ServiceCase> {
    this.caseList = caseList;
    this.orderList = dmsOrders;
    return new Promise<ServiceCase>((resolve, reject) => {
      this.caseSelectedCallback = resolve;
      this.newCaseCallback = resolve;
      this.cancelCallback = reject;
    });
  }

  close(): void {
    this.clearLists();
    this.cancelCallback?.();
  }

  newCaseSelected() {
    this.clearLists();
    this.newCaseCallback?.();
  }

  caseSelected(serviceCase: ServiceCase): void {
    this.clearLists();
    this.caseSelectedCallback?.(serviceCase);
  }

  orderSelected(serviceCase: ServiceCase): void {
    this.clearLists();
    this.newCaseCallback?.(serviceCase);
  }

  private clearLists(): void {
    this.caseList = [];
    this.orderList = [];
  }
}
