<div class="button-bar">
  <input
    ds-input
    type="text"
    [(ngModel)]="orderSearchInput"
    [placeholder]="'case.dmsOrderNumber' | translate"
    (keyup.enter)="searchOrder()"
  >
  <button ds-button ds-loading icon="search"
          [isLoading]="showLoadingIndicator"
          (click)="searchOrder()"
          [disabled]="!orderSearchInput || orderSearchInput.trim().length === 0">
  </button>
</div>

@if (showErrorIndicator || showNoResultInfo) {
  <app-dms-search-message
    [errorMessage]="errorMessage"
    [showErrorIndicator]="showErrorIndicator"
    [showNoResultInfo]="showNoResultInfo">
  </app-dms-search-message>
}

@if (dcomOrderSearchResult) {
  <div>
    @if (showTooManyResultInfo) {
      <ds-message tone="caution" variant="slim"
                  [isDismissable]="false"
                  messageText="{{('dms.tooManyResults' | translate)}}">
      </ds-message>
    }
    <ag-grid-angular
      class="ag-theme-density"
      [rowData]="dcomOrderSearchResult"
      [columnDefs]="dcomSearchResultColumns"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      (gridSizeChanged)="resizeColumns()"
      (rowDoubleClicked)="selectVehicleFromDcom($event.data)"
      [enableBrowserTooltips]="true"
      domLayout="autoHeight"
      [rowHeight]="35"
      [headerHeight]="35"
    >
    </ag-grid-angular>
  </div>
}

@if (etkDmsOrderSearchResult) {
  <div>
    <ag-grid-angular
      class="ag-theme-density"
      [rowData]="etkDmsOrderSearchResult"
      [columnDefs]="etkSearchResultColumns"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      (gridSizeChanged)="resizeColumns()"
      (rowDoubleClicked)="selectVehicleFromEtkDms($event.data)"
      [enableBrowserTooltips]="true"
      domLayout="autoHeight"
      [rowHeight]="35"
      [headerHeight]="35"
    >
    </ag-grid-angular>
  </div>
}
