import {Injectable} from '@angular/core';
import PQueue from 'p-queue';
import {Job} from '../../../core/job/job';
import {ServiceCase} from '../../../core/service-case/models/service-case';
import {ServiceCaseService} from '../../../core/service-case/services/service-case.service';
import {TaskQueue} from './TaskQueue';

/**
 * A FIFO queue for sequential processing of service case updates.
 *
 * For each update, a request is sent to the backend API and the response is awaited before processing the next item in
 * the queue. When adding a service case update to the queue, any existing update with the same external service case
 * ID will be removed.
 *
 * This approach has two advantages.
 *
 * 1. Fewer requests will be sent to the backend, even when the user quickly adds a lot of items to
 * the service case in a very short time.
 *
 * 2. The UI can modify service cases with no perceived delay.
 *
 * The downside is the potential delay between the user initiating an update and learning about its definitive outcome.
 */
@Injectable({
  providedIn: 'root'
})
export class ServiceCaseUpdateQueueService {

  private queue = new PQueue({
    queueClass: TaskQueue,
    concurrency: 1
  });

  constructor(private serviceCaseService: ServiceCaseService) {
  }

  public addMetadataUpdate(serviceCaseUpdate: ServiceCase): Promise<ServiceCase> {
    return this.queue.add(
      () => this.serviceCaseService.updateMetadataInServiceCase(serviceCaseUpdate),
      {id: serviceCaseUpdate.getExternalId()}
    );
  }

  public addJobUpdate(serviceCaseId: string, job: Job): Promise<Job> {
    return this.queue.add(
      () => this.serviceCaseService.updateJobInServiceCase(serviceCaseId, job),
      {id: job.getExternalId()}
    );
  }

  public addJobDeletion(serviceCaseId: string, jobId: string): Promise<void> {
    return this.queue.add(
      () => this.serviceCaseService.deleteJobInServiceCase(serviceCaseId, jobId),
      {id: jobId}
    );
  }

  public hasMoreWithSameId(serviceCaseId: string): boolean {
    return this.queue.sizeBy({id: serviceCaseId}) > 0;
  }
}
