import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {WarrantyRestriction} from '../interfaces/warranty-restriction';

@Injectable({
  providedIn: 'root'
})
export class WarrantyRestrictionService {
  constructor(private httpClinet: HttpClient) {
  }

  getWarrantyRestrictions(vin: string, locale: string, country: string): Promise<WarrantyRestriction[]> {
    return new Promise((resolve, reject) => {
      this.httpClinet.get(
        `/api/warranty-restrictions/v2?vin17=${vin}&locale=${locale}&country=${country}`,
        {
          withCredentials: true
        }
      )
        .subscribe({
          next: (response: any) => resolve(response.data.restrictions),
          error: response => reject(response.errors)
        });
    });
  }
}
