import {Pipe, PipeTransform} from '@angular/core';
import {CustomerSource} from "@service-and-repairs/calm-leads-customer-lib-api";

@Pipe({
  name: 'customerSource'
})
export class CustomerSourcePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case CustomerSource.RETAIL_CRM:
        return 'Retail CRM';
      case CustomerSource.WHOLESALE_CRM:
        return 'Wholesale CRM';
      default:
        return value;
    }
  }
}
