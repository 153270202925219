<div class="button-bar">
  <input ds-input
         type="text"
         [(ngModel)]="licensePlateSearchInput"
         (keyup.enter)="searchLicensePlate()"
         [placeholder]="'case.licensePlate' | translate">
  <button ds-button ds-loading
          icon="search"
          (click)="searchLicensePlate()"
          [isLoading]="showLoadingIndicator"
          [disabled]="!licensePlateSearchInput || licensePlateSearchInput.trim().length === 0">
  </button>
</div>

@if (showErrorIndicator || showNoResultInfo) {
  <app-dms-search-message
    [errorMessage]="errorMessage"
    [showErrorIndicator]="showErrorIndicator"
    [showNoResultInfo]="showNoResultInfo">
  </app-dms-search-message>
}

@if (dcomLicensePlateSearchResult) {
  <div>
    @if (dcomLicensePlateSearchResult?.maxResultSize > MAX_SEARCH_RESULTS) {
      <ds-message tone="caution" variant="slim"
                  [isDismissable]="false"
                  messageText="{{('dms.tooManyResults' | translate :{results: dcomLicensePlateSearchResult.vehicles.length, maxResults: dcomLicensePlateSearchResult.maxResultSize})}}">
      </ds-message>
    }
    <ag-grid-angular
      class="ag-theme-density"
      [rowData]="dcomLicensePlateSearchResult.vehicles"
      [columnDefs]="dcomSearchResultColumns"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      (gridSizeChanged)="resizeColumns()"
      (rowDoubleClicked)="selectVehicleFromDcom($event.data)"
      [enableBrowserTooltips]="true"
      domLayout="autoHeight"
      [rowHeight]="35"
      [headerHeight]="35"
    >
    </ag-grid-angular>
  </div>
}

@if (etkDmsLicensePlateSearchResult) {
  <div>
    <ag-grid-angular
      class="ag-theme-density"
      [rowData]="etkDmsLicensePlateSearchResult"
      [columnDefs]="etkSearchResultColumns"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      (gridSizeChanged)="resizeColumns()"
      (rowDoubleClicked)="selectVehicleFromEtkDms($event.data)"
      [enableBrowserTooltips]="true"
      domLayout="autoHeight"
      [rowHeight]="35"
      [headerHeight]="35"
    >
    </ag-grid-angular>
  </div>
}
