import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {OidcConfigProvider} from '@oidc/OidcConfigProvider';
import {Observable} from 'rxjs';
import {OidcFlow} from '../../../assets/OidcFlow';
import {KeyDataService} from '../../core/keydata/services/key-data.service';
import {Util} from '../../util/util';
import {OidcToken} from './oidc-token';

/**
 * Adds authentication headers to outgoing requests to AWP backends.
 */
export class WenInterceptor implements HttpInterceptor {

  hostname = window.location.hostname;
  isRefreshInProgress = false;
  oidc: OidcFlow;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const oidcConfig = (new OidcConfigProvider()).get(this.hostname);
    this.oidc = new OidcFlow(oidcConfig);

    if (!this.oidc.oidcIsSupported()) {
      return next.handle(req);
    }

    // TODO: Implement proper configuration of OIDC enabled endpoints, e.g. through environments.ts.
    if (!req.url.includes('/api/') && !req.url.includes('/etk-rest/')) {
      return next.handle(req);
    }

    if (req.headers.has('x-skip-token-interceptor')) {
      console.debug(
        Util.logPrefix('Webapp', 'Auth')
        + 'Will not modify request to ' + req.url + ' because of header x-skip-token-interceptor');
      return next.handle(req.clone({headers: req.headers.delete('x-skip-token-interceptor')}));
    }

    if (!this.oidc.getOidcTokenFromStorage()) {
      console.log(Util.logPrefix('Webapp', 'Auth') + 'No token found, will not modify outgoing request to ' + req.url);
      return next.handle(req);
    }

    // Refreshes asynchronously, will not affect the current request
    // TODO: Make thread-safe
    if (!this.isRefreshInProgress) {
      this.isRefreshInProgress = true;
      this.oidc.refreshAccessTokenIfExpired().then(() => this.isRefreshInProgress = false);
    }

    const additionalHeaders = this.getAdditionalHeadersForTargetUrl(req.url);
    const mergedHeaders = req.url.includes('/api/key-pool/') || req.url.includes('/api/vehicle-information/vehicles/') ?
      this.mergeKeyPoolHeaders(req.headers, additionalHeaders) :
      this.mergeHeaders(req.headers, additionalHeaders);
    console.debug(Util.logPrefix('Webapp', 'Auth') + 'Adding authentication headers in request to ' + req.url);
    return next.handle(req.clone({headers: mergedHeaders}));
  }

  private getAdditionalHeadersForTargetUrl(url: string): {[key: string]: string;} {
    const token = OidcToken.readFromStorage(this.oidc.businessContext);

    // ETK
    if (url.includes('/etk-rest/')) {
      return token.getAuthorizationHeader(); // This header is sufficient for the ETK
    }

    // AWP
    else if (url.includes('/api/')) {
      // Retrieve OIDC / WEN authentication headers
      const wenHeaders = token.getHeaders();

      // Request ID for log correlation
      wenHeaders['Cavors-Request-Id'] = Util.createRandomUuid();

      // Session ID for log correlation
      const cavorsSessionId = sessionStorage.getItem('cavors-session-id');
      if (cavorsSessionId) {
        wenHeaders['Cavors-Session-Id'] = cavorsSessionId;
      }

      return wenHeaders;
    }

    return {};
  }

  private mergeHeaders(existingHeaders: HttpHeaders, additionalHeaders: {[key: string]: string;}): HttpHeaders {
    let headers = existingHeaders;
    Object.keys(additionalHeaders).forEach(name => {
      headers = headers.set(name, additionalHeaders[name]);
    });
    return headers;
  }

  private mergeKeyPoolHeaders(existingHeaders: HttpHeaders, additionalHeaders: {
    [key: string]: string;
  }): HttpHeaders {
    let headers = existingHeaders;
    Object.keys(additionalHeaders).forEach(name => {
      if (KeyDataService.supportedHeaders.includes(name.toLowerCase())) {
        headers = headers.set(name, additionalHeaders[name]);
      }
    });
    return headers;
  }
}
