@if (appVisible && !koreaDisclaimerState.visible) {
  <app-header></app-header>

  <div class="content-with-navigation">
    <app-side-navigation></app-side-navigation>
    <div class="content">
      <app-service-case-bar
        [ngClass]="{'hidden': !showCaseBar}"
        [shortListViewState]="shortlistViewState">
      </app-service-case-bar>
      <div class="awp-viewport"
           [ngClass]="{'loading': loading, 'case-bar-hidden': !showCaseBar}">
        @if (loading && showCaseBar) {
          <div class="loading-overlay">
            <ds-progress-circle class="m-3x"></ds-progress-circle>
            <p>{{ ('common.loading' | translate) + '...' }}</p>
          </div>
        }
        <router-outlet #outlet="outlet" (activate)="onRouteActivate(outlet.activatedRouteData)"
                       (deactivate)="onRouteDeactivate()"></router-outlet>
        <!-- iframes for integrated apps will be inserted here by iframe.component.ts -->
        <!-- web components for integrated apps will be inserted here by web-component.component.ts -->
      </div>
    </div>
    <app-shortlist [hidden]="!shortlistViewState.visible || !showCaseBar"
                   [shortlistViewState]="shortlistViewState"></app-shortlist>
  </div>
}

<app-korea-disclaimer [koreaDisclaimerState]="koreaDisclaimerState"></app-korea-disclaimer>
