import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {AuthInfoFactory} from '../../../auth/auth-info-factory';
import {UserData} from '../../user/models/user-data';
import {MileageRecord} from '../interfaces/mileage-record';

@Injectable({
  providedIn: 'root'
})
export class MileageService {
  private headers = new HttpHeaders();

  constructor(private httpClient: HttpClient) {
    this.headers = this.headers
      .set('Auth-App-Id', 'awp')
      .set('Content-Type', 'application/json');
  }

  getMileageRecord(vin: string, user: UserData): Promise<MileageRecord> {
    this.setHeaders(user);

    const url = `${this.getMileageServiceHost(user.getContext())}/api/vehicle-information/vehicles/${vin}/mileage`;
    const requestStartMs = (new Date()).getTime();
    console.debug(`[Webapp] [Mileage service] Requesting mileage from ${url}.`);

    return new Promise((resolve, reject) => {
      this.httpClient.get(url, {
        headers: this.headers,
        withCredentials: true
      })
        .subscribe({
          next: (mileageRecord: MileageRecord) => resolve(mileageRecord),
          error: reason => reject(reason),
          complete: () => {
            const requestEndMs = (new Date()).getTime();
            console.debug(`[Webapp] [Mileage Service] Mileage request took ${requestEndMs - requestStartMs}ms.`);
          }
        });
    });
  }

  private getMileageServiceHost(businessContext: string): string {
    switch (businessContext) {
      case 'B2D':
        return environment.urls.mileageService.b2d;
      case 'B2D_INTERNAL':
        return environment.urls.mileageService.b2dIntranet;
      case 'B2E':
        return environment.urls.mileageService.b2e;
    }
    return undefined;
  }

  private setHeaders(user: UserData): void {
    if (user.isB2E()) {
      this.headers = this.headers
        .set('Auth-Brands', AuthInfoFactory.awpBrandsToCsslBrands(user.getSelectedBrands()))
        .set('Auth-Country', user.getSelectedCountry());
    } else {
      this.headers = this.headers
        .set('Auth-Dist-Partner', user.getBusinessPartner().getDistributionPartnerNumber())
        .set('Auth-Outlet', user.getBusinessPartner().getOutletNumber());
    }
  }
}
