import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '@environments/environment';
import {IServerApi, NavigationCall} from '@service-and-repairs/awpintegrationlib';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AnalyticsService} from '../core/analytics/services/analytics.service';
import {UserData} from '../core/user/models/user-data';
import {UserService} from '../core/user/services/user.service';
import {IIframeApp, IWebComponentApp} from '../interfaces/IApp';
import {RouteFactoryService} from '../route-factory.service';
import {AwpClientLibService} from './awp-client-lib.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private awpLib: IServerApi;
  private routesLoaded = new Subject<void>();
  private previousRoute: string;

  constructor(awpClientLibService: AwpClientLibService,
              private router: Router,
              private routeFactory: RouteFactoryService,
              private userService: UserService,
              private analyticsService: AnalyticsService) {
    this.awpLib = awpClientLibService.serverApi;

    this.openDefaultAppIfNoPathIsProvided();
    this.setupNavigationAnalytics();

    userService.userSubject.subscribe(this.onUserChange.bind(this));

    this.awpLib.subscribeToNavigateTo((plainNavigationCall: object) => {
      const navigationCall = NavigationCall.constructFromPlainObject(plainNavigationCall);
      const params = {
        pathParam: navigationCall.getPathParam(),
        queryParams: navigationCall.getQueryParams()
      };
      this.router.navigateByUrl(navigationCall.getApp(), {state: params});
    });
  }

  navigateTo(path: string, extras?: object): void {
    this.router.navigateByUrl(path || '', extras).catch(() => {
      // wait for routes to exist if navigation fails
      this.routesLoaded.subscribe(() => {
        this.router.navigateByUrl(path || '', extras);
      });
    });
  }

  private onUserChange(user: UserData): void {
    if (user) {
      this.createNavigationRoutesForApps();
      this.router.initialNavigation();
    }
  }

  private createNavigationRoutesForApps(): void {
    this.router.config = [];
    this.userService.getVisibleApps().forEach((app: IIframeApp | IWebComponentApp) => {
      this.router.config.push(this.routeFactory.getRouteForApp(app));
    });
    this.routesLoaded.next();
    this.routesLoaded.complete();
  }

  private openDefaultAppIfNoPathIsProvided(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.url.substring(1).split('?')[0];
        if (url === '' || url === '/') {
          this.openDefaultApp();
        }
      });
  }

  private openDefaultApp(): void {
    const user = this.userService.userSubject.getValue();
    let userDefaultApp: IIframeApp | IWebComponentApp;
    for (const category of environment.categories) {
      userDefaultApp = category.apps.find(app => app.titleResource === user?.getDefaultApp());
      if (userDefaultApp) {
        break;
      }
    }
    if (userDefaultApp) {
      this.navigateTo(userDefaultApp.routingPath);
    } else {
      this.navigateTo('caseoverview');
    }
  }

  private setupNavigationAnalytics(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.url.substring(1).split('?')[0];
        this.postNavigateToAnalyticsEvent(url, this.previousRoute);
        this.previousRoute = url;
      });
  }

  private postNavigateToAnalyticsEvent(path: string, origin: string) {
    console.debug(`[Webapp] [Navigation] Navigated to ${path}.`);
    this.analyticsService.postNavigationEvent(path, origin);
  }
}
