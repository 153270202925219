import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IBusinessRelationship} from '@service-and-repairs/awpintegrationlib';
import {AuthenticationTokenBearerService} from '../../../auth/authentication-token-bearer.service';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {Outlet} from '../models/outlet';

@Injectable({
  providedIn: 'root'
})
export class DealerDataService {

  outlets: Outlet[];

  private user: UserData;

  constructor(private userService: UserService,
              private translate: TranslateService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService) {
    userService.userSubject.subscribe((user: UserData) => {
      this.user = user;
      this.outlets = this.createOutletsFromUser();
    });
  }

  setSelectedOutlet(selectedOutlet: Outlet): void {
    const businessPartnerIdChanged = this.hasSelectedBusinessPartnerIdChanged(selectedOutlet);
    const vehicleTypeChanged = this.hasSelectedVehicleTypeChanged(selectedOutlet);
    if (businessPartnerIdChanged || vehicleTypeChanged) {
      this.user.setBusinessPartner(selectedOutlet.businessPartner);
      this.user.setSelectedVehicleType(selectedOutlet.vehicleType);
      if (businessPartnerIdChanged) {
        this.authenticationTokenBearerService.fetchAndStoreCsslToken(this.user)
          .then(() => this.handleCsslTokenRetrievalSuccess())
          .catch(() => this.handleCsslTokenRetrievalFailure())
          .finally(() => this.updateUserData());
      } else {
        this.updateUserData();
      }
    }
  }

  getSelectedOutlet(): Outlet {
    const selectedOutlet = this.outlets?.find(outlet =>
      outlet.businessPartner.getBusinessPartnerId() === this.user.getBusinessPartner().getBusinessPartnerId() &&
      outlet.vehicleType === this.user.getSelectedVehicleType()
    );

    return selectedOutlet || this.outlets?.at(0);
  }

  switchToOutlet(businessNumber: string): void {
    const outlet = this.outlets.find(outlet => outlet.businessNumber === businessNumber);
    if (outlet) {
      console.log(`[Webapp] [DealerDataService] Switching to outlet ${outlet.toShortString(this.translate)}`);
      this.setSelectedOutlet(outlet);
    } else {
      console.log(`[Webapp] [DealerDataService] Switch to business number ${businessNumber} not allowed`);
    }
  }

  private handleCsslTokenRetrievalSuccess(): void {
    console.log('[Webapp] [DealerDataService] Outlet has changes and CSSL token available. Updating user data.');
  }

  private handleCsslTokenRetrievalFailure(): void {
    console.warn('[Webapp] [DealerDataService] Outlet has changes but CSSL token is missing. Updating user data.');
  }

  private updateUserData(): void {
    this.saveSelectedOutlet().finally(() => this.getOutletPeers().then(() => this.userService.publishUser(this.user)));
  }

  private getOutletPeers(): Promise<void> {
    return new Promise(resolve => {
      this.userService.getOutletPeers()
        .then((outletPeers) => {
          this.user.setOutletPeers(outletPeers);
          resolve();
        });
    });
  }

  private saveSelectedOutlet(): Promise<void> {
    const userSettings = UserService.getUserSettingsFromUserData(this.user);
    return this.userService.setUserSettings(userSettings);
  }

  private hasSelectedBusinessPartnerIdChanged(selectedOutlet: Outlet): boolean {
    return selectedOutlet.businessPartner.getBusinessPartnerId() !==
      this.user?.getBusinessPartner().getBusinessPartnerId();
  }

  private hasSelectedVehicleTypeChanged(selectedOutlet: Outlet): boolean {
    return selectedOutlet.vehicleType !== this.user?.getSelectedVehicleType();
  }

  private createOutletsFromUser(): Outlet[] {
    if (!this.user?.getBusinessPartners()) {
      return undefined;
    }

    const outlets = [];

    this.user?.getBusinessPartners().forEach(bp => {
      const relationships = bp.getBusinessRelationships() || [];
      relationships.forEach((businessRelationship: IBusinessRelationship) => {
        const outlet = new Outlet(
          bp,
          businessRelationship.getVehicleType(),
          businessRelationship.getBusinessNumber()
        );
        if (outlets.every(existingOutlet => !existingOutlet.equals(outlet))) {
          outlets.push(outlet);
        }
      });
    });
    return outlets;
  }
}
