import {Injectable} from '@angular/core';
import {DsToastService, DsToastTone} from '@bmw-ds/components';
import {UuidUtil} from '@service-and-repairs/dms-impl';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private toastService: DsToastService) {
  }

  private static getNotificationTimeoutInMs(message: string): number {
    // https://ux.stackexchange.com/questions/11203/how-long-should-a-temporary-notification-toast-appear
    return Math.min(Math.max(message.length * 100, 3000), 20000);
  }

  showErrorNotification(message: string): void {
    this.showNotification(message, 'critical');
  }

  showWarningNotification(message: string): void {
    this.showNotification(message, 'caution');
  }

  showSuccessNotification(message: string): void {
    this.showNotification(message, 'positive');
  }

  showInfoNotification(message: string, duration?: number): void {
    this.showNotification(message, 'info', duration);
  }

  private showNotification(message: string, tone: DsToastTone, duration?: number): void {
    this.toastService.pushToast({
      tone: tone,
      toastText: message,
      duration: duration ?? NotificationsService.getNotificationTimeoutInMs(message),
      id: UuidUtil.generateUuid()
    });
  }
}
