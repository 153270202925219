import {jwtDecode, JwtPayload} from 'jwt-decode';

/**
 * This class fully resembles how we store CSSL tokens in the browser's local storage.
 * A CSSL token is a Java Web Token (JWT). To learn more about JWTs and debug/decode
 * test tokens, see https://jwt.io/.
 */
export class CsslToken {
  csslToken: string; // A JWT holding basic identity information including roles. Up to around 3000 characters.
  creationTimestampMilliseconds: number; // Timestamp the token was stored at.
  toBeRefreshedInMilliseconds: number; // How long before a refresh is needed. Shorter than the actual expiration time.
  businessContext: string; // The user's business context for which the token was issued: B2D | B2D_INTERNAL | B2E.

  /**
   * Reads the token for the given business context from local storage and returns it as an instance of CsslToken.
   * @param businessContext B2D | B2D_INTERNAL | B2E
   * @return The CSSL token from local storage. If none exists, an empty token is returned instead of null or undefined.
   */
  static readFromStorage(businessContext: string): CsslToken {
    const itemKey = 'csslToken_' + businessContext;
    const stringItemFromStorage = window.sessionStorage.getItem(itemKey);
    const parsedItemFromStorage = JSON.parse(stringItemFromStorage);
    return Object.assign(new CsslToken(), parsedItemFromStorage);
  }

  static create(csslToken: string, businessContext: string): CsslToken | undefined {
    let token: CsslToken = undefined;

    try {
      token = new CsslToken();
      token.csslToken = csslToken;
      token.businessContext = businessContext;
      token.creationTimestampMilliseconds = (new Date()).getTime();
      const decodedCsslJwt: JwtPayload = jwtDecode(token.csslToken);
      const expirationTimestampSecs = decodedCsslJwt.exp;

      // CSSL tokens are valid for 290 seconds after they have been issued by CSSL. CSSL tokens are cached by the
      // AWP gateway for performance reasons and to reduce load on CSSL side. The gateway refreshes tokens after 280
      // out of 290 seconds validity. We want to refresh roughly in the middle of 280 and 290 to ensure that (A) we get
      // a fresh, valid token and (B) we provide that fresh token in a timely manner to our embedded apps. If we
      // refreshed at 290, embedded apps might just have used their existing token right at expiration and run into
      // authentication problems. If we refreshed at 280, the gateway might not yet have stored its new token.
      // Note: CSSL sets an expiration date of NOW + 290s, so we have to refresh roughly at EXPIRATION_DATE - 8s.
      token.toBeRefreshedInMilliseconds = expirationTimestampSecs * 1000 - new Date().getTime() - 8000;

    } catch (error) {
      console.error('[Webapp] [Auth] Failed to decode CSSL JWT.', error);
    }

    return token;
  }

  writeToStorage(): void {
    const storageKey = 'csslToken_' + this.businessContext;
    const storageValue = JSON.stringify(this);
    window.sessionStorage.setItem(storageKey, storageValue);
  }

  deleteFromStorage(): void {
    window.sessionStorage.removeItem('csslToken_' + this.businessContext);
  }

  isExpired(): boolean {
    return !this.creationTimestampMilliseconds
      || (new Date()).getTime() > this.creationTimestampMilliseconds + this.toBeRefreshedInMilliseconds;

  }
}
