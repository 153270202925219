<div class="header p-4x">
  <span class="title-md">{{ 'case.basket' | translate }} ({{ numberOfElementsInActiveCase }})</span>
  <button
    ds-button
    variant="ghost-muted"
    icon="close"
    (click)="close()">
  </button>
  <button
    ds-toggle-button
    variant="ghost-muted"
    class="mx-3x"
    icon="pin"
    (click)="togglePinnedState()"
    [checked]="isPinned">
  </button>
</div>

@if (showErrorIndicator) {
  <div class="message-container">
    <ds-message tone="critical"
                [isDismissable]="false"
                messageText="{{('common.viewLoadingError' | translate)}}">
    </ds-message>
  </div>
}

@for (serviceCase of serviceCaseHolder.serviceCases; track serviceCase.getExternalId()) {
  <div class="shortlist-component-container"
       [ngClass]="{small: showErrorIndicator}"
       [hidden]="isHidden(serviceCase)">
    <ueg-awp-service-case-shortlist
      [activeCase]="serviceCase"
      [userData]="user"
      [authInfo]="authInfo"
      [locale]="locale"
      [showFlatRateUnitAmount]="showFlatRateUnitAmount()"
      [leadsDealerConfig]="leadsConfiguration"
      (jobsDeleted)="onJobsDeleted($event['detail'])"
      (openDetailsClicked)="openServiceCaseDetails()"
    ></ueg-awp-service-case-shortlist>
  </div>
}
