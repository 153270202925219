import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {IBusinessPartner} from '@service-and-repairs/awpintegrationlib';
import {KeyData} from '../interfaces/key-data';

@Injectable({
  providedIn: 'root'
})
export class KeyDataService {
  static supportedHeaders = [
    'accept-encoding',
    'accept-language',
    'access_token',
    'auth-app-id',
    'auth-brands',
    'auth-buno',
    'auth-country',
    'auth-dist-partner',
    'auth-outlet',
    'auth-session',
    'authorization',
    'authscheme',
    'authtype',
    'cache-control',
    'cavors-request-id',
    'cavors-session-id',
    'content-type',
    'cookie',
    'cssl-authorization',
    'origin',
    'pragma',
    'referer',
    'user-agent',
    'x-app-authorization',
    'x-requesting-application'
  ];

  private headers = new HttpHeaders();

  constructor(private httpClient: HttpClient) {
    this.addHeadersIfSupported({
      'Auth-App-Id': 'awp',
      'Content-Type': 'application/json'
    });
  }

  getKeyData(context: string, businessPartner: IBusinessPartner): Promise<KeyData[]> {
    this.setHeaders(businessPartner);

    const url = `${this.getKeyPoolHost(context)}/api/key-pool/overview`;
    const requestStartMs = (new Date()).getTime();
    console.debug(`[Webapp] [Keypool] Requesting key data from ${url}.`);

    return new Promise((resolve, reject) => {
      this.httpClient.get(url, {
        headers: this.headers,
        withCredentials: true
      }).subscribe({
        next: (response: any) => resolve(response.interpretationExtracts),
        error: reason => reject(reason),
        complete: () => {
          const requestEndMs = (new Date()).getTime();
          console.debug(`[Webapp] [Keypool] Key data request took ${requestEndMs - requestStartMs}ms.`);
        }
      });
    });
  }

  private getKeyPoolHost(businessContext: string): string {
    return businessContext === 'B2D_INTERNAL' ? environment.urls.keypool.b2dIntranet : environment.urls.keypool.b2d;
  }

  private setHeaders(businessPartner: IBusinessPartner): void {
    this.addHeadersIfSupported({
      'Auth-Dist-Partner': businessPartner.getDistributionPartnerNumber(),
      'Auth-Outlet': businessPartner.getOutletNumber()
    });
  }

  private addHeadersIfSupported(headers: {}): void {
    Object.keys(headers).forEach(key => {
      if (KeyDataService.supportedHeaders.indexOf(key.toLowerCase()) >= 0) {
        this.headers = this.headers.set(key, headers[key]);
      }
    });
  }
}
