import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceContract} from '../interfaces/service-contract';

@Injectable({
  providedIn: 'root'
})
export class ServiceContractService {
  constructor(private httpClient: HttpClient) {
  }

  getServiceContracts(vin: string, locale: string, country: string): Promise<ServiceContract[]> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(
        `/api/service-contract/v2?vin=${vin}&locale=${locale}&country=${country}`,
        {
          withCredentials: true
        }
      )
        .subscribe({
          next: (response: any) => resolve(response.data.bsiFullContract),
          error: response => reject(response.errors)
        });
    });
  }
}
