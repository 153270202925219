<ds-modal #tpl [ds-modal-trigger-for]="tpl" [(isModalOpen)]="popupVisible" size="sm">
  <ds-box-header>{{ 'customer.customerLanguagePopupTitle' | translate }}</ds-box-header>
  <ds-box-content>
    {{
      'customer.customerLanguagePopupText' | translate:{
        customerLanguage: serviceCaseLanguage,
        awpLanguage: user?.getLocale()
      }
    }}

    @if (showSaveError) {
      <ds-message
        tone="critical"
        variant="slim"
        class="my-4x"
        [isDismissable]="true"
        [messageText]="'customer.customerLanguagePopupError' | translate"></ds-message>
    }

    @if (showLanguageWarning) {
      <ds-message
        tone="caution"
        variant="slim"
        class="my-4x"
        [isDismissable]="true"
        [messageText]="'customer.customerLanguagePopupLanguageWarning' | translate:{customerLanguage: serviceCaseLanguage}"></ds-message>
    }
  </ds-box-content>

  <ds-box-footer>
    <button ds-button
            ds-loading loadingVariant="indeterminate"
            class="mx-4x"
            (click)="confirm()"
            [disabled]="showLanguageWarning"
            [isLoading]="showLoadingIndicator"
    >{{ 'common.yes' | translate }}
    </button>

    <button #dsModalDismissElement
            ds-button
            variant="outline"
            (click)="close()"
    >{{ 'common.no' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
