import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Job} from '../../job/job';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {ServiceCase} from '../models/service-case';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseService {

  constructor(private httpClient: HttpClient,
              private translate: TranslateService,
              private userService: UserService) {
  }

  private static getServiceCasesByVinParams(vin: string, user: UserData): URLSearchParams {
    const queryParams = [
      [
        'assigned_distribution_partner_number',
        user.getContext() !== 'B2E' ? user.getBusinessPartner()?.getDistributionPartnerNumber() : undefined
      ],
      [
        'assigned_user_id',
        user.getContext() === 'B2E' ? user.getId() : undefined
      ],
      ['vin_short', vin.length === 7 ? vin : undefined],
      ['vin_long', vin.length === 17 ? vin : undefined]
    ].filter(param => param[1]);

    return new URLSearchParams(queryParams);
  }

  private static removeExcessServiceCasePropertiesForUpdatingMetadata(serviceCase: ServiceCase): ServiceCase {
    const clone = ServiceCase.fromPlainObject(serviceCase);
    clone.getJobs().splice(0);
    clone.setVehicle(null);
    clone.setCampaigns(undefined);
    clone.setServiceContracts(undefined);
    clone.setWarrantyRestrictions(undefined);
    clone.setWarnings([]);
    clone.setCustomers(undefined);
    return clone;
  }

  /**
   * Persists the service case in the backend. The external id (set by the backend service) is set in the service case.
   * @param newCase The service case to be persisted in the backend
   */
  createServiceCase(newCase: ServiceCase): Promise<ServiceCase> {
    return new Promise<ServiceCase>((resolve, reject) => {
      this.httpCreateServiceCase(newCase).subscribe({
        next: (response: any) => resolve(ServiceCase.fromPlainObject(response)),
        error: () => reject(this.translate.instant('case.notificationSaveError'))
      });
    });
  }

  /**
   * Updates the service case in the backend.
   * @param serviceCase The service case to be updated in the backend
   */
  updateMetadataInServiceCase(serviceCase: ServiceCase): Promise<ServiceCase> {
    return new Promise<ServiceCase>((resolve, reject) => {
      this.httpUpdateMetadata(serviceCase).subscribe({
        next: (response: any) => resolve(ServiceCase.fromPlainObject(response.data)),
        error: (response: any) => {
          console.error(response.errors);
          reject(response.errors);
        }
      });
    });
  }

  /**
   Updates the given job in a service case in the backend.
   @param serviceCaseId external id of service case
   @param job job to update
   */
  updateJobInServiceCase(serviceCaseId: string, job: Job): Promise<Job> {
    return new Promise<Job>((resolve, reject) => {
      this.httpUpdateJob(serviceCaseId, job).subscribe({
        next: (response: any) => resolve(Job.fromPlainObject(response.data)),
        error: (response: any) => {
          console.error(response.errors);
          reject(response.errors);
        }
      });
    });
  }

  /**
   * Deletes the given job in a service case in the backend.
   * @param serviceCaseId external id of service case
   * @param jobId external if of job to delete
   */
  deleteJobInServiceCase(serviceCaseId: string, jobId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpDeleteJob(serviceCaseId, jobId).subscribe({
        next: () => resolve(),
        error: (response: any) => {
          console.error(response.errors);
          reject(response.errors);
        }
      });
    });
  }

  /**
   * Retrieves a service case from the backend with the external id.
   * @param externalId External id of the service case to look up
   */
  getServiceCase(externalId: string): Promise<ServiceCase> {
    return new Promise<ServiceCase>((resolve, reject) => {
      this.httpGetServiceCase(externalId).subscribe({
        next: (response: any) => {
          const serviceCase = ServiceCase.fromPlainObject(response);
          this.setAssignedBusinessPartnerIdIfMissing(serviceCase);
          resolve(serviceCase);
        },
        error: (response: any) => reject(this.getLoadingFailedReason(response.statusCode))
      });
    });
  }

  /**
   * Retrieves service cases for a VIN modified within the last 42 days from the backend. In B2D context the service
   * cases are limited to the dealer outlet's service cases. In B2E the service cases are limited to the user's service
   * cases.
   * @param vin vehicle identification number
   */
  getServiceCasesByVin(vin: string): Promise<ServiceCase[]> {
    return new Promise<ServiceCase[]>((resolve, reject) => {
      this.httpGetServiceCasesByVin(vin).subscribe({
        next: (response: any) => {
          const serviceCases: ServiceCase[] = response.data.map((plainServiceCase: any) => {
            const serviceCase = ServiceCase.fromPlainObject(plainServiceCase);
            this.setAssignedBusinessPartnerIdIfMissing(serviceCase);

            // ServiceCase.fromPlainObject clears demandCategories as the API does not return jobs.
            serviceCase.setDemandCategories(plainServiceCase.demandCategories);
            return serviceCase;
          });
          resolve(serviceCases);
        },
        error: (reason => {
          console.error(this.translate.instant('case.notificationLoadError') + ':' + reason);
          reject(this.translate.instant('case.notificationLoadError'));
        })
      });
    });
  }

  private getLoadingFailedReason(statusCode: any): string {
    let reason: string;
    if (statusCode?.valueOf() === 403) {
      reason = this.translate.instant('case.notificationLoadErrorNotAllowed');
    } else {
      reason = this.translate.instant('case.notificationLoadError');
      console.error('[Webapp][Service Case Service] ' + reason + ': ' + statusCode);
    }
    return reason;
  }

  // TODO: Remove once all service cases have a business partner ID (e.g. by applying an SQL script at deployment time).
  private setAssignedBusinessPartnerIdIfMissing(serviceCase: ServiceCase): void {
    const userData = this.userService.userSubject.getValue();
    if (userData?.isB2E()) {
      return;
    }
    if (!serviceCase.getAssignedBusinessPartnerId() || serviceCase.getAssignedBusinessPartnerId() === '') {
      const businessPartnerId = this.getBusinessPartnerIdForOutlet(
        serviceCase.getAssignedDistributionPartnerNumber(),
        serviceCase.getAssignedOutletNumber()
      );
      serviceCase.setAssignedBusinessPartnerId(businessPartnerId);
    }
  }

  private getBusinessPartnerIdForOutlet(distributionPartnerNumber: string, outletNumber: string): string {
    const userData = this.userService.userSubject.getValue();

    // Use current business partner ID as default
    let businessPartnerId = userData?.getBusinessPartner()?.getBusinessPartnerId();

    // Try to find business partner ID for existing dpNo and outletNo
    userData?.getBusinessPartners().forEach((partner) => {
      if (partner.getDistributionPartnerNumber() ===
        distributionPartnerNumber &&
        partner.getOutletNumber() ===
        outletNumber) {
        businessPartnerId = partner.getBusinessPartnerId();
      }
    });
    return businessPartnerId;
  }

  /**
   * Gets the service case with the given external id.
   * @param id external id
   */
  private httpGetServiceCase(id: string) {
    return this.httpClient.get('/api/service-case/v1/' + id, {withCredentials: true});
  }

  /**
   * Gets all service cases that map the given VIN. In B2D context the service cases are limited to the user's outlet.
   * In B2E context the service cases are limited to the user's id.
   * @param vin vehicle identification number
   */
  private httpGetServiceCasesByVin(vin: string) {
    const params = ServiceCaseService.getServiceCasesByVinParams(vin, this.userService.userSubject.getValue());
    return this.httpClient.get('/api/service-case/v3?' + params, {withCredentials: true});
  }

  /**
   * Persists the given service case in the backend.
   * @param serviceCase service case to persist
   */
  private httpCreateServiceCase(serviceCase: ServiceCase) {
    const serviceCaseForBackend = ServiceCaseService.removeExcessServiceCasePropertiesForUpdatingMetadata(serviceCase);
    return this.httpClient.post('/api/service-case/v1', serviceCaseForBackend);
  }

  /**
   * Updates the given service case in the backend.
   * @param serviceCase service case to update
   */
  private httpUpdateMetadata(serviceCase: ServiceCase) {
    const serviceCaseForBackend = ServiceCaseService.removeExcessServiceCasePropertiesForUpdatingMetadata(serviceCase);
    return this.httpClient.put('/api/service-case/v3/metadata', serviceCaseForBackend);
  }

  /**
   * Updates the given job in a service case in the backend.
   * @param serviceCaseId external id of service case
   * @param job job to update
   */
  private httpUpdateJob(serviceCaseId: string, job: Job) {
    return this.httpClient.put('/api/service-case/v3/' + serviceCaseId + '/jobs', job);
  }

  /**
   * Deletes the given job in a service case in the backend.
   * @param serviceCaseId external id of service case
   * @param jobId external if of job to delete
   */
  private httpDeleteJob(serviceCaseId: string, jobId: string) {
    return this.httpClient.delete('/api/service-case/v3/' + serviceCaseId + '/jobs/' + jobId);
  }
}
