export class DcomDmsSettings {
  overwriteMetadataOnUpdate: boolean = false;
  isPricesAndAvailabilitiesEnabled: boolean = false;
  isAutomaticPriceRequestEnabled: boolean = false;
  requestPricesAndAvailabilitiesPerJob: boolean = false;
  isOrderSearchEnabled: boolean = false;
  isLicensePlateSearchEnabled: boolean = false;
  workplaceId: string = undefined;
  bmwWorkplaceId: string = undefined;
  bmwiWorkplaceId: string = undefined;
  miniWorkplaceId: string = undefined;
  rollsRoyceWorkplaceId: string = undefined;
  zinoroWorkplaceId: string = undefined;
  bmwMotorradWorkplaceId: string = undefined;
  url: string = undefined;
  dmsCostCenterForSipActivated: boolean = false;
  dmsCostUnitAccountIdForSip: string = undefined;
  dmsCostUnitDescriptionForSip: string = undefined;
}
