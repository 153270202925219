import {formatDate} from '@angular/common';
import {
  Case,
  ICase,
  IDefect,
  IFlatRateUnit,
  IJob,
  IPackage,
  IPart,
  IVehicle,
  Vehicle
} from '@service-and-repairs/awpintegrationlib';
import {Customer, CustomerInfo, CustomerSource} from '@service-and-repairs/calm-leads-customer-lib-api';
import {Util} from '../../../util/util';
import {VehicleCampaignCollection} from '../../campaign/interfaces/vehicle-campaign-collection';
import {Job} from '../../job/job';
import {MileageRecord} from '../../mileage/interfaces/mileage-record';
import {ServiceContract} from '../../service-contract/interfaces/service-contract';
import {UserData} from '../../user/models/user-data';
import {VehicleWarning} from '../../vehicle-warning/interfaces/vehicle-warning';
import {WarrantyRestriction} from '../../warranty-restriction/interfaces/warranty-restriction';

export class ServiceCase extends Case {
  private isAlreadyPersisted: boolean;
  saveEventIds = new Set<string>();

  private vehicleCampaignCollection: VehicleCampaignCollection;

  private serviceContracts: ServiceContract[];

  private warrantyRestrictions: WarrantyRestriction[];

  private warnings: VehicleWarning[] = [];

  private mileageRecord: MileageRecord;
  private mileage: string;

  // Will be set if loading a service case fails.
  // Error will then be displayed as a tooltip in the respective tab.
  loadingError: string;

  // Don't make this constructor public. Use the static factory "fromPlainObject" instead.
  private constructor(sourceCase: ICase, jobs: IJob[]) {
    super(
      sourceCase.getExternalId(),
      sourceCase.getTitle(),
      sourceCase.getCreatedAt(),
      sourceCase.getCreatedByUserId(),
      sourceCase.getCreatedByUserName(),
      sourceCase.getModifiedAt(),
      sourceCase.getAssignedUserId(),
      sourceCase.getAssignedUserName(),
      jobs
    );
  }

  static constructMinimalServiceCase(user: UserData): ServiceCase {
    const businessPartner = user.getBusinessPartner();
    const creationDate = new Date();
    const contextPrefixedUserId = (user.getContext() + ':' + user.getId()).toLowerCase();
    const plainServiceCaseObject = {
      externalId: Util.createRandomUuid(),
      createdAt: creationDate,
      createdByUserId: contextPrefixedUserId,
      createdByUserName: user.getName(),
      customers: undefined,
      assignedDistributionPartnerNumber: businessPartner ? businessPartner.getDistributionPartnerNumber() : null,
      assignedOutletNumber: businessPartner ? businessPartner.getOutletNumber() : null,
      assignedBusinessPartnerId: businessPartner ? businessPartner.getBusinessPartnerId() : null,
      modifiedAt: creationDate,
      assignedUserId: contextPrefixedUserId,
      assignedUserName: user.getName(),
      jobs: [],
      title: formatDate(creationDate, 'short', user.getLocale()),
      isPersisted: false
    };
    return ServiceCase.fromPlainObject(plainServiceCaseObject);
  }

  /**
   * Creates a type safe ServiceCase object from data contained in a "plain object".
   *
   * Plain objects can occur, for example, when sending a type safe object to some other
   * component using window.postMessage(object). When receiving a plain object in this way,
   * it has no methods, just data.
   *
   * However, this factory method can also be used to easily create type safe
   * objects from JSON, e.g. to crate objects for unit testing.
   *
   * @param plainServiceCaseObject
   */
  static fromPlainObject(plainServiceCaseObject: any): ServiceCase {
    // Transform plain object into ICase
    const sourceCase: ICase = ServiceCase.constructFromPlainObject(plainServiceCaseObject);

    // Transform IJobs into Jobs
    const jobs = sourceCase.getJobs().map((j) => Job.fromPlainObject(j));

    // Transform ICases into ServiceCases
    const serviceCase = new ServiceCase(sourceCase, jobs);
    serviceCase.setAssignedDistributionPartnerNumber(sourceCase.getAssignedDistributionPartnerNumber());
    serviceCase.setAssignedOutletNumber(sourceCase.getAssignedOutletNumber());
    serviceCase.setAssignedBusinessPartnerId(sourceCase.getAssignedBusinessPartnerId());
    serviceCase.setAssignedSpecialistGroup(sourceCase.getAssignedSpecialistGroup());
    serviceCase.setBrand(sourceCase.getBrand());
    serviceCase.setComment(sourceCase.getComment());
    serviceCase.setCurrencyCode(sourceCase.getCurrencyCode());
    serviceCase.setCustomers(sourceCase.getCustomers());
    serviceCase.setCustomerLanguage(sourceCase.getCustomerLanguage());
    serviceCase.setDevelopmentCode(sourceCase.getDevelopmentCode());
    serviceCase.setDmsOrderNumber(sourceCase.getDmsOrderNumber());
    serviceCase.setCustomerName(sourceCase.getCustomerName());
    serviceCase.setCustomerNameSource(sourceCase.getCustomerNameSource());
    serviceCase.setDmsCustomerNumber(sourceCase.getDmsCustomerNumber());
    serviceCase.setGcdmCustomerId(sourceCase.getGcdmCustomerId());
    serviceCase.setHasSalesStop(sourceCase.getHasSalesStop());
    serviceCase.setHasWarnings(sourceCase.getHasWarnings());
    serviceCase.setIsDmsPreOrder(sourceCase.getIsDmsPreOrder());
    serviceCase.setLastModifierUserName(sourceCase.getLastModifierUserName());
    serviceCase.setLicensePlate(sourceCase.getLicensePlate());
    serviceCase.setModelDesignation(sourceCase.getModelDesignation());
    serviceCase.setNetPrice(sourceCase.getNetPrice());
    serviceCase.setSmallCosyImageUrl(sourceCase.getSmallCosyImageUrl());
    serviceCase.setTotalPrice(sourceCase.getTotalPrice());
    serviceCase.setTypeCode(sourceCase.getTypeCode());
    serviceCase.setVehicle(sourceCase.getVehicle() ? Vehicle.fromPlainObject(sourceCase.getVehicle()) : null);
    serviceCase.setVinLong(sourceCase.getVinLong());
    serviceCase.setVinShort(sourceCase.getVinShort());
    serviceCase.setWarnings(plainServiceCaseObject.warnings || []);
    serviceCase.setCampaigns(plainServiceCaseObject.vehicleCampaignCollection);
    serviceCase.setServiceContracts(plainServiceCaseObject.serviceContracts);
    serviceCase.setWarrantyRestrictions(plainServiceCaseObject.warrantyRestrictions);
    serviceCase.setSccId(plainServiceCaseObject.sccId);

    serviceCase.setSchedulingInformation(sourceCase.getSchedulingInformation());

    serviceCase.setCurrentMileageInKilometers(sourceCase.getCurrentMileageInKilometers());
    serviceCase.setCurrentMileageSource(sourceCase.getCurrentMileageSource());
    serviceCase.setCurrentMileageRecordedAt(sourceCase.getCurrentMileageRecordedAt());
    serviceCase.mileageRecord = plainServiceCaseObject.mileageRecord;
    serviceCase.mileage = plainServiceCaseObject.mileage;

    serviceCase.updateDemandCategories();

    // default value is true even if property is null or undefined
    serviceCase.isAlreadyPersisted = plainServiceCaseObject.isPersisted !== false;

    return serviceCase;
  }

  addDefects(defects: IDefect[]): IJob {
    const job = this.getActiveJobCreateIfNotExists(defects[0].getDescription());
    (job as Job).addDefects(defects);
    return job;
  }

  addFlatRateUnits(flatRateUnits: IFlatRateUnit[]): IJob {
    const job = this.getActiveJobCreateIfNotExists(flatRateUnits[0].getDescription());
    (job as Job).addOrReplaceFlatRateUnits(flatRateUnits);
    return job;
  }

  addParts(parts: IPart[]): IJob {
    const job = this.getActiveJobCreateIfNotExists(parts[0].getDescription());
    (job as Job).addParts(parts);
    return job;
  }

  addJob(job: IJob): IJob {
    const optionalPackage = job.getPackages().at(0);
    if (optionalPackage && this.hasPackage(optionalPackage) && !optionalPackage.getIsLocal()) {
      return this.replaceExistingPackage(optionalPackage);
    } else {
      this.addOrReplaceJob(job);
      return job;
    }
  }

  addOrReplacePackage(servicePackage: IPackage): IJob {
    if (this.hasPackage(servicePackage) && !servicePackage.getIsLocal()) {
      return this.replaceExistingPackage(servicePackage);
    } else {
      return this.addPackageToNewJob(servicePackage);
    }
  }

  private replaceExistingPackage(servicePackage: IPackage): IJob {
    const job = this.getJobs().find((job: Job) => job.hasPackage(servicePackage)) as Job;
    job.addOrReplacePackage(servicePackage);

    return job;
  }

  private addPackageToNewJob(servicePackage: IPackage): IJob {
    const job = new Job(Util.createRandomUuid(), servicePackage.getDescription());
    job.setPositionNumber(this.getNextJobPositionNumber());
    job.addOrReplacePackage(servicePackage);
    this.addOrReplaceJob(job);
    return job;
  }

  private hasPackage(servicePackage: IPackage): boolean {
    return this.getJobs().some((job: Job) => job.hasPackage(servicePackage));
  }

  vehicleDataMissing(): boolean {
    // check if vehicle data is missing, best guess is there is a VIN7 but no model designation
    return this.getVinShort() && !this.getModelDesignation();
  }

  getCampaigns(): VehicleCampaignCollection {
    return this.vehicleCampaignCollection;
  }

  getServiceContracts(): ServiceContract[] {
    return this.serviceContracts;
  }

  getWarnings(): VehicleWarning[] {
    return this.warnings;
  }

  setWarnings(warnings: VehicleWarning[]): void {
    this.warnings = warnings;
  }

  addWarning(warning: VehicleWarning): void {
    this.warnings.push(warning);
    this.setHasWarnings(true);
    if (warning.isStop) {
      this.setHasSalesStop(true);
    }
  }

  setWarrantyRestrictions(warrantyRestrictions: WarrantyRestriction[]): void {
    this.warrantyRestrictions = warrantyRestrictions;
  }

  getWarrantyRestrictions(): WarrantyRestriction[] {
    return this.warrantyRestrictions;
  }

  updateMetaDataOnly(update: ServiceCase): void {
    this.setAssignedDistributionPartnerNumber(update.getAssignedDistributionPartnerNumber());
    this.setAssignedOutletNumber(update.getAssignedOutletNumber());
    this.setAssignedBusinessPartnerId(update.getAssignedBusinessPartnerId());
    this.setAssignedSpecialistGroup(update.getAssignedSpecialistGroup());
    this.setAssignedUserId(update.getAssignedUserId());
    this.setAssignedUserName(update.getAssignedUserName());
    this.setComment(update.getComment());
    this.setDmsOrderNumber(update.getDmsOrderNumber());
    this.setCustomers(update.getCustomers());
    this.setCustomerName(update.getCustomerName());
    this.setCustomerNameSource(update.getCustomerNameSource());
    this.setDmsCustomerNumber(update.getDmsCustomerNumber());
    this.setGcdmCustomerId(update.getGcdmCustomerId());
    this.setIsDmsPreOrder(update.getIsDmsPreOrder());
    this.setModifiedAt(update.getModifiedAt());
    this.setNetPrice(update.getNetPrice());
    this.setTitle(update.getTitle());
    this.setTotalPrice(update.getTotalPrice());
    this.setCurrencyCode(update.getCurrencyCode());
    this.updateVehicle(update.getVehicle());
    this.setWarnings(update.getWarnings());
    this.setSchedulingInformation(update.getSchedulingInformation());
    this.setSccId(update.getSccId());
    // do not update demand categories as is only necessary, if jobs have changed
  }

  updateJob(updatedJob: IJob): IJob {
    const existingJob = this.getJobByExternalId(updatedJob.getExternalId());
    if (existingJob) {
      existingJob.setIsSelected(updatedJob.getIsSelected());
      existingJob.setTitle(updatedJob.getTitle());
      existingJob.setComment(updatedJob.getComment());
      existingJob.setPositionNumber(updatedJob.getPositionNumber());
      existingJob.setCustomerStatement(updatedJob.getCustomerStatement());
      existingJob.setDemandCategory(updatedJob.getDemandCategory().trim());
      existingJob.setDemandIds(updatedJob.getDemandIds());
      existingJob.setCbsIds(updatedJob.getCbsIds());
      existingJob.setCcmIds(updatedJob.getCcmIds());
      existingJob.setParts(updatedJob.getParts());
      Job.setFlatRateUnitsExternalIdIfMissing(updatedJob.getFlatRateUnits());
      existingJob.setFlatRateUnits(updatedJob.getFlatRateUnits());
      existingJob.setDefects(updatedJob.getDefects());
      updatedJob.getPackages()
        .forEach((_pack: IPackage) => Job.setFlatRateUnitsExternalIdIfMissing(updatedJob.getFlatRateUnits()));
      existingJob.setPackages(updatedJob.getPackages());
      existingJob.setIsFrozenAfterDmsTransfer(updatedJob.getIsFrozenAfterDmsTransfer());

      if (!existingJob.getIsActive() && updatedJob.getIsActive()) {
        // if job shall be activated, all other jobs need to be deactivated
        this.getJobs().forEach(job => job.setIsActive(false));
      }
      existingJob.setIsActive(updatedJob.getIsActive());
      this.updateDemandCategories();
    }

    return existingJob;
  }

  updateVehicle(updatedVehicle: IVehicle): void {
    this.setVehicle(updatedVehicle);
    this.setBrand(updatedVehicle?.getBrand() || null);
    this.setDevelopmentCode(updatedVehicle?.getDevelopmentSeries() || null);
    this.setModelDesignation(updatedVehicle?.getModelDesignation() || null);
    this.setSmallCosyImageUrl(updatedVehicle?.getImageUrlSmall() || null);
    this.setTypeCode(updatedVehicle?.getTypeCode() || null);
    this.setVinLong(updatedVehicle?.getVin() || null);
    this.setVinShort(updatedVehicle?.getVin7() || null);
    this.setLicensePlate(updatedVehicle?.getLicensePlate() || null);
    this.setWarnings([]);
  }

  setCurrentMileage(mileageRecord: MileageRecord, mileageUnit: string, locale: string): boolean {
    const currentMileageBefore = this.getCurrentMileageInKilometers();
    const currentMileageSourceBefore = this.getCurrentMileageSource();
    const currentMileageRecordedAtBefore = this.getCurrentMileageRecordedAt();

    this.mileageRecord = mileageRecord;
    // The mileage is null, if mileageRecord is null. However, mileage is undefined, if mileageRecord is undefined.
    this.setCurrentMileageInKilometers(mileageRecord !== null ? mileageRecord?.value : null);
    this.setCurrentMileageSource(mileageRecord?.source || null); // source is never undefined
    this.setCurrentMileageRecordedAt(mileageRecord?.recordedAt ? new Date(mileageRecord.recordedAt) : null);
    this.applyUserData(mileageUnit, locale);

    return currentMileageBefore !== this.getCurrentMileageInKilometers()
      || currentMileageSourceBefore !== this.getCurrentMileageSource()
      || currentMileageRecordedAtBefore?.getTime() !== this.getCurrentMileageRecordedAt()?.getTime();
  }

  applyUserData(mileageUnit: string, locale: string): void {
    const mileage = this.getCurrentMileageInKilometers();
    if (mileage === undefined) {
      this.mileage = '- ' + mileageUnit;
    } else {
      const currentMileage = (mileageUnit === 'km' ? mileage : mileage / 1.609344);
      this.mileage = Math.round(currentMileage).toLocaleString(locale) + ' ' + mileageUnit;
    }
  }

  getMileageRecord(): MileageRecord {
    return this.mileageRecord;
  }

  getMileage(): string {
    return this.mileage;
  }

  setCampaigns(vehicleCampaignCollection: VehicleCampaignCollection): void {
    this.vehicleCampaignCollection = vehicleCampaignCollection;
  }

  setServiceContracts(serviceContracts: ServiceContract[]): void {
    this.serviceContracts = serviceContracts;
  }

  private getActiveJobCreateIfNotExists(title: string): IJob {
    const job = this.getActiveJob() ?? this.addEmptyJobAsFirstJob(title);
    if ((job as Job).isEmpty()) {
      job.setTitle(title);
    }
    return job;
  }

  getActiveJob(): IJob | null {
    const activeJob = this.getJobs().find(j => j.getIsActive());
    if (!activeJob) {
      console.log(
        '[Webapp] ServiceCase {} has no active job. This is fine in some cases, but unusual.',
        this.getExternalId()
      );
    }
    return activeJob || null;
  }

  addEmptyJobAsFirstJob(title: string): Job {
    const emptyJob = new Job(Util.createRandomUuid(), title);
    emptyJob.setIsSelected(true);
    emptyJob.setPositionNumber(-1); // Ensure the new job is still the first after sorting
    this.getJobs().unshift(emptyJob);
    this.markJobAsActive(emptyJob);
    this.setJobPositionNumbers();
    return emptyJob;
  }

  markJobAsActive(job: IJob): void {
    this.getJobs().forEach(j => j.setIsActive(false));
    job.setIsActive(true);
  }

  private addOrReplaceJob(jobToAdd: IJob): void {
    (jobToAdd as Job).prepareJob();

    const jobs = this.getJobs();
    const index = this.getJobIndex(jobToAdd);
    if (index >= 0) {
      jobToAdd.setPositionNumber(jobs[index].getPositionNumber()); // keep position number and job order
      jobs[index] = jobToAdd;
    } else {
      jobToAdd.setPositionNumber(undefined); // keep position numbers and order of existing jobs, sort job at the end
      this.getJobs().push(jobToAdd);
    }
    if (jobToAdd.getPackages().length === 0) {
      this.markJobAsActive(jobToAdd);
    }
    this.updateDemandCategories();
    this.setJobPositionNumbers();
  }

  getJobIndex(job: IJob): number {
    return this.getJobs().findIndex(j => j.getExternalId() === job.getExternalId());
  }

  getJobByExternalId(externalId: string): IJob {
    return this.getJobs().find(j => j.getExternalId() === externalId);
  }

  /**
   * Removes a job from this service case.
   * @param jobToRemove job to remove.
   */
  removeJob(jobToRemove: IJob): IJob {
    // Remove jobs
    const jobs = this.getJobs();
    const existingIndex = this.getJobIndex(jobToRemove);
    if (existingIndex >= 0) {
      jobs.splice(existingIndex, 1);
    }

    this.updateDemandCategories();

    // Sort and reassign position numbers
    this.setJobPositionNumbers();

    return jobToRemove;
  }

  getNextJobPositionNumber(): number {
    if (this.getJobs()?.length > 0) {
      return Math.max(...this.getJobs().map(job => job.getPositionNumber())) + 1;
    } else {
      return 1;
    }
  }

  /**
   * Get the number of elements in the service case.
   * Parts will be counted by their quantity, a package counts as one element.
   */
  getNumberOfElements(): number {
    let numberOfElements = 0;
    this.getJobs().forEach((job: IJob) => {
      numberOfElements += this.getNumberOfPartsInJob(job);
      numberOfElements += job.getFlatRateUnits().length;
      numberOfElements += job.getPackages().length;
      numberOfElements += job.getDefects().length;
    });
    return numberOfElements;
  }

  private getNumberOfPartsInJob(job: IJob) {
    return job.getParts().reduce((sumOfQuantities: number, part: IPart) => {
      return sumOfQuantities + part.getQuantity();
    }, 0);
  }

  private setJobPositionNumbers(): void {
    this.sortJobsByPositionNumber();
    for (let i = 0; i < this.getJobs().length; i++) {
      this.getJobs()[i].setPositionNumber(i + 1);
    }
  }

  private sortJobsByPositionNumber(): void {
    this.getJobs().sort((a, b) => {
      // Arrange jobs with no position number to the end of the list
      return !a.getPositionNumber() || (a.getPositionNumber() > b.getPositionNumber()) ? 1 : -1;
    });
  }

  /**
   * Returns true, if the service case was persisted on backend side, false otherwise.
   */
  isPersisted(): boolean {
    return this.isAlreadyPersisted;
  }

  updateCustomers(customers: CustomerInfo): boolean {
    this.setCustomers(customers);
    return this.setPreferredCustomer(customers.customers);
  }

  setPreferredCustomer(customers: Customer[]): boolean {
    const preferredCustomer: Customer = customers?.find((customer: Customer) => customer.isPreferred)
      ?? customers?.find((customer: Customer) => customer);

    let changed: boolean = false;

    // IDs will always be set, if the ID is changed
    if (preferredCustomer?.idCollection?.dmsId &&
      this.getDmsCustomerNumber() !==
      preferredCustomer.idCollection.dmsId) {
      this.setDmsCustomerNumber(preferredCustomer.idCollection.dmsId);
      changed = true;
    }
    if (preferredCustomer?.idCollection?.retailCrmOkuNumber
      && this.getRetailCrmCustomerId() !== preferredCustomer.idCollection.retailCrmOkuNumber) {
      this.setRetailCrmCustomerId(preferredCustomer.idCollection.retailCrmOkuNumber);
      changed = true;
    }
    if (preferredCustomer?.idCollection?.gcid && this.getGcdmCustomerId() !== preferredCustomer.idCollection.gcid) {
      this.setGcdmCustomerId(preferredCustomer.idCollection.gcid);
      changed = true;
    } else if (this.getGcdmCustomerId() === undefined) {
      this.setGcdmCustomerId(null);
      changed = true;
    }

    // Customer name (and its source) will only set, if the determined preferred customer is not of a lower ranked
    // source: 1. marked as preferred 2. from DMS 3. from RETAIL CRM 4. from WHOLESALE_CRM 5. from GCDM 6. from DDR
    if (this.isCustomerSourceHigher(preferredCustomer) && this.hasCustomerNameChanged(preferredCustomer)) {
      this.setCustomerName(preferredCustomer.nameCollection.name);
      this.setCustomerNameSource(preferredCustomer.source);
      changed = true;
    }

    return changed;
  }

  private isCustomerSourceHigher(customer: Customer): boolean {
    const rankedCustomerSources = [
      CustomerSource.DDR.toString(),
      CustomerSource.GCDM.toString(),
      CustomerSource.WHOLESALE_CRM.toString(),
      CustomerSource.RETAIL_CRM.toString(),
      CustomerSource.DMS.toString()
    ];
    return customer?.isPreferred
      || rankedCustomerSources.indexOf(customer?.source) >= rankedCustomerSources.indexOf(this.getCustomerNameSource());
  }

  private hasCustomerNameChanged(customer: Customer): boolean {
    return this.getCustomerName() !==
      customer?.nameCollection?.name ||
      this.getCustomerNameSource() !==
      customer?.source;
  }

  private updateDemandCategories(): void {
    this.setDemandCategories(
      this.getJobs()
        .map(job => job.getDemandCategory().trim())
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter(category => category.length > 0)
        .sort((a, b) => a.localeCompare(b))
        .join(',')
    );
  }
}
