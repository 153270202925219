import {environment} from '@environments/environment';
import {IVehicle, Vehicle} from '@service-and-repairs/awpintegrationlib';

export class CosyService {
  static COSY_FALLBACK_IMAGE = 'COSY-EU-100-7331zK%25yoKGfl1w1pfag5EntUp6xEaS%25F6ds57Qqd';

  static addBasePathToCosyUrls(vehicle: IVehicle, context: string): IVehicle {
    // if there is no url set, just use a transparent PNG
    return Vehicle.fromPlainObject({
      ...vehicle,
      imageUrlSmall: CosyService.getCosyBaseUrl(context) +
        (vehicle.getImageUrlSmall() || CosyService.COSY_FALLBACK_IMAGE),
      imageUrlLarge: CosyService.getCosyBaseUrl(context) +
        (vehicle.getImageUrlLarge() || CosyService.COSY_FALLBACK_IMAGE)
    });
  }

  private static getCosyBaseUrl(context: string): string {
    switch (context) {
      case 'B2D':
        return environment.urls.cosy.b2d;
      case 'B2D_INTERNAL':
        return environment.urls.cosy.b2dIntranet;
      default:
        return environment.urls.cosy.b2e;
    }
  }
}
