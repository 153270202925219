export class WebAgentCookieRetriever {
  private readonly app: string;
  private popup: Window;
  private readonly webAgentSecuredPopupUrl: string;

  private resolveCallback: () => void;
  private rejectCallback: () => void;

  constructor(app: string, webAgentSecuredPopupUrl: string) {
    this.app = app;
    this.webAgentSecuredPopupUrl = webAgentSecuredPopupUrl;
  }

  public openPopupToSetWebAgentCookie(): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log('[WEN] [WebAgent] Trying to obtain WebAgent cookies "agent-authn-tx" and "am_auth_jwt" for app ' +
        this.app);
      this.resolveCallback = resolve;
      this.rejectCallback = reject;

      window.addEventListener('message', this.handleMessageEventFromPopup);

      this.openPopup();
    });
  }

  /**
   * This method needs to be an arrow function to successfully remove it from the event listeners.
   */
  private handleMessageEventFromPopup = (event: MessageEvent): void => {
    if (event.data === 'auth-complete' && event.origin === new URL(this.webAgentSecuredPopupUrl).origin) {
      console.log('[WEN] [WebAgent] Successfully obtained WebAgent cookie for app ' + this.app);
      this.popup?.close();
      window.removeEventListener('message', this.handleMessageEventFromPopup);
      this.resolveCallback();
    }
  };

  private openPopup(): void {
    const randomString = new Date().getTime().toString(); // time since 1970/1/1 in milliseconds
    const url = new URL(this.webAgentSecuredPopupUrl + '?cacheInvalidator=' + randomString);
    this.popup = window.open(url.toString(), 'webAgentSecuredPopup', 'width=360,height=360');

    this.cleanupIfPopupIsBlocked();
  }

  private cleanupIfPopupIsBlocked(): void {
    if (!this.popup || this.popup.closed) {
      console.log('[WEN] [WebAgent] Popup was blocked, WebAgent cookie was not obtained for app ' + this.app);
      window.removeEventListener('message', this.handleMessageEventFromPopup);
      this.rejectCallback();
    }
  }
}
