export class FlatRateUnitValue {

  number: string;
  text: string;
  value: number;
  displayType: string;
  userComment: string;

  static getFlatRateUnitType(displayType: string): string {
    switch (displayType) {
      case 'DEFAULT':
        return 'AW';
      case 'ARBEITSZEIT':
        return 'AZ';
      default:
        return '';
    }
  }
}
