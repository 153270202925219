import {TranslateService} from '@ngx-translate/core';
import {IBusinessPartner, VehicleType} from '@service-and-repairs/awpintegrationlib';

export class Outlet {
  constructor(public businessPartner: IBusinessPartner,
              public vehicleType: VehicleType,
              public businessNumber: string) {
  }

  toString(translate: TranslateService): string {
    return this.businessPartner.getOutletName() + ' ('
      + this.businessPartner.getStreet() + ' '
      + this.businessPartner.getZipCode() + ' ' + this.businessPartner.getCity() + ') - ('
      + this.businessPartner.getDistributionPartnerNumber() + '/' + this.businessPartner.getOutletNumber() + '/'
      + translate.instant('vehicleType.' + this.vehicleType) + ')';
  }

  toShortString(translate: TranslateService): string {
    return this.businessPartner.getOutletName() + ' ('
      + this.businessPartner.getDistributionPartnerNumber() + '/' + this.businessPartner.getOutletNumber() + '/'
      + translate.instant('vehicleType.' + this.vehicleType) + ')';
  }

  equals(otherOutlet: Outlet): boolean {
    return otherOutlet.businessPartner.getBusinessPartnerId() === this.businessPartner.getBusinessPartnerId() &&
      otherOutlet.vehicleType === this.vehicleType;
  }
}
