import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {VehicleType} from '@service-and-repairs/awpintegrationlib';
import {Observable} from 'rxjs';
import {EnvironmentUrlKey} from '../../../interfaces/IEnvironment';
import {EtkConfiguration} from '../interfaces/etk-configuration';
import {OutletRelatedConfiguration} from '../interfaces/outlet-related-configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private httpClient: HttpClient) {
  }

  static mapConfigVehicleType(vehicleType: VehicleType): string {
    if (vehicleType === VehicleType.PASSENGER_CAR) {
      return 'CAR';
    } else {
      return 'MOT';
    }
  }

  static mapEtkVehicleType(vehicleType: VehicleType): string {
    if (vehicleType === VehicleType.PASSENGER_CAR) {
      return 'PKW';
    } else {
      return 'MOTORRAD';
    }
  }

  static getConfigBaseUrl(key: EnvironmentUrlKey, context: string): string {
    switch (context) {
      case 'B2E':
        return environment.urls[key]?.b2e;
      case 'B2D':
        return environment.urls[key]?.b2d;
      case 'B2D_INTERNAL':
        return environment.urls[key]?.b2dIntranet;
      default:
        return undefined;
    }
  }

  /**
   * Retrieve outlet configuration
   * @return A promise with the config.
   */
  getOutletConfigFromBackend(businessPartnerId: string,
                             vehicleType: VehicleType): Promise<OutletRelatedConfiguration> {
    return new Promise<OutletRelatedConfiguration>((resolve, reject): void => {
      const configVehicleType: string = ConfigurationService.mapConfigVehicleType(vehicleType);
      this.makeHttpGetRequest(`/api/configuration/outlet/${businessPartnerId}/${configVehicleType}`)
        .subscribe({
          next: (response: any) => resolve(response.data),
          error: reject
        });
    });
  }

  /**
   * Retrieve ETK outlet configuration.
   * @return A promise with the config.
   */
  getEtkConfigFromBackend(context: string,
                          businessPartnerId: string,
                          vehicleType: VehicleType): Promise<EtkConfiguration> {
    return new Promise<EtkConfiguration>((resolve, reject): void => {
      const etkConfigUrl: string = ConfigurationService.getConfigBaseUrl('etkConfig', context);
      const etkVehicleType: string = ConfigurationService.mapEtkVehicleType(vehicleType);
      this.makeHttpGetRequest<EtkConfiguration>(etkConfigUrl +
        `/etk-rest/outbound/configuration/outlet?businessPartnerId=${businessPartnerId}&vehicleType=${etkVehicleType}`)
        .subscribe({
          next: resolve,
          error: reject
        });
    });
  }

  private makeHttpGetRequest<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url, {withCredentials: true});
  }
}
