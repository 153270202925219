import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceCase} from '../../../../core/service-case/models/service-case';
import {UserData} from '../../../../core/user/models/user-data';
import {UserService} from '../../../../core/user/services/user.service';

@Component({
  selector: 'app-service-case-for-vin-selection',
  templateUrl: './service-case-for-vin-selection.component.html',
  styleUrls: ['./shared-styles.scss']
})
export class ServiceCaseForVinSelectionComponent implements OnInit {
  @Output()
  existingServiceCaseSelected = new EventEmitter<ServiceCase>();

  @Input()
  caseList: ServiceCase[];

  locale: string;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.userSubject.subscribe((user: UserData) => this.locale = user?.getLocale());
  }

  caseSelected(serviceCase: ServiceCase): void {
    this.existingServiceCaseSelected.emit(serviceCase);
  }
}
