import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {KeyDataLoader} from '../../keydata/services/key-data.loader';
import {ServiceCaseItemUpdate} from '../../service-case/interfaces/service-case-item.update';
import {ServiceCase} from '../../service-case/models/service-case';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {AnalyticsAction} from '../enums/analytics-action';
import {AnalyticsEventType} from '../enums/analytics.event-type';
import {AnalyticsEvent} from '../interfaces/analytics.event';
import {AnalyticsEventPayload} from '../interfaces/analytics.event.payload';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private user: UserData;

  constructor(userService: UserService,
              private httpClient: HttpClient,
              private keyDataLoader: KeyDataLoader
  ) {
    userService.userSubject.subscribe((user: UserData) => this.user = user);
  }

  postUserLoggedInEvent(): void {
    const payload = this.createPayload(AnalyticsAction.LOGIN, 'webapp');
    payload.login = this.user.getSettingsId();
    const event = this.createEvent(AnalyticsEventType.USER, payload);
    this.postEvent(event);
  }

  postKeyPoolButtonClickedEvent(): void {
    const payload = this.createPayload(AnalyticsAction.CLICKED, 'key-pool');
    const event = this.createEvent(AnalyticsEventType.NAVIGATION, payload);
    this.postEvent(event);
  }

  postVehicleSearchEvent(action: AnalyticsAction, origin: string): void {
    const payload = this.createPayload(action, origin);
    const event = this.createEvent(AnalyticsEventType.VEHICLE, payload);
    this.postEvent(event);
  }

  postServiceCaseCrudEvent(action: AnalyticsAction,
                           origin: string,
                           serviceCase: ServiceCase,
                           updatedItems?: ServiceCaseItemUpdate[]): void {
    const payload = this.createPayload(action, origin, serviceCase);
    payload.updatedItems = updatedItems;
    const event = this.createEvent(AnalyticsEventType.SERVICE_CASE, payload);
    this.postEvent(event);
  }

  postDmsRequestEvent(origin: string): void {
    const payload = this.createPayload(AnalyticsAction.CLICKED, origin);
    const event = this.createEvent(AnalyticsEventType.DMS, payload);
    this.postEvent(event);
  }

  postNavigationEvent(destination: string, origin: string): void {
    const payload = this.createPayload(AnalyticsAction.NAVIGATION, origin);
    payload.destination = destination;
    const event = this.createEvent(AnalyticsEventType.NAVIGATION, payload);
    this.postEvent(event);
  }

  private createPayload(action: AnalyticsAction, origin: string, serviceCase?: ServiceCase): AnalyticsEventPayload {
    const keyData = this.keyDataLoader.getKeyDataForVin(serviceCase?.getVinLong());
    return {
      action,
      service: 'webapp',
      origin,
      serviceCaseId: serviceCase?.getExternalId(),
      vin: serviceCase?.getVinLong(),
      appointmentDate: serviceCase?.getSchedulingInformation()?.getReceptionStart()?.toString(),
      returnDate: serviceCase?.getSchedulingInformation()?.getReturnStart()?.toString(),
      readInDate: keyData?.readInDate
    };
  }

  private createEvent(eventType: AnalyticsEventType, eventPayload: AnalyticsEventPayload): AnalyticsEvent {
    const bp = this.user.getBusinessPartner();
    return {
      source: 'AWP',
      environment: environment.deploymentEnvironment,
      created: new Date().getTime(),
      session: sessionStorage.getItem('cavors-session-id') || 'Unknown',
      language: this.user.getLocale().replace('-', '_'),
      country: this.user.getB2XCountryCode(),
      payload: eventPayload,
      'auth-context': {
        context: this.user.getContext(),
        'client-id': bp ? bp.getDistributionPartnerNumber() + '-' + bp.getOutletNumber() : this.user.getSettingsId()
      },
      'event-type': eventType
    };
  }

  private postEvent(event: AnalyticsEvent): void {
    const headers = new HttpHeaders().append('Content-Type', 'application/vnd.air-analytics.v1+json');
    this.httpClient.post('/api/canal/event/' + event.session, event, {headers}).subscribe();
  }
}
