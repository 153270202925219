import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IVehicle, Vehicle} from '@service-and-repairs/awpintegrationlib';
import {VehicleCampaign} from '../../campaign/interfaces/vehicle-campaign';
import {ServiceCase} from '../../service-case/models/service-case';
import {ServiceContract} from '../../service-contract/interfaces/service-contract';
import {WarrantyRestriction} from '../../warranty-restriction/interfaces/warranty-restriction';

@Injectable({
  providedIn: 'root'
})
export class VehicleWarningLoader {
  private ALPINA_SPECIAL_EQUIPMENT_CODE = '0920';

  constructor(private translate: TranslateService) {
  }

  addVehicleWarnings(serviceCase: ServiceCase): boolean {
    const hasWarningBeforeCheck = serviceCase.getHasWarnings();
    const hasStopBeforeCheck = serviceCase.getHasSalesStop();

    this.checkForMissingVehicleData(serviceCase);
    this.checkForAlpina(serviceCase);
    this.checkForIndividualData(serviceCase);
    this.resetWarningsIfNonePresent(serviceCase);

    return serviceCase.getHasWarnings() !== hasWarningBeforeCheck
      || serviceCase.getHasSalesStop() !== hasStopBeforeCheck;
  }

  addTechnicalCampaignWarningsToServiceCase(serviceCase: ServiceCase): boolean {
    const hasWarningBeforeCheck = serviceCase.getHasWarnings();
    const hasStopBeforeCheck = serviceCase.getHasSalesStop();

    this.checkForMissingCampaignData(serviceCase);
    this.checkForActiveCampaigns(serviceCase);
    this.resetWarningsIfNonePresent(serviceCase);

    return serviceCase.getHasWarnings() !== hasWarningBeforeCheck
      || serviceCase.getHasSalesStop() !== hasStopBeforeCheck;
  }

  addServiceContractWarningsToServiceCase(serviceCase: ServiceCase): boolean {
    const hasWarningBeforeCheck = serviceCase.getHasWarnings();
    const hasStopBeforeCheck = serviceCase.getHasSalesStop();

    this.checkForMissingServiceContractData(serviceCase);
    this.checkForValidServiceContracts(serviceCase);
    this.resetWarningsIfNonePresent(serviceCase);

    return serviceCase.getHasWarnings() !== hasWarningBeforeCheck
      || serviceCase.getHasSalesStop() !== hasStopBeforeCheck;
  }

  addWarrantyRestrictionWarningsToServiceCase(serviceCase: ServiceCase): boolean {
    const hasWarningBeforeCheck = serviceCase.getHasWarnings();
    const hasStopBeforeCheck = serviceCase.getHasSalesStop();

    this.checkForMissingWarrantyRestrictions(serviceCase);
    this.checkForWarrantyRestrictions(serviceCase);
    this.resetWarningsIfNonePresent(serviceCase);

    return serviceCase.getHasWarnings() !== hasWarningBeforeCheck
      || serviceCase.getHasSalesStop() !== hasStopBeforeCheck;
  }

  private checkForMissingVehicleData(serviceCase: ServiceCase): void {
    if (serviceCase.vehicleDataMissing()) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.vehicleDataMissing'),
        isStop: false
      });
      if (serviceCase.getVinShort() && !serviceCase.getVinLong()) {
        serviceCase.addWarning({
          message: this.translate.instant('warning.vin17Missing'),
          isStop: false
        });
      }
      serviceCase.addWarning({
        message: this.translate.instant('warning.salesStopMightExist'),
        isStop: true
      });
    }
  }

  private checkForAlpina(serviceCase: ServiceCase): void {
    if (this.isAlpinaVehicle(serviceCase.getVehicle())) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.alpina'),
        isStop: false
      });
    }
  }

  private checkForIndividualData(serviceCase: ServiceCase): void {
    if (this.hasIndividualOption(serviceCase.getVehicle())) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.individualOptions'),
        isStop: false
      });
    }
  }

  private checkForMissingCampaignData(serviceCase: ServiceCase): void {
    if (serviceCase.getCampaigns() === undefined) { // campaigns are null for virtual vehicles
      serviceCase.addWarning({
        message: this.translate.instant('warning.couldNotLoadTechnicalCampaigns'),
        isStop: true
      });
    }
  }

  private checkForActiveCampaigns(serviceCase: ServiceCase): void {
    if (this.hasOpenOrActiveTechnicalCampaign(serviceCase.getCampaigns()?.technicalCampaigns)) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.openOrActiveTechnicalCampaign'),
        isStop: false
      });
    }
    if (this.hasSalesStopFlag(serviceCase.getCampaigns()?.technicalCampaigns)) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.salesStop'),
        isStop: true
      });
    }
  }

  private checkForMissingServiceContractData(serviceCase: ServiceCase): void {
    if (serviceCase.getServiceContracts() === undefined) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.couldNotLoadServiceContracts'),
        isStop: false
      });
    }
  }

  private checkForValidServiceContracts(serviceCase: ServiceCase): void {
    const mileage = serviceCase.getCurrentMileageInKilometers();
    if (mileage && this.hasValidServiceContract(serviceCase.getServiceContracts(), mileage)) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.validServiceContract'),
        isStop: false
      });
    } else if (!mileage && this.hasPossiblyValidServiceContract(serviceCase.getServiceContracts())) {
      serviceCase.addWarning({
        message: this.translate.instant(
          'warning.possiblyValidServiceContractUnknownMileage'),
        isStop: false
      });
    }
  }

  private checkForMissingWarrantyRestrictions(serviceCase: ServiceCase): void {
    if (serviceCase.getWarrantyRestrictions() === undefined) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.couldNotLoadWarrantyRestrictions'),
        isStop: false
      });
    }
  }

  private checkForWarrantyRestrictions(serviceCase: ServiceCase): void {
    if (this.hasWarrantyRestrictions(serviceCase.getWarrantyRestrictions())) {
      serviceCase.addWarning({
        message: this.translate.instant('warning.warrantyRestrictionsExist'),
        isStop: false
      });
    }
  }

  private resetWarningsIfNonePresent(serviceCase: ServiceCase): void {
    // reset warnings and stop flag, is no other suitable warnings are set
    if (serviceCase.getWarnings().length === 0) {
      serviceCase.setHasWarnings(false);
    }
    if (serviceCase.getWarnings().every(warning => !warning.isStop)) {
      serviceCase.setHasSalesStop(false);
    }
  }

  private isAlpinaVehicle(vehicle: IVehicle): boolean {
    const isUsVehicle =
      (vehicle as Vehicle)?.rawData?.vehicleState.vehicleSpecification.typeInformation.country === 'USA';
    return !isUsVehicle &&
      vehicle?.getEquipmentOptions().some(option => option.getCode() === this.ALPINA_SPECIAL_EQUIPMENT_CODE);
  }

  private hasIndividualOption(vehicle: IVehicle): boolean {
    return (vehicle as Vehicle)?.rawData?.individualOptions?.individualOption?.length > 0;
  }

  private hasOpenOrActiveTechnicalCampaign(technicalCampaigns: VehicleCampaign[]): boolean {
    return technicalCampaigns?.some(campaign => campaign.campaign.state.toLowerCase() !== 'closed');
  }

  private hasSalesStopFlag(technicalCampaigns: VehicleCampaign[]): boolean {
    return technicalCampaigns?.some(campaign => campaign.stopSales);
  }

  private hasValidServiceContract(serviceContracts: ServiceContract[], currentMileageInKm: number): boolean {
    return serviceContracts?.some(contract => {
      const contractEndDate = new Date(contract.endDate.substring(0, 19));
      return contractEndDate >= new Date() && contract.endKm.value >= currentMileageInKm;
    });
  }

  private hasPossiblyValidServiceContract(serviceContracts: ServiceContract[]): boolean {
    return serviceContracts?.some(contract => {
      const contractEndDate = new Date(contract.endDate.substring(0, 19));
      return contractEndDate >= new Date();
    });
  }

  private hasWarrantyRestrictions(warrantyRestrictions: WarrantyRestriction[]): boolean {
    return warrantyRestrictions?.length > 0;
  }
}
