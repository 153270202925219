<awp-frontend-configuration [authInfo]="authInfo"
                            [businessPartnerId]="businessPartnerId"
                            [etkConfigurationBaseUrl]="etkConfigurationBaseUrl"
                            [headers]="authHeaders"
                            [language]="locale"
                            [printConfigurationBaseUrl]="printConfigurationBaseUrl"
                            [showAwpThinClientDownload]="userIsAbleToDownloadThinClient()"
                            [showEtkConfiguration]="userIsAbleToSeeEtkInAWP()"
                            [showLeadsConfiguration]="userIsAbleToSeeLeadsInAWP()"
                            [showPrintConfiguration]="userIsAbleToSeePrintInAWP()"
                            [vehicleType]="vehicleType"
                            (configChange)="onConfigChange()"
                            #uegAwpConfigurationWebComponent
                            id="awp-frontend-configuration">
</awp-frontend-configuration>
