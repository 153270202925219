import {AppointmentInfo, EtkVehicleInfo, OrderInfo, VehicleInfo} from '@service-and-repairs/dms-api';

export class DmsVehicle {
  vin: string;
  orderNumber?: string;
  customerId?: string;
  customerName?: string;
  licensePlate?: string;

  private constructor() {
    // use static from.. methods instead
  }

  static fromDcomVehicle(dcomVehicle: VehicleInfo): DmsVehicle {
    const result = new DmsVehicle();
    result.vin = dcomVehicle.vin;
    result.licensePlate = dcomVehicle.numberPlate;
    return result;
  }

  static fromDcomOrder(dcomOrder: OrderInfo): DmsVehicle {
    const result = new DmsVehicle();
    result.vin = dcomOrder.vehicle?.vin;
    result.licensePlate = dcomOrder.vehicle?.numberPlate;
    result.customerId = dcomOrder.customer?.customerId?.dmsId;
    result.customerName = (dcomOrder.customer?.firstName || '') + ' ' + (dcomOrder.customer?.lastName || '');
    result.orderNumber = dcomOrder.serviceOrderNumber;
    return result;
  }

  static fromDcomAppointment(dcomAppointment: AppointmentInfo): DmsVehicle {
    const result = new DmsVehicle();
    result.vin = dcomAppointment.vehicle?.vin;
    result.licensePlate = dcomAppointment.vehicle?.numberPlate;
    result.customerId = dcomAppointment.customer?.customerId?.dmsId;
    result.customerName = (dcomAppointment.customer?.firstName || '') + ' ' + (dcomAppointment.customer?.lastName || '');
    result.orderNumber = dcomAppointment.serviceAppointmentId;
    return result;
  }

  static fromEtkVehicle(etkVehicle: EtkVehicleInfo, licensePlate?: string): DmsVehicle {
    const result = new DmsVehicle();
    // some DMS add empty spaces to a VIN 7
    result.vin = etkVehicle.vin.trim();
    result.licensePlate = licensePlate;
    result.customerId = etkVehicle.customerId;
    result.customerName = etkVehicle.customerName;
    result.orderNumber = etkVehicle.orderNumber;
    return result;
  }
}
