import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class Util {
  /**
   * Check if a script element already exists in the DOM.
   * @param src The script element's source.
   * @param targetDocument The document where the script should be searched.
   */
  private static scriptElementAlreadyExists(src: string, targetDocument: Document): boolean {
    return targetDocument.querySelectorAll(`script[src="${src}"]`).length > 0;
  }

  /**
   * Attach a script element to the specified document. Will only be added once.
   * @param src The source URL of the script element to be added.
   * @param targetDocument The script element will be attached to the specified document's head.
   */
  static attachScript(src: string, targetDocument?: Document): Promise<void> {
    targetDocument = targetDocument || document;
    return new Promise<void>((resolve, reject) => {
      if (Util.scriptElementAlreadyExists(src, targetDocument)) {
        resolve();
      } else if (targetDocument.head) {
        const script = targetDocument.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject('Util: Failed to load script ' + script.src);
        };
        targetDocument.head.appendChild(script);
      } else {
        reject('Util: Failed to attach script ' + src + ' as target document has no head.');
      }
    });
  }

  /**
   * Creates a new UUID using a randomly generated string.
   */
  static createRandomUuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Util.getCryptoRandom() * 16) | 0; // eslint-disable-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
      return v.toString(16);
    });
  }

  static getCryptoRandom(): number {
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
  }

  /**
   * Returns a log line prefix in the form of "YYYY-MM-DDT00:00:00.000Z [Component] [Subcomponent] ".
   * @param component Short, friendly name of the component that logs the line.
   * @param subcomponent Short, friendly name of the subcomponent that logs the line.
   */
  static logPrefix(component?: string, subcomponent?: string): string {
    const componentString = component ? `[${component}] ` : '';
    const subcomponentString = subcomponent ? `[${subcomponent}] ` : '';
    const datetimeString = (new Date()).toISOString() + ' ';
    return datetimeString + componentString + subcomponentString;
  }
}
