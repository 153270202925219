import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-dms-search-message',
  templateUrl: './dms-search-message.component.html',
  styleUrls: ['./dms-search.scss']
})
export class DmsSearchMessageComponent {
  @Input()
  showErrorIndicator = false;

  @Input()
  showNoResultInfo = false;

  @Input()
  errorMessage: string;
}
