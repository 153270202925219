import {Component, OnInit} from '@angular/core';
import {environment} from '@environments/environment';
import {UserService} from '../../core/user/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {DsNavigationItem} from '@bmw-ds/components/ds-interfaces/navigation-bar.interface';
import {SupportInformationService} from '../../core/support/support-information.service';

export const LOCAL_STORAGE_KEY_FOR_NAVIGATION_MENU_COLLAPSED = 'navigationMenuClosed';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {

  expanded = true;
  navigationItems: DsNavigationItem[];

  constructor(private userService: UserService, private translate: TranslateService, public supportInformationService: SupportInformationService) {
    this.expanded = !window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_NAVIGATION_MENU_COLLAPSED);
  }

  ngOnInit(): void {
    this.createAppEntries();
    this.translate.onLangChange.subscribe(() => {
      this.createAppEntries();
    });
  }

  onExpandedChange(): void {
    if (this.expanded) {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY_FOR_NAVIGATION_MENU_COLLAPSED);
    } else {
      window.localStorage.setItem(LOCAL_STORAGE_KEY_FOR_NAVIGATION_MENU_COLLAPSED, 'true');
    }
  }

  private createAppEntries(): void {
    this.navigationItems = [];
    for (const category of environment.categories) {
      const visibleCategory = {
        label: this.translate.instant(category.titleResource),
        group: true,
        children: []
      };
      category.apps.forEach((app) => {
        if (this.userService.getVisibleApps().includes(app)) {
          visibleCategory.children.push({
            label: this.translate.instant(app.titleResource),
            routerLink: app.routingPath,
            icon: app.icon
          });
        }
      });
      if (visibleCategory.children.length > 0) {
        this.navigationItems.push(visibleCategory);
      }
    }
  }
}
