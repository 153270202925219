<ds-modal #tpl size="sm" accentBarTone="caution"
          [ds-modal-trigger-for]="tpl"
          [(isModalOpen)]="visible"
          (modalDismiss)="hide()">
  <ds-box-header>{{ 'case.vehicleError' | translate }}</ds-box-header>
  <ds-box-content>
    <p>{{ 'case.vehicleErrorOverlayText1' | translate }}</p>

    <ds-form-field class="my-4x">
      <input
        ds-input
        ds-form-validation
        type="text"
        minlength="17"
        maxlength="17"
        [(ngModel)]="vin17Input"
        (keyup.enter)="confirm()"
        placeholder="VIN17"
        required
      />
      <ds-error>{{ 'case.enterValidVin17' | translate }}</ds-error>
    </ds-form-field>

    <p>{{ 'case.vehicleErrorOverlayText2' | translate }}</p>

    <button ds-button variant="outline" class="my-4x" (click)="reloadCase()">{{ 'common.reload' | translate }}</button>

    @if (showVinMismatchError) {
      <ds-message
        tone="critical"
        variant="slim"
        class="my-4x"
        [messageText]="('case.vehicleErrorOverlayVinMismatch' | translate)  +' (' + serviceCase?.getVinShort() +')'"
      ></ds-message>
    }
  </ds-box-content>
  <ds-box-footer>
    <button ds-button variant="outline" class="m-3x" (click)="hide()">{{ 'common.cancel' | translate }}</button>
    <button ds-button
            (click)="confirm()"
            [disabled]="vin17Input?.length !== 17"
    >{{ 'common.save' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
