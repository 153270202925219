import {Injectable} from '@angular/core';
import {ServiceCase} from '../../service-case/models/service-case';
import {UserService} from '../../user/services/user.service';
import {VehicleWarningLoader} from '../../vehicle-warning/services/vehicle-warning.loader';
import {VehicleCampaignCollection} from '../interfaces/vehicle-campaign-collection';
import {CampaignService} from './campaign.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignLoader {

  constructor(private campaignService: CampaignService,
              private userService: UserService,
              private vehicleWarningLoader: VehicleWarningLoader) {
  }

  loadCampaignsInServiceCase(serviceCase: ServiceCase): Promise<boolean> {
    if (!serviceCase.getVinLong()) {
      return Promise.resolve(this.updateCampaignsInServiceCase(serviceCase, null));
    }

    const user = this.userService.userSubject.getValue();
    return new Promise(resolve => {
      this.campaignService.getCampaigns(
        serviceCase.getVinLong(),
        user.getContext(),
        user.getLocale(),
        user.getB2XCountryCode(),
        user.getBusinessPartner()
      )
        .then(campaigns => resolve(this.updateCampaignsInServiceCase(serviceCase, campaigns)))
        .catch(reason => {
          this.logBackendErrors(reason);
          resolve(this.updateCampaignsInServiceCase(serviceCase, undefined));
        });
    });
  }

  private updateCampaignsInServiceCase(serviceCase: ServiceCase,
                                       vehicleCampaignCollection: VehicleCampaignCollection): boolean {
    serviceCase.setCampaigns(vehicleCampaignCollection);
    return this.vehicleWarningLoader.addTechnicalCampaignWarningsToServiceCase(serviceCase);
  }

  private logBackendErrors(error: string): void {
    console.log(error);
  }
}
