import {Injectable} from '@angular/core';
import {ServiceCase} from '../models/service-case';
import {ServiceCaseLifecycleManagerService} from './service-case-lifecycle-manager.service';
import {ServiceCaseService} from './service-case.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseLoader {
  constructor(private serviceCaseService: ServiceCaseService,
              private lifecycleManagerService: ServiceCaseLifecycleManagerService) {
  }

  createServiceCase(serviceCase: ServiceCase): Promise<ServiceCase> {
    return new Promise<ServiceCase>((resolve, reject): void => {
      this.serviceCaseService.createServiceCase(serviceCase)
        .then((createdServiceCase: ServiceCase): void => {
          this.lifecycleManagerService.createServiceCaseCentral(createdServiceCase)
            .then((sccId: string) => serviceCase.setSccId(sccId))
            .finally(() => resolve(serviceCase));
        })
        .catch(reason => reject(reason));
    });
  }

  getServiceCaseById(externalId: string): Promise<ServiceCase> {
    return this.serviceCaseService.getServiceCase(externalId);
  }

  getServiceCasesByVin(vin: string): Promise<ServiceCase[]> {
    return this.serviceCaseService.getServiceCasesByVin(vin);
  }
}
