import {ColDef, ITooltipParams, ValueGetterParams} from 'ag-grid-community';
import {VehicleInfo} from '@service-and-repairs/dms-api';
import {TranslateService} from '@ngx-translate/core';

export class DmsSearchColumnDefinitions {
  static getDefaultSearchResultColumnDefinition(): ColDef {
    return {
      resizable: true,
      sortable: true,
      tooltipValueGetter: (params: ITooltipParams) => params.value,
    };
  }

  static getDcomLicensePlateSearchResultColumns(translate: TranslateService): ColDef[] {
    return [
      {
        field: 'numberPlate',
        tooltipField: 'numberPlate',
        headerName: translate.instant('case.licensePlate'),
        headerTooltip: translate.instant('case.licensePlate'),
      },
      {
        field: 'brand',
        tooltipField: 'brand',
        headerName: translate.instant('case.brand'),
        headerTooltip: translate.instant('case.brand'),
      },
      {
        field: 'vin',
        tooltipField: 'vin',
        headerName: translate.instant('vehicle.vin'),
        headerTooltip: translate.instant('vehicle.vin'),
      },
      {
        headerName: translate.instant('vehicle.model'),
        headerTooltip: translate.instant('vehicle.model'),
        valueGetter: (params: ValueGetterParams) => {
          const dcomVehicle: VehicleInfo = params.data as VehicleInfo;
          if (dcomVehicle?.engineeringSeries) {
            return dcomVehicle.model + ' (' + dcomVehicle?.engineeringSeries + ')'
          }
          return dcomVehicle.model;
        },
      },
      {
        field: 'mileage.value',
        tooltipField: 'mileage.value',
        headerName: translate.instant('vehicle.mileage'),
        headerTooltip: translate.instant('vehicle.mileage'),
      },
      {
        field: 'typeKey',
        tooltipField: 'typeKey',
        headerName: translate.instant('vehicle.type'),
        headerTooltip: translate.instant('vehicle.type'),
      },
    ];
  }

  static getDcomOrderSearchResultColumns(translate: TranslateService): ColDef[] {
    return [
      {
        field: 'vehicle.numberPlate',
        tooltipField: 'vehicle.numberPlate',
        headerName: translate.instant('case.licensePlate'),
        headerTooltip: translate.instant('case.licensePlate'),
      },
      {
        field: 'vehicle.brand',
        tooltipField: 'vehicle.brand',
        headerName: translate.instant('case.brand'),
        headerTooltip: translate.instant('case.brand'),
      },
      {
        field: 'vehicle.vin',
        tooltipField: 'vehicle.vin',
        headerName: translate.instant('vehicle.vin'),
        headerTooltip: translate.instant('vehicle.vin'),
      },
      {
        headerName: translate.instant('vehicle.model'),
        headerTooltip: translate.instant('vehicle.model'),
        valueGetter: (params: ValueGetterParams) => {
          const dcomVehicle: VehicleInfo = params.data.vehicle as VehicleInfo;
          if (dcomVehicle?.engineeringSeries) {
            return dcomVehicle.model + ' (' + dcomVehicle?.engineeringSeries + ')'
          }
          return dcomVehicle.model;
        },
      },
      {
        field: 'vehicle.mileage.value',
        tooltipField: 'vehicle.mileage.value',
        headerName: translate.instant('vehicle.mileage'),
        headerTooltip: translate.instant('vehicle.mileage'),
      },
      {
        field: 'vehicle.typeKey',
        tooltipField: 'vehicle.typeKey',
        headerName: translate.instant('vehicle.type'),
        headerTooltip: translate.instant('vehicle.type'),
      },
    ];
  }

  static getEtkSearchResultColumns(translate: TranslateService): ColDef[] {
    return [
      {
        field: 'brand',
        tooltipField: 'brand',
        headerName: translate.instant('case.brand'),
        headerTooltip: translate.instant('case.brand'),
      },
      {
        field: 'vin',
        tooltipField: 'vin',
        headerName: translate.instant('vehicle.vin'),
        headerTooltip: translate.instant('vehicle.vin'),
      },
      {
        field: 'orderNumber',
        tooltipField: 'orderNumber',
        headerName: translate.instant('case.dmsOrderNumber'),
        headerTooltip: translate.instant('case.dmsOrderNumber'),
      },
      {
        field: 'customerId',
        tooltipField: 'customerId',
        headerName: translate.instant('case.customerId'),
        headerTooltip: translate.instant('case.customerId'),
      },
      {
        field: 'customerName',
        tooltipField: 'customerName',
        headerName: translate.instant('case.customerName'),
        headerTooltip: translate.instant('case.customerName'),
      },
    ];
  }
}
