import {Injectable} from '@angular/core';
import {Locales} from '../../../locales';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) {
  }

  /**
   * Switch language. Will default to en-US if translation cannot be found.
   * @param locale Locale of the language to use.
   * @return A promise which is resolved once translation for new language is available.
   */
  use(locale: string): Promise<void> {
    let langChangeSubscription: Subscription;
    const promise = new Promise<void>(resolve => {
      Locales.registerLocale(locale);
      langChangeSubscription = this.translate.onLangChange.subscribe(resolve);
      this.translate.use(locale).subscribe({
        error: () => {
          this.translate.use('en-US').subscribe(resolve);
        }
      });
    });
    promise.then(() => {
      langChangeSubscription.unsubscribe();
    });
    return promise;
  }
}
