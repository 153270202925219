<div class="button-bar my-3x">
  <ds-switch
    [(ngModel)]="filterToPersonalKeyReader"
    (ngModelChange)="keyReaderSwitchChanged()"
    [disabled]="!user?.getKeyReaderId()">
    {{ 'keyPool.myKeyReaderOnly' | translate }}
  </ds-switch>
  <ds-form-field class="mx-3x">
    <input
      ds-input
      type="text"
      placeholder="{{'keyPool.filterKeyPool' | translate}}"
      [(ngModel)]="searchAsYouTypeInput"
      (keyup)="searchAsYouType()">
  </ds-form-field>
  <button ds-button ds-loading variant="outline" (click)="triggerKeyDataReload()"
          [isLoading]="!keyData && !showErrorIndicator">{{ 'keyPool.refresh' | translate }}
  </button>
</div>

@if (showErrorIndicator) {
  <ds-message tone="critical" variant="slim" [messageText]="'keyPool.error' | translate"
              [isDismissable]="false">
  </ds-message>
}

<div class="key-pool-scroll-container">
  <ag-grid-angular class="ag-theme-density"
                   domLayout="autoHeight"
                   [rowSelection]="'single'"
                   [gridOptions]="gridOptions"
                   [rowData]="visibleKeyData"
                   (gridReady)="onGridReady($event)"
                   (rowDoubleClicked)="rowDoubleClicked($event)"
                   [hidden]="!keyData || showErrorIndicator"
                   [columnDefs]="columnDefs">
  </ag-grid-angular>
</div>
