<div class="button-bar mb-3x">
  <ds-form-field>
    <label ds-label for="first-name">{{ 'customer.firstName' | translate }}</label>
    <input ds-input
           required
           id="first-name"
           type="text"
           [(ngModel)]="firstName"
           (keyup.enter)="searchCustomer()"
           [placeholder]="'customer.firstName' | translate">
  </ds-form-field>
  <ds-form-field class="mx-3x">
    <label ds-label for="last-name">{{ 'customer.lastName' | translate }}</label>
    <input ds-input
           required
           id="last-name"
           type="text"
           [(ngModel)]="lastName"
           (keyup.enter)="searchCustomer()"
           [placeholder]="'customer.lastName' | translate">
  </ds-form-field>
  <button ds-button ds-loading
          icon="search"
          (click)="searchCustomer()"
          [isLoading]="showLoadingIndicator"
          [disabled]="(!firstName || firstName.trim().length === 0) && (!lastName || lastName.trim().length === 0)">
  </button>
</div>

@if (searchResult && searchResult.length > 0) {
  <div class="search-result-container p-3x">
    @for (result of searchResult; track result) {
      <div class="single-result">
        <div class="customer-details container-fluid g-4x mb-4x">
          <div class="row">
            <div class="col">
              <div class="text-label">{{ 'case.customerName' | translate }}</div>
              <div class="customer-name">{{ result.customer.nameCollection.name }}</div>
            </div>
            <div class="col">
              <div class="text-label">{{ 'customer.source' | translate }}</div>
              <div>{{ result.customer.source | customerSource }}</div>
            </div>
          </div>
        </div>
        @if (result.vehicles?.length > 0) {
          <ag-grid-angular
            class="ag-theme-density"
            [rowData]="result.vehicles"
            [columnDefs]="vehicleColumnDefinitions"
            [defaultColDef]="defaultColumnDefinition"
            (gridReady)="onGridReady($event)"
            (gridSizeChanged)="resizeColumns()"
            (rowDoubleClicked)="selectVehicle($event.data, result.customer)"
            [enableBrowserTooltips]="true"
            domLayout="autoHeight"
            [rowHeight]="35"
            [headerHeight]="35"
          >
          </ag-grid-angular>
        }
        @if (!result.vehicles || result.vehicles.length === 0) {
          <ds-message tone="neutral" variant="slim" class="my-3x"
                      messageText="{{'customer.noVehicles' | translate}}">
          </ds-message>
        }
      </div>
    }
  </div>
}

@if (searchResult && searchResult.length === 0) {
  <ds-message tone="info" variant="slim" class="my-3x"
              messageText="{{'customer.noSearchResults' | translate}}">
  </ds-message>
}

@if (errorMessage) {
  <ds-message tone="critical" variant="slim" class="my-3x"
              messageText="{{'dms.searchError' | translate}} ({{errorMessage}})">
  </ds-message>
}
