export const countries = [
  'AU',
  'BE',
  'BR',
  'BG',
  'CN',
  'DE',
  'FR',
  'GR',
  'IN',
  'ID',
  'IT',
  'JP',
  'CA',
  'KR',
  'MY',
  'MX',
  'NL',
  'AT',
  'PA',
  'PL',
  'PT',
  'RO',
  'RU',
  'SE',
  'CH',
  'SI',
  'ES',
  'ZA',
  'TH',
  'CZ',
  'HU',
  'GB',
  'US'
];
