import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ICase} from '@service-and-repairs/awpintegrationlib';
import {AuthenticationTokenBearerService} from '../../../auth/authentication-token-bearer.service';
import {ServiceCaseItemUpdateAction} from '../../service-case/enums/service-case-item-update.action';
import {ServiceCaseItemUpdate} from '../../service-case/interfaces/service-case-item.update';
import {UserService} from '../../user/services/user.service';
import {EditAuditEvent} from '../interfaces/edit-audit-event';

@Injectable({
  providedIn: 'root'
})
export class EditAuditService {
  constructor(private http: HttpClient,
              private authTokenBearerService: AuthenticationTokenBearerService,
              private userService: UserService) {
  }

  sendServiceCaseUpdateEvent(serviceCase: ICase,
                             action: ServiceCaseItemUpdateAction,
                             items: ServiceCaseItemUpdate[]): void {
    const event: EditAuditEvent = {
      source: 'service-case',
      session: sessionStorage.getItem('cavors-session-id'),
      created: new Date().getTime(),
      infoObject: 'SERVICE_CASE',
      action,
      serviceCaseId: serviceCase.getExternalId(),
      serviceCaseName: serviceCase.getTitle(),
      payload: {items}
    };

    const url: string = '/api/audit/log/v1/entries';
    this.http.post(
      url,
      event,
      {
        headers: this.getCsslHeaders()
      }
    ).subscribe();
  }

  private getCsslHeaders(): HttpHeaders {
    return this.authTokenBearerService.getCsslHttpHeaders(this.userService.userSubject.getValue());
  }
}
