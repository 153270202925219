<div class="user-dropdown">
  <button
    ds-button variant="ghost"
    icon="arrow_down_small"
    class="user-button"
    (click)="toggleUserDropdown()">{{ user.getName() }}
  </button>
  @if (viewState.userDropdownVisible) {
    <ds-box>
      <ds-box-content spacing="sm">
        <ds-list-item icon="user_settings" [isHoverable]="true"
                      (click)="openSettings()">{{ 'user.settings' | translate }}
        </ds-list-item>
        <ds-list-item icon="logout" [isHoverable]="true" (click)="logOut()">Logout</ds-list-item>
      </ds-box-content>
      <ds-box-content spacing="sm" divider="full" class="user-dropdown-footer text-smallprint">
        <p>AWP {{ supportInformationService.awpVersion }}</p>
        <a (click)="copySupportInformation()">Copy support information</a>
        @if (copyAccessTokenVisible()) {
          <a (click)="copyAccessToken()">Copy access token</a>
        }
        <p>© Copyright {{ getCurrentYear() }} BMW Group</p>
      </ds-box-content>
    </ds-box>
  }
</div>

@if (viewState.userDropdownVisible) {
  <div class="user-dropdown-outside-area" (click)="closeUserDropdown()"></div>
}

<app-user-settings-overlay [state]="viewState"></app-user-settings-overlay>
