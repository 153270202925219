export enum CsslBrands {
  BMW = 'BMW',
  BMW_I = 'BMW_I',
  MINI = 'MINI',
  BMW_MOTORCYCLE = 'BMW_MOTORRAD',
  ROLLS_ROYCE = 'ROLLS_ROYCE',
  C_1 = 'C1',
  BMW_ZINORO = 'ZINORO',
  HUSQVARNA = 'HUSQVARNA',
  UNKNOWN = 'UNKNOWN'
}
