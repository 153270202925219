export class OidcConfigProvider {
  get(key) {
    const accessTokenValidityMs = 1800000; // 30 min
    return new Map([
      ['localhost', {
        clientId: '09beb677-085b-4a2b-927e-b21855ce5ec1', // Disable: ''. Enable: '09beb677-085b-4a2b-927e-b21855ce5ec1'
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth-i.bmwgroup.com',
        accessTokenValidityMs: 15000 // 15 sek
      }],
      ['awp-test.bmwgroup.com', {
        clientId: '7e119931-3096-400f-8b82-8ad842113264',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth-i.bmwgroup.com',
        accessTokenValidityMs: 60000 // 1 min
      }],
      ['awp-test-b2d.bmwgroup.net', {
        clientId: 'dc65b64e-3c4c-4637-92e2-8ddd00eac2bf',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: 60000 // 1 min
      }],
      ['awp-test.bmwgroup.net', {
        clientId: '98a8749a-ae16-43bf-af69-902b15e5b085',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: 60000 // 1 min
      }],
      ['awp-int.bmwgroup.com', {
        clientId: 'd99518d1-9c23-44f6-bd0d-c3b589fc8058',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth-i.bmwgroup.com',
        accessTokenValidityMs: 300000 // 5 min
      }],
      ['awp-int-b2d.bmwgroup.net', {
        clientId: '47701a1d-1220-495b-b3bf-6aecfd306a53',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: 300000 // 5 min
      }],
      ['awp-int.bmwgroup.net', {
        clientId: '8f7d3343-555b-4374-adcd-08ceaf833f65',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: 300000 // 5 min
      }],
      ['awp-qa.bmwgroup.com', {
        clientId: '09beb677-085b-4a2b-927e-b21855ce5ec1',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth-i.bmwgroup.com',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-qa-b2d.bmwgroup.net', {
        clientId: '4fa53da8-d43f-4386-832a-8b3bc36c7e87',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-qa.bmwgroup.net', {
        clientId: '3a6f87ac-326d-4eab-9d53-4d2798e3c4d4',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-us-qa.bmwgroup.com', {
        clientId: '65636c7e-5d0e-4786-9255-b04d27cfca6b',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth-i.bmwgroup.com',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-us-qa-b2d.bmwgroup.net', {
        clientId: 'dbb594d1-b1f5-4317-9f4e-8a5d27b4c50c',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-us-qa.bmwgroup.net', {
        clientId: '6e6627d7-a932-4483-bfad-121a4cc5b063',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-qa.bmwgroup.com.cn', {
        clientId: '49de4a5e-7fb4-474e-9ce8-c13bdedf515a',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth-i.bmwgroup.com',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-cn-qa-b2d.bmwgroup.net', {
        clientId: 'f49e1d18-1842-4a8a-80e6-e88c069c8e2a',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-cn-qa.bmwgroup.net', {
        clientId: 'c916e31f-eba9-49d0-8ec6-0325d6fd1dad',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth-i.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp.bmwgroup.com', {
        clientId: '2e136195-aa07-4564-bb59-5aeb289a0db0',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth.bmwgroup.com',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-b2d.bmwgroup.net', {
        clientId: 'e119b9da-9a15-47dc-980b-e060fdbd7346',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp.bmwgroup.net', {
        clientId: '33610baa-6615-40d2-b8b8-fde4b737120c',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-us.bmwgroup.com', {
        clientId: 'bdaf99ab-6546-4581-9e90-2adbccc5a65e',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth.bmwgroup.com',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-us-b2d.bmwgroup.net', {
        clientId: 'e562d2fd-1d93-4a8b-8b11-1be9914390dd',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-us.bmwgroup.net', {
        clientId: 'ae692c58-f836-42d0-9f2f-4e7ba811570a',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-cn.bmwgroup.net', {
        clientId: '8e1e6c0f-cd5f-4d89-bd81-f4407cb0888c',
        realm: 'intranetb2x',
        businessContext: 'B2E',
        wenEnvironment: 'auth.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp-cn-b2d.bmwgroup.net', {
        clientId: '43253e48-e6a1-48bf-950a-326ae0a0015b',
        realm: 'intranetb2x',
        businessContext: 'B2D_INTERNAL',
        wenEnvironment: 'auth.bmwgroup.net',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }],
      ['awp.bmwgroup.com.cn', {
        clientId: '8b74bbdc-6037-4a58-8963-1173ee7232f5',
        realm: 'internetb2x',
        businessContext: 'B2D',
        wenEnvironment: 'auth.bmwgroup.com.cn',
        accessTokenValidityMs: accessTokenValidityMs // 30 min
      }]
    ]).get(key);
  }
}
