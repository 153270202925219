export enum AnalyticsAction {
  CLICKED = 'CLICKED',
  LOGIN = 'LOGIN',
  NAVIGATION = 'NAVIGATION',
  CREATE_IN_NEW_TAB = 'CREATE_IN_NEW_TAB', // a new tab is opened with a newly created service case
  OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB', // a new tab is opened for an existing service case
  UPDATE_ADD_ITEM = 'UPDATE_ADD_ITEM', // a new item is being added to an active service case
  DELETE = 'DELETE', // a service case is being deleted
  SWITCH_TO_OPEN_TAB = 'SWITCH_TO_OPEN_TAB',
  VEHICLE_SEARCH = 'VEHICLE_SEARCH'
}