import {TransferFormat, TransferMode} from '@service-and-repairs/dms-api';
import {DcomDmsSettings} from './dcom-dms-settings';
import {EtkDmsSettings} from './etk-dms-settings';

export class DmsSettings {
  transferMode = TransferMode.Undefined;
  transferFormat = TransferFormat.Unknown;
  transferFileStorageLocation: string = undefined;
  lockJobsAfterTransfer = false;
  lockJobsAfterTransferAsPreOrder = false;
  dcom = new DcomDmsSettings();
  etk = new EtkDmsSettings();
}
