@if (showErrorIndicator) {
  <ds-message tone="critical"
              variant="slim"
              [isDismissable]="true"
              messageText="{{('dms.searchError' | translate) + (errorMessage ? ' (' + errorMessage + ')' : '')}}">
  </ds-message>
}
@if (showNoResultInfo) {
  <ds-message tone="info"
              variant="slim"
              [isDismissable]="true"
              messageText="{{('dms.noResult' | translate)}}" class="error-message">
  </ds-message>
}
