import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  ActorId,
  Defect,
  FlatRateUnit,
  IDefect,
  IFlatRateUnit,
  IJob,
  IPackage,
  IPart,
  IServerApi,
  Package,
  Part,
  Topic
} from '@service-and-repairs/awpintegrationlib';
import {Customer} from '@service-and-repairs/calm-leads-customer-lib-api';
import {
  ServiceCaseUpdateQueueService
} from '../../../components/service-case-bar/case/service-case-update-queue.service';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {CustomerService} from '../../customer/services/customer.service';
import {Job} from '../../job/job';
import {UserService} from '../../user/services/user.service';
import {ServiceCaseUpdateScope} from '../enums/service-case-update-scope';
import {FlatRateUnitGroup} from '../interfaces/flat-rate-unit-group';
import {FlatRateUnitValue} from './flat-rate-unit-value';
import {ServiceCase} from './service-case';
import {ServiceCaseHolder} from './service-case-holder';
import {ServiceCaseUpdateLogger} from './service-case-update-logger';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseUpdater {

  private awpLib: IServerApi;

  constructor(awpClientLibService: AwpClientLibService,
              private customerService: CustomerService,
              private serviceCaseHolder: ServiceCaseHolder,
              private translate: TranslateService,
              private updateQueue: ServiceCaseUpdateQueueService,
              private userService: UserService,
              private serviceCaseUpdateLogger: ServiceCaseUpdateLogger) {
    this.awpLib = awpClientLibService.serverApi;
    this.subscribeToMetadataUpdates();
    this.subscribeToUpdateJobs();
    this.subscribeToAddItems();
    this.subscribeToCustomerChanges();
  }

  saveServiceCaseMetadata(serviceCase: ServiceCase): void {
    serviceCase.setLastModifierUserName(this.userService.userSubject.getValue().getName());
    // ATTENTION: A clone is required to prevent the service case that we want to
    // save from being changed (by the UI) while awaiting the backend response.
    const serviceCaseClone: ServiceCase = ServiceCase.fromPlainObject(serviceCase);
    const updatePromise: Promise<ServiceCase> = this.updateQueue.addMetadataUpdate(serviceCaseClone);
    this.handleUpdateServiceCaseResult(updatePromise, serviceCase.getExternalId());
  }

  private saveServiceCaseJob(externalId: string, job: IJob): void {
    // ATTENTION: A clone is required to prevent the service case that we want to
    // save from being changed (by the UI) while awaiting the backend response.
    const jobClone: Job = Job.fromPlainObject(job);
    const updatePromise: Promise<Job> = this.updateQueue.addJobUpdate(externalId, jobClone);
    this.handleUpdateServiceCaseResult(updatePromise, job.getExternalId());
  }

  private saveServiceCaseJobDeletion(externalId: string, jobId: string): void {
    const updatePromise: Promise<void> = this.updateQueue.addJobDeletion(externalId, jobId);
    this.handleUpdateServiceCaseResult(updatePromise, jobId);
  }

  private handleUpdateServiceCaseResult(updatePromise: Promise<any>, updateId: string): void {
    const saveEventId: string = this.serviceCaseHolder.addServiceCaseIsBeingSavedEvent(updateId);
    updatePromise
      .then((): void => {
        // Nothing to do
      })
      .catch((): void => {
        this.handleUpdateErrors(updateId);
      })
      .finally(() => this.serviceCaseHolder.removeServiceCaseIsBeingSavedEvent(updateId, saveEventId));
  }

  private applyUpdateToActiveServiceCase(updateScope: ServiceCaseUpdateScope, job?: IJob): void {
    const activeCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    if (activeCase) {
      switch (updateScope) {
        case ServiceCaseUpdateScope.UPDATE_METADATA: // Service Case metadata will always be persisted
          break;
        case ServiceCaseUpdateScope.UPDATE_JOB:
          this.saveServiceCaseJob(activeCase.getExternalId(), job);
          break;
        case ServiceCaseUpdateScope.DELETE_JOB:
          this.saveServiceCaseJobDeletion(activeCase.getExternalId(), job.getExternalId());
          break;
      }
      // Service case metadata is also changed if jobs are added, updated or deleted because demand categories
      // may haven been changed.
      this.saveServiceCaseMetadata(activeCase);
      this.serviceCaseHolder.onActiveCaseChanged();
    } else {
      this.awpLib.notifyError(this.translate.instant('case.noCaseToModify'));
    }
  }

  handleUpdateErrors(updateId: string): void {
    // Ignore errors, if more updates for the same service case are queued.
    if (!this.updateQueue.hasMoreWithSameId(updateId)) {
      this.awpLib.notifyError(this.translate.instant('case.notificationSaveError'));
    }
  }

  private subscribeToCustomerChanges(): void {
    this.customerService.subscribeToSetPreferredCustomer((vin: string, customers: Customer[]): void => {
      const activeServiceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      activeServiceCase.setCustomers({
        customers,
        dmsCustomerFurtherNotes: activeServiceCase.getCustomers().dmsCustomerFurtherNotes
      });
      if (activeServiceCase.getVinLong() === vin && activeServiceCase.setPreferredCustomer(customers)) {
        this.saveServiceCaseMetadata(activeServiceCase);
      }
      this.serviceCaseHolder.onActiveCaseChanged();
    });
  }

  private subscribeToMetadataUpdates(): void {
    this.awpLib.subscribeToActiveCaseModification(
      Topic.UPDATE_META_DATA,
      (plainServiceCase: any, origin: ActorId): void => {
        const serviceCase: ServiceCase = ServiceCase.fromPlainObject(plainServiceCase);
        this.serviceCaseHolder.getActiveCase().updateMetaDataOnly(serviceCase);
        this.serviceCaseUpdateLogger.logUpdatedServiceCase(origin, serviceCase);
        this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.UPDATE_METADATA);
      }
    );
  }

  private subscribeToUpdateJobs(): void {
    this.awpLib.subscribeToActiveCaseModification(Topic.ADD_EMPTY_JOB, (_emptyPayload: null, origin: ActorId): void => {
      this.showItemsAddedToastMessage(Topic.ADD_EMPTY_JOB);
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      const job: Job = serviceCase.addEmptyJobAsFirstJob(this.translate.instant('case.newJob'));
      this.serviceCaseUpdateLogger.logAddedJob(job, origin, serviceCase);
      // update all jobs as their position numbers have changed
      serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
        ServiceCaseUpdateScope.UPDATE_JOB,
        j
      ));
    });

    this.awpLib.subscribeToActiveCaseModification(Topic.ADD_JOBS, (plainJobs: any[], origin: ActorId): void => {
      this.showItemsAddedToastMessage(Topic.ADD_JOBS);
      const jobs: Job[] = plainJobs.map(plainJob => Job.fromPlainObject(plainJob));
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      jobs.forEach((job: Job): void => {
          const addedJob: IJob = serviceCase.addJob(job);
          this.serviceCaseUpdateLogger.logAddedJob(addedJob, origin, serviceCase);
          this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.UPDATE_JOB, addedJob);
        }
      );
    });

    this.awpLib.subscribeToActiveCaseModification(Topic.UPDATE_JOB, (plainJob: any, origin: ActorId): void => {
      const updatedJob: Job = Job.fromPlainObject(plainJob);
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      const job: IJob = serviceCase.getJobs()
        .find((j: IJob): boolean => j.getExternalId() === updatedJob.getExternalId());
      this.serviceCaseUpdateLogger.logUpdatedJob(updatedJob, job, origin, serviceCase);
      this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.UPDATE_JOB, serviceCase.updateJob(updatedJob));
    });

    this.awpLib.subscribeToActiveCaseModification(Topic.REMOVE_JOBS, (plainJobs: any[], origin: ActorId): void => {
      const jobs: Job[] = plainJobs.map(plainJob => Job.fromPlainObject(plainJob));
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      jobs.forEach((job: Job): void => {
        const deletedJob: IJob = serviceCase.removeJob(job);
        this.serviceCaseUpdateLogger.logDeletedJob(deletedJob, origin, serviceCase);
        this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.DELETE_JOB, deletedJob);
      });
      // update all remaining jobs as their position numbers may have changed
      serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
        ServiceCaseUpdateScope.UPDATE_JOB,
        j
      ));
    });
  }

  private subscribeToAddItems(): void {
    this.awpLib.subscribeToActiveCaseModification(Topic.ADD_DEFECTS, (plainItems: any[], origin: ActorId): void => {
      this.showItemsAddedToastMessage(Topic.ADD_DEFECTS);
      const defects: IDefect[] = plainItems.map(plainItem => Defect.constructFromPlainObject(plainItem));
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      const job: IJob = serviceCase.addDefects(defects);
      this.serviceCaseUpdateLogger.logAddedDefects(defects, job, origin, serviceCase);
      // update all jobs as their position numbers may have changed, if a new empty job was added at the beginning
      serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
        ServiceCaseUpdateScope.UPDATE_JOB,
        j
      ));
    });

    this.awpLib.subscribeToActiveCaseModification(
      Topic.ADD_FLAT_RATE_UNITS,
      (plainItems: any[], origin: ActorId): void => {
        this.showItemsAddedToastMessage(Topic.ADD_FLAT_RATE_UNITS);
        const flatRateUnits: IFlatRateUnit[] = plainItems.map(plainItem => FlatRateUnit.constructFromPlainObject(
          plainItem));
        const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
        const job: IJob = serviceCase.addFlatRateUnits(flatRateUnits);
        this.serviceCaseUpdateLogger.logAddedFlatRateUnits(flatRateUnits, job, origin, serviceCase);
        // update all jobs as their position numbers may have changed, if a new empty job was added at the beginning
        serviceCase.getJobs()
          .forEach((j: IJob) => this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.UPDATE_JOB, j));
      }
    );

    this.awpLib.subscribeToActiveCaseModification(Topic.ADD_PARTS, (plainItems: any[], origin: ActorId): void => {
      this.showItemsAddedToastMessage(Topic.ADD_PARTS);
      const parts: IPart[] = plainItems.map(plainItem => Part.constructFromPlainObject(plainItem));
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      const job: IJob = serviceCase.addParts(parts);
      this.serviceCaseUpdateLogger.logAddedParts(parts, job, origin, serviceCase);
      // update all jobs as their position numbers may have changed, if a new empty job was added at the beginning
      serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
        ServiceCaseUpdateScope.UPDATE_JOB,
        j
      ));
    });

    this.awpLib.subscribeToActiveCaseModification(Topic.ADD_PACKAGES, (plainItems: any[], origin: ActorId): void => {
      this.showItemsAddedToastMessage(Topic.ADD_PACKAGES);
      const packages: IPackage[] = plainItems.map(plainItem => Package.constructFromPlainObject(plainItem));
      const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
      packages.forEach((pack: IPackage): void => {
          const job: IJob = serviceCase.addOrReplacePackage(pack);
          this.serviceCaseUpdateLogger.logAddedPackage(pack, job, origin, serviceCase);
          this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.UPDATE_JOB, job);
        }
      );
    });
  }

  addFlatRateUnitsFromFlatRateSearch(flatRateUnitGroups: FlatRateUnitGroup[]): void {
    this.showItemsAddedToastMessage(Topic.ADD_FLAT_RATE_UNITS);
    const flatRateUnits: IFlatRateUnit[] = [];
    flatRateUnitGroups.forEach((flatRateGroupValue: FlatRateUnitGroup) =>
      flatRateGroupValue.flatrates.forEach((flatRateValue: FlatRateUnitValue) =>
        flatRateUnits.push(this.createFlatRateUnit(flatRateValue))
      )
    );

    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    const job: IJob = serviceCase.addFlatRateUnits(flatRateUnits);
    this.serviceCaseUpdateLogger.logAddedFlatRateUnits(flatRateUnits, job, 'flat-rate-search', serviceCase);
    // update all jobs as their position numbers may have changed, if a new empty job was added at the beginning
    serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
      ServiceCaseUpdateScope.UPDATE_JOB,
      j
    ));
  }

  private createFlatRateUnit(fruValue: FlatRateUnitValue): IFlatRateUnit {
    const flatRateUnitForAwp: FlatRateUnit = new FlatRateUnit(
      fruValue.number,
      fruValue.text,
      fruValue.value,
      FlatRateUnitValue.getFlatRateUnitType(fruValue.displayType),
      FlatRateUnitValue.getFlatRateUnitType(fruValue.displayType)
    );
    flatRateUnitForAwp.setComment(fruValue.userComment?.trim() || undefined);

    return flatRateUnitForAwp;
  }

  addPartsFromPartSearch(parts: IPart[]): void {
    this.showItemsAddedToastMessage(Topic.ADD_PARTS);
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    const job: IJob = serviceCase.addParts(parts);
    this.serviceCaseUpdateLogger.logAddedParts(parts, job, 'parts-search', serviceCase);
    // update all jobs as their position numbers may have changed, if a new empty job was added at the beginning
    serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
      ServiceCaseUpdateScope.UPDATE_JOB,
      j
    ));
  }

  removeJobsFromServiceCase(jobs: IJob[]): void {
    const serviceCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    jobs.forEach((job: IJob): void => {
      const deletedJob: IJob = serviceCase.removeJob(job);
      this.serviceCaseUpdateLogger.logDeletedJob(deletedJob, 'service-case-shortlist', serviceCase);
      this.applyUpdateToActiveServiceCase(ServiceCaseUpdateScope.DELETE_JOB, deletedJob);
    });
    // update all remaining jobs as their position numbers may have changed
    serviceCase.getJobs().forEach((j: IJob) => this.applyUpdateToActiveServiceCase(
      ServiceCaseUpdateScope.UPDATE_JOB,
      j
    ));
  }

  showItemsAddedToastMessage(topic: Topic): void {
    if (!window.location.pathname.includes('caseoverview') && !window.location.pathname.includes('casedetails')) {
      let key: string = '';
      switch (topic) {
        case Topic.ADD_DEFECTS:
          key = 'common.addedDefects';
          break;
        case Topic.ADD_FLAT_RATE_UNITS:
          key = 'common.addedFlatRateUnits';
          break;
        case Topic.ADD_PARTS:
          key = 'common.addedParts';
          break;
        case Topic.ADD_PACKAGES:
          key = 'common.addedPackages';
          break;
        case Topic.ADD_JOBS:
          key = 'common.addedJobs';
          break;
        case Topic.ADD_EMPTY_JOB:
          key = 'common.addedJobs';
          break;
      }
      this.awpLib.notifyInfo(this.translate.instant(key));
    }
  }
}
