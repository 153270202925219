<ds-modal #tpl [ds-modal-trigger-for]="tpl" [isModalOpen]="caseList.length > 0 || orderList.length > 0"
          (dismiss)="close()" (modalDismiss)="close()" [dimensionsConfig]="{height: '90vh'}">
  <ds-box-header>{{ 'case.selectCase' | translate }}</ds-box-header>

  <ds-box-content class="overlay-box-content">
    <div class="button-bar">
      <button ds-button variant="primary" (click)="newCaseSelected()">{{ "case.new" | translate }}</button>
    </div>

    @if (orderList.length === 0) {
      <p>{{ 'case.existingCases' | translate }}</p>
    } @else if (caseList.length === 0) {
      <p>{{ 'case.existingDmsOrders' | translate }}</p>
    } @else {
      <p>{{ 'case.existingCasesAndDmsOrders' | translate }}</p>
    }

    <ds-tabs>
      <!-- new Angular control flow does not seem to work with ds-tabs, fall back to ngIf -->
      <ng-container *ngIf="caseList.length > 0">
        <ds-tabs-label for="existing-cases">{{ 'categories.serviceCases' | translate }} ({{ caseList.length }})
        </ds-tabs-label>
        <ds-tabs-content id="existing-cases">
          <app-service-case-for-vin-selection
            [caseList]="caseList"
            (existingServiceCaseSelected)="caseSelected($event)">
          </app-service-case-for-vin-selection>
        </ds-tabs-content>
      </ng-container>
      <ng-container *ngIf="orderList.length > 0">
        <ds-tabs-label for="existing-orders">{{ 'dms.ordersOrAppointments' | translate }} ({{ orderList.length }})
        </ds-tabs-label>
        <ds-tabs-content id="existing-orders">
          <app-dms-order-for-vin-selection
            [orderList]="orderList"
            (newCaseWithOrder)="orderSelected($event)">
          </app-dms-order-for-vin-selection>
        </ds-tabs-content>
      </ng-container>
    </ds-tabs>
  </ds-box-content>

  <ds-box-footer>
    <button ds-button variant="outline" (click)="close()">{{ 'common.cancel' | translate }}</button>
  </ds-box-footer>
</ds-modal>
