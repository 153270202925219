export enum VehicleSearchOrigin {
  NO_VEHICLE = 'NO_VEHICLE',
  TYPE_CODE = 'TYPE_CODE',
  VIN7 = 'VIN7',
  VIN17 = 'VIN17',
  CASE_ID = 'CASE_ID',
  TYPE_ATTRIBUTES = 'TYPE_ATTRIBUTES',
  DMS_CUSTOMER_NAME = 'DMS_CUSTOMER_NAME',
  DMS_LICENSE_PLATE = 'DMS_LICENSE_PLATE',
  DMS_ORDER_NUMBER = 'DMS_ORDER_NUMBER',
  KEY_POOL = 'KEY_POOL',
}
