import {registerLocaleData} from '@angular/common';
import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localeNo from '@angular/common/locales/nb';
import localePl from '@angular/common/locales/pl';
import localePtPt from '@angular/common/locales/pt-PT';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSv from '@angular/common/locales/sv';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeZh from '@angular/common/locales/zh';

const locales = {
  'bg-BG': localeBg,
  'cs-CZ': localeCs,
  'da-DK': localeDa,
  'de-DE': localeDe,
  'el-GR': localeEl,
  'es-ES': localeEs,
  // en-US is registered by default
  'en-US': undefined,
  'en-GB': localeEnGb,
  'fi-FI': localeFi,
  'fr-FR': localeFr,
  'hu-HU': localeHu,
  'it-IT': localeIt,
  'ja-JP': localeJa,
  'ko-KR': localeKo,
  'nl-NL': localeNl,
  'nb-NO': localeNo,
  'pl-PL': localePl,
  'pt-PT': localePtPt,
  'ro-RO': localeRo,
  'ru-RU': localeRu,
  'sk-SK': localeSk,
  'sl-SI': localeSl,
  'sv-SE': localeSv,
  'th-TH': localeTh,
  'tr-TR': localeTr,
  'uk-UA': localeUk,
  'zh-CN': localeZh,
  'zh-TW': localeZh
};

export class Locales {
  static getLocaleIds(): string[] {
    return Object.keys(locales);
  }

  static registerLocale(localeId: string): void {
    if (locales[localeId]) {
      registerLocaleData(locales[localeId], localeId);
    } else if (localeId !== 'en-US') {
      console.warn('src/app/components/user-dropdown/locales.ts: Language ' + localeId + ' not found.');
    }
  }
}



