import {Injectable} from '@angular/core';
import {IDefect, IFlatRateUnit, IJob, IPackage, IPart} from '@service-and-repairs/awpintegrationlib';
import {AnalyticsAction} from '../../analytics/enums/analytics-action';
import {AnalyticsService} from '../../analytics/services/analytics.service';
import {EditAuditService} from '../../edit-audit/services/edit-audit.service';
import {ServiceCaseItemUpdateAction} from '../enums/service-case-item-update.action';
import {ServiceCaseItemType} from '../enums/service-case-item.type';
import {ServiceCaseItemUpdate} from '../interfaces/service-case-item.update';
import {ServiceCase} from './service-case';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseUpdateLogger {
  constructor(private analyticsService: AnalyticsService,
              private editAuditService: EditAuditService) {
  }

  logUpdatedServiceCase(origin: string, serviceCase: ServiceCase, updatedItems?: ServiceCaseItemUpdate[]): void {
    this.analyticsService.postServiceCaseCrudEvent(AnalyticsAction.UPDATE_ADD_ITEM, origin, serviceCase, updatedItems);
  }

  logAddedPackage(pack: IPackage, job: IJob, origin: string, serviceCase: ServiceCase): void {
    this.logUpdatedItems(this.getPackageUpdates([pack], [], job), origin, serviceCase);
  }

  logAddedDefects(defects: IDefect[], job: IJob, origin: string, serviceCase: ServiceCase): void {
    this.logUpdatedItems(this.getDefectUpdates(defects, [], job), origin, serviceCase);
  }

  logAddedParts(parts: IPart[], job: IJob, origin: string, serviceCase: ServiceCase): void {
    this.logUpdatedItems(this.getPartUpdates(parts, [], job), origin, serviceCase);
  }

  logAddedFlatRateUnits(flatRateUnits: IFlatRateUnit[], job: IJob, origin: string, serviceCase: ServiceCase): void {
    this.logUpdatedItems(this.getFlatRateUnitUpdates(flatRateUnits, [], job), origin, serviceCase);
  }

  logAddedJob(job: IJob, origin: string, serviceCase: ServiceCase): void {
    const addedItems = [
      {
        jobId: job.getExternalId(),
        demandIds: job.getDemandIds(),
        type: ServiceCaseItemType.JOB,
        id: job.getExternalId(),
        description: job.getTitle(),
        quantity: 1,
        action: ServiceCaseItemUpdateAction.ADD_ITEM
      } as ServiceCaseItemUpdate
    ]
      .concat(this.getDefectUpdates(job.getDefects(), [], job))
      .concat(this.getPartUpdates(job.getParts(), [], job))
      .concat(this.getFlatRateUnitUpdates(job.getFlatRateUnits(), [], job))
      .concat(this.getPackageUpdates(job.getPackages(), [], job))
      .flatMap(item => item);

    this.logUpdatedItems(addedItems, origin, serviceCase);
  }

  logDeletedJob(job: IJob, origin: string, serviceCase: ServiceCase): void {
    const deletedItems = [
      {
        jobId: job.getExternalId(),
        demandIds: job.getDemandIds(),
        type: ServiceCaseItemType.JOB,
        id: job.getExternalId(),
        description: job.getTitle(),
        quantity: 1,
        action: ServiceCaseItemUpdateAction.DELETE_ITEM
      } as ServiceCaseItemUpdate
    ]
      .concat(this.getDefectUpdates([], job.getDefects(), job))
      .concat(this.getPartUpdates([], job.getParts(), job))
      .concat(this.getFlatRateUnitUpdates([], job.getFlatRateUnits(), job))
      .concat(this.getPackageUpdates([], job.getPackages(), job))
      .flatMap(item => item);

    this.logUpdatedItems(deletedItems, origin, serviceCase);
  }

  logUpdatedJob(updatedJob: IJob, job: IJob, origin: string, serviceCase: ServiceCase): void {
    const updatedItems = this.getDefectUpdates(updatedJob.getDefects(), job.getDefects(), updatedJob)
      .concat(this.getPartUpdates(updatedJob.getParts(), job.getParts(), updatedJob))
      .concat(this.getFlatRateUnitUpdates(updatedJob.getFlatRateUnits(), job.getFlatRateUnits(), updatedJob))
      .concat(this.getPackageUpdates(updatedJob.getPackages(), job.getPackages(), updatedJob));

    this.logUpdatedItems(updatedItems, origin, serviceCase);
  }

  private getPackageQuantities(packages: IPackage[], job: IJob): ServiceCaseItemUpdate[] {
    return packages.map(pack => {
      return {
        jobId: job.getExternalId(),
        demandIds: job.getDemandIds(),
        type: ServiceCaseItemType.PACKAGE,
        id: pack.getNumber(),
        description: pack.getDescription(),
        quantity: 1,
        // items initially will be counted as removed as they may not be in the updated item's list
        action: ServiceCaseItemUpdateAction.DELETE_ITEM
      } as ServiceCaseItemUpdate;
    });
  }

  private getPackageUpdates(updatedPackages: IPackage[], packages: IPackage[], job: IJob): ServiceCaseItemUpdate[] {
    const updatedItems = this.getPackageQuantities(packages, job);

    updatedPackages.forEach(pack => {
      // removed items will not be found in updated item's list, they are already listed as removed
      const updatedItem = updatedItems.find(item => item.id === pack.getNumber());
      if (updatedItem) {
        // item is not removed or added
        updatedItem.quantity = 0;
        updatedItem.action = ServiceCaseItemUpdateAction.REMAIN_ITEM;
      } else {
        // item is added
        updatedItems.push({
          jobId: job.getExternalId(),
          demandIds: job.getDemandIds(),
          type: ServiceCaseItemType.PACKAGE,
          id: pack.getNumber(),
          description: pack.getDescription(),
          quantity: 1,
          action: ServiceCaseItemUpdateAction.ADD_ITEM
        } as ServiceCaseItemUpdate);
      }
    });

    return updatedItems.filter(item => item.action !== ServiceCaseItemUpdateAction.REMAIN_ITEM);
  }

  private getDefectQuantities(defects: IDefect[], job: IJob): ServiceCaseItemUpdate[] {
    return defects.map(defect => {
      return {
        jobId: job.getExternalId(),
        demandIds: job.getDemandIds(),
        type: ServiceCaseItemType.DEFECT,
        id: defect.getCode(),
        description: defect.getDescription(),
        quantity: 1,
        // items initially will be counted as removed as they may not be in the updated item's list
        action: ServiceCaseItemUpdateAction.DELETE_ITEM
      } as ServiceCaseItemUpdate;
    });
  }

  private getDefectUpdates(updatedDefects: IDefect[], defects: IDefect[], job: IJob): ServiceCaseItemUpdate[] {
    const updatedItems = this.getDefectQuantities(defects, job);

    updatedDefects.forEach(defect => {
      // removed items will not be found in updated item's list, they are already listed as removed
      const updatedItem = updatedItems.find(item => item.id === defect.getCode());
      if (updatedItem) {
        // item is not removed or added
        updatedItem.quantity = 0;
        updatedItem.action = ServiceCaseItemUpdateAction.REMAIN_ITEM;
      } else {
        // item is added
        updatedItems.push({
          jobId: job.getExternalId(),
          demandIds: job.getDemandIds(),
          type: ServiceCaseItemType.DEFECT,
          id: defect.getCode(),
          description: defect.getDescription(),
          quantity: 1,
          action: ServiceCaseItemUpdateAction.ADD_ITEM
        } as ServiceCaseItemUpdate);
      }
    });

    return updatedItems.filter(item => item.action !== ServiceCaseItemUpdateAction.REMAIN_ITEM);
  }

  private getPartQuantities(parts: IPart[], job: IJob): ServiceCaseItemUpdate[] {
    return parts.map(part => {
      return {
        jobId: job.getExternalId(),
        demandIds: job.getDemandIds(),
        type: ServiceCaseItemType.PART,
        id: part.getPartNumber(),
        description: part.getDescription(),
        quantity: part.getQuantity(),
        // items initially will be counted as removed as they may not be in the updated item's list
        action: ServiceCaseItemUpdateAction.DELETE_ITEM
      } as ServiceCaseItemUpdate;
    });
  }

  private getPartUpdates(updatedParts: IPart[], parts: IPart[], job: IJob): ServiceCaseItemUpdate[] {
    const updatedItems = this.getPartQuantities(parts, job);

    updatedParts.forEach(part => {
      // removed items will not be found in updated item's list, they are already listed as removed
      const updatedItem = updatedItems.find(item => item.id === part.getPartNumber());
      if (updatedItem) {
        // item is already in the updated item's list, increase quantity
        this.addQuantity(updatedItem, part.getQuantity());
      } else {
        // item is added
        updatedItems.push({
          jobId: job.getExternalId(),
          demandIds: job.getDemandIds(),
          type: ServiceCaseItemType.PART,
          id: part.getPartNumber(),
          description: part.getDescription(),
          quantity: part.getQuantity(),
          action: ServiceCaseItemUpdateAction.ADD_ITEM
        } as ServiceCaseItemUpdate);
      }
    });

    return updatedItems.filter(item => item.action !== ServiceCaseItemUpdateAction.REMAIN_ITEM);
  }

  private getFlatRateUnitQuantities(flatRateUnits: IFlatRateUnit[], job: IJob): ServiceCaseItemUpdate[] {
    const items: ServiceCaseItemUpdate[] = [];

    flatRateUnits.forEach(flatRateUnit => {
      const item = items.find(i =>
        i.type === ServiceCaseItemType.FLAT_RATE_UNIT
        && i.id === flatRateUnit.getNumber()
        && i.description === flatRateUnit.getDescription()
      );
      if (item) {
        item.quantity = item.quantity + flatRateUnit.getAmount();
      } else {
        items.push({
          jobId: job.getExternalId(),
          demandIds: job.getDemandIds(),
          type: ServiceCaseItemType.FLAT_RATE_UNIT,
          id: flatRateUnit.getNumber(),
          description: flatRateUnit.getDescription(),
          quantity: flatRateUnit.getAmount(),
          // items initially will be counted as removed as they may not be in the updated item's list
          action: ServiceCaseItemUpdateAction.DELETE_ITEM
        } as ServiceCaseItemUpdate);
      }
    });

    return items;
  }

  private getFlatRateUnitUpdates(updatedFlatRateUnits: IFlatRateUnit[],
                                 flatRateUnits: IFlatRateUnit[], job: IJob): ServiceCaseItemUpdate[] {
    const updatedItems = this.getFlatRateUnitQuantities(flatRateUnits, job);

    updatedFlatRateUnits.forEach(flatRateUnit => {
      // removed items will not be found in updated item's list, they are already listed as removed
      const updatedItem = updatedItems
        .find(item => item.id === flatRateUnit.getNumber() && item.description === flatRateUnit.getDescription());
      if (updatedItem) {
        // item is already in updated item's list, increase quantity
        this.addQuantity(updatedItem, flatRateUnit.getAmount());
      } else {
        // item is added
        updatedItems.push({
          jobId: job.getExternalId(),
          demandIds: job.getDemandIds(),
          type: ServiceCaseItemType.FLAT_RATE_UNIT,
          id: flatRateUnit.getNumber(),
          description: flatRateUnit.getDescription(),
          quantity: flatRateUnit.getAmount(),
          action: ServiceCaseItemUpdateAction.ADD_ITEM
        } as ServiceCaseItemUpdate);
      }
    });

    return updatedItems.filter(item => item.action !== ServiceCaseItemUpdateAction.REMAIN_ITEM);
  }

  private addQuantity(updatedItem: ServiceCaseItemUpdate, addedQuantity: number): void {
    const totalAddedQuantity =
      (updatedItem.action === ServiceCaseItemUpdateAction.DELETE_ITEM ? -1 : 1) * updatedItem.quantity + addedQuantity;
    if (totalAddedQuantity < 0) {
      // quantity reduced in total
      updatedItem.action = ServiceCaseItemUpdateAction.DELETE_ITEM;
    } else if (totalAddedQuantity > 0) {
      // quantity increased in total
      updatedItem.action = ServiceCaseItemUpdateAction.ADD_ITEM;
    } else {
      // quantity unchanged in total
      updatedItem.action = ServiceCaseItemUpdateAction.REMAIN_ITEM;
    }
    updatedItem.quantity = Math.abs(totalAddedQuantity);
  }

  private logUpdatedItems(updatedItems: ServiceCaseItemUpdate[], origin: string, serviceCase: ServiceCase): void {
    // removed
    if (updatedItems.some(item => item.action === ServiceCaseItemUpdateAction.DELETE_ITEM)) {
      this.editAuditService.sendServiceCaseUpdateEvent(
        serviceCase,
        ServiceCaseItemUpdateAction.DELETE_ITEM,
        updatedItems.filter(item => item.action === ServiceCaseItemUpdateAction.DELETE_ITEM)
      );
    }

    // added
    if (updatedItems.some(item => item.action === ServiceCaseItemUpdateAction.ADD_ITEM)) {
      this.editAuditService.sendServiceCaseUpdateEvent(
        serviceCase,
        ServiceCaseItemUpdateAction.ADD_ITEM,
        updatedItems.filter(item => item.action === ServiceCaseItemUpdateAction.ADD_ITEM)
      );
    }

    // metadata
    this.logUpdatedServiceCase(origin, serviceCase, updatedItems.length > 0 ? updatedItems : undefined);
  }
}
