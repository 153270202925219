import {Injectable} from '@angular/core';
import {
  Customer,
  CustomerInfo,
  CustomerLibConfigurable,
  CustomerWithVehicles
} from '@service-and-repairs/calm-leads-customer-lib-api';
import {AuthenticationTokenBearerService} from '../../../auth/authentication-token-bearer.service';
import {OidcToken} from '../../../auth/oidc/oidc-token';
import {DmsService} from '../../../components/dms-search/dms.service';
import {Util} from '../../../util/util';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {fetchAllCustomersTestData, searchByCustomerNameTestData} from './customer-service-test-data';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  customerLibLoaded: Promise<CustomerLibConfigurable>;

  private customerLib: CustomerLibConfigurable;

  constructor(private dmsService: DmsService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService,
              private userService: UserService) {
    this.customerLibLoaded = new Promise(resolve => {
      this.loadCustomerLibImplementation().then(() => {
        this.configureCustomerLib();
        resolve(this.customerLib);
      });
    });

    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(this.configureCustomerLib.bind(this));
    this.dmsService.dmsSettingsChanged.subscribe(this.configureCustomerLib.bind(this));
  }

  searchByCustomerName(firstName: string, lastName: string): Promise<CustomerWithVehicles[]> {
    if (this.runningOnLocalhost()) {
      return Promise.resolve(searchByCustomerNameTestData);
    }

    if (this.customerLib) {
      return this.customerLib.searchByCustomerName(firstName, lastName);
    } else {
      return Promise.reject(new Error('Customer lib not loaded.'));
    }
  }

  fetchAllCustomers(vin17: string): Promise<CustomerInfo> {
    if (this.runningOnLocalhost()) {
      return Promise.resolve(fetchAllCustomersTestData);
    }

    if (this.customerLib) {
      return this.customerLib.fetchAllCustomers(vin17);
    } else {
      return Promise.reject(new Error('Customer lib not loaded.'));
    }
  }

  subscribeToSetPreferredCustomer(cb: (vin: string, customers: Customer[]) => void): void {
    this.customerLibLoaded.then((): void => {
      this.customerLib.subscribeToSetPreferredCustomer(cb);
    });
  };

  // returns true if AWP is running locally (excluding unit tests)
  private runningOnLocalhost(): boolean {
    return window.location.hostname === 'localhost' &&
      window.location.port !== '9876' &&
      window.location.port !== '9877';
  }

  private loadCustomerLibImplementation(): Promise<void> {
    if (this.customerLib) {
      // already loaded
      return Promise.resolve();
    }
    return new Promise(resolve => Util.attachScript('/ui/customer/customer-impl.min.js')
      .then(() => {
        if (window['calmLeadsCustomerImpl']) {
          this.customerLib = window['calmLeadsCustomerImpl'].makeCustomerLib();
        }
        resolve();
      }));
  }

  private configureCustomerLib(): void {
    const userData: UserData = this.userService.userSubject.getValue();
    const oidcToken: OidcToken = this.authenticationTokenBearerService.oidcTokenSubject.getValue();

    if (userData && oidcToken.access_token) {
      this.customerLib?.configure(
        this.getHeadersForCustomerLib(oidcToken, userData),
        this.dmsService.dmsApi as any,
        undefined
      );
    }
  }

  private getHeadersForCustomerLib(oidcToken: OidcToken, userData: UserData): {[key: string]: string;} {
    const headers = oidcToken.getHeaders();
    headers['Auth-Dist-Partner'] = userData.getBusinessPartner()?.getDistributionPartnerNumber();
    headers['Auth-Outlet'] = userData.getBusinessPartner()?.getOutletNumber();

    return headers;
  }
}
