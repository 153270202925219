import {
  Brand,
  IBusinessPartner,
  IBusinessRelationship,
  User,
  VehicleType
} from '@service-and-repairs/awpintegrationlib';
import {AuthRealm, AuthScheme} from '../../../auth/auth-info';
import {UserSettings} from '../interfaces/user-settings';

export class UserData extends User {

  private newCaseAfterVehicleIdentification: boolean;
  private loginName: string;
  private settingsId: string;

  private caseTabShowCustomer: boolean;
  private caseTabShowModel: boolean;
  private caseTabShowTitle: boolean;
  private caseTabShowVin: boolean;

  getCaseTabShowCustomer(): boolean {
    return this.caseTabShowCustomer;
  }

  setCaseTabShowCustomer(caseTabShowCustomer: boolean): void {
    this.caseTabShowCustomer = caseTabShowCustomer;
  }

  getCaseTabShowModel(): boolean {
    return this.caseTabShowModel;
  }

  setCaseTabShowModel(caseTabShowModel: boolean): void {
    this.caseTabShowModel = caseTabShowModel;
  }

  getCaseTabShowTitle(): boolean {
    return this.caseTabShowTitle;
  }

  setCaseTabShowTitle(caseTabShowTitle: boolean): void {
    this.caseTabShowTitle = caseTabShowTitle;
  }

  getCaseTabShowVin(): boolean {
    return this.caseTabShowVin;
  }

  setCaseTabShowVin(caseTabShowVin: boolean): void {
    this.caseTabShowVin = caseTabShowVin;
  }

  getServiceCaseTabLineCount(): number {
    return [
      this.getCaseTabShowCustomer(),
      this.getCaseTabShowModel(),
      this.getCaseTabShowTitle(),
      this.getCaseTabShowVin()
    ].filter(x => x).length;
  }

  getLoginName(): string {
    return this.loginName;
  }

  setLoginName(loginName: string): void {
    this.loginName = loginName;
  }

  getSettingsId(): string {
    return this.settingsId;
  }

  setSettingsId(id: string): void {
    this.settingsId = id;
  }

  getNewCaseAfterVehicleIdentification(): boolean {
    return this.newCaseAfterVehicleIdentification;
  }

  setNewCaseAfterVehicleIdentification(value: boolean): void {
    this.newCaseAfterVehicleIdentification = value;
  }

  isB2E(): boolean {
    return this.getContext() === 'B2E';
  }

  setSelectedBusiness(businessPartnerId: string, vehicleType: string): void {
    const selectedPartner = this.findSelectedBusinessPartner(businessPartnerId);
    this.setBusinessPartner(selectedPartner);

    // If we had to fall back to a different partner, we might have to fall back to a different vehicle type as well
    const relationship = this.findBusinessRelationship(vehicleType);
    this.setSelectedVehicleType(relationship?.getVehicleType());
  }

  getAuthScheme(): AuthScheme {
    const context = this.getContext();
    switch (context) {
      case 'B2D':
        return 'B2D';
      case 'B2D_INTERNAL':
        return 'B2D';
      case 'B2E':
        return 'B2E';
      default:
        throw new Error(`[Webapp] [User] Could not determine auth scheme for unknown business context "${context}".`);
    }
  }

  getAuthRealm(): AuthRealm {
    return this.getContext() === 'B2D' ? 'internetb2x' : 'intranetb2x';
  }

  // TODO: Refactor:
  // 1. Method getBusinessNumberForVehicleType
  // 2. Method getBusinessNumberForBrand (error if no relationship for brand exists)
  // 3. Method getBusinessNumber(brand?) -> brand ? getBusinessNumberForBrand(brand) : getBusinessNumberForVehicleType()
  /**
   * Returns a business number (BuNo) that matches the user's currently selected business partner and vehicle type.
   * <p>
   * TODO: The problem is that a user can have multiple BuNos for the same vehicle type and even for the same brand.
   *       E.g. one BuNo for BMW and another BuNo for MINI or RR. So far we have no functionally correct solution for
   *       this. Until then we'll return the first BuNo to match BMW and if no BuNo for BMW exists, we'll continue with
   *       MINI, BMW_I and RR in the given order.
   */
  getBusinessNumber(): string {
    return this.getSelectedVehicleType() === VehicleType.MOTORCYCLE
      ? this.getMotorcycleBuNo()
      : this.getPassengerCarBuNo();
  }

  getBrandsOfSelectedBusinessPartner(): Brand[] {
    return this.getBusinessPartner()
      ?.getBusinessRelationships()
      .filter(br => br.getVehicleType() === this.getSelectedVehicleType())
      .map(br => br.getBrands())
      .flat()
      .filter((brand, index, brands) => brands.indexOf(brand) === index) ?? [];
  }

  hasValidBusinessRelationship(): boolean {
    return this.getBusinessPartners()?.some(bp => bp?.getBusinessRelationships()?.some(br => !!br));
  }

  setUserSettings(settings: UserSettings): void {
    this.setDefaultApp(settings.defaultApp);
    this.setKeyReaderId(settings.keyReaderId);
    this.setNewCaseAfterVehicleIdentification(settings.newCaseAfterVehicleIdentification);
    this.setLocale(settings.systemLanguage);

    this.setMileageUnit(settings.mileageUnit);
    this.setTemperatureUnit(settings.temperatureUnit);
    this.setVolumeUnit(settings.volumeUnit);

    this.setCaseTabShowCustomer(settings.caseTabShowCustomer);
    this.setCaseTabShowModel(settings.caseTabShowModel);
    this.setCaseTabShowTitle(settings.caseTabShowTitle);
    this.setCaseTabShowVin(settings.caseTabShowVin);

    if (this.getContext() === 'B2E') {
      this.setSelectedBrands(settings.selectedBrands);
      this.setSelectedCountry(settings.selectedCountry);
    } else {
      this.setDmsPassword(settings.dmsUserPassword);
      this.setDmsUsername(settings.dmsUserId);
      this.setDmsServiceAdvisorId(settings.dmsServiceAdvisorId);
      this.setSelectedBusiness(settings.selectedBusinessPartnerId, settings.selectedVehicleType);
      this.setSelectedVehicleType(this.vehicleTypeFromString(settings.selectedVehicleType));
    }
  }

  private vehicleTypeFromString(value: string): VehicleType {
    // Dhyan: I don't know a more elegant way to get the value of a const enum for a given string.
    let vehicleType = VehicleType.UNKNOWN;
    if (value === VehicleType.PASSENGER_CAR) {
      vehicleType = VehicleType.PASSENGER_CAR;
    } else if (value === VehicleType.MOTORCYCLE) {
      vehicleType = VehicleType.MOTORCYCLE;
    }
    return vehicleType;
  }

  private findSelectedBusinessPartner(businessPartnerId: string): IBusinessPartner {
    // find the business partner with the given id or any business partner with valid business relationships
    const selectedBusinessPartner = this.getBusinessPartners()
      .find(bp => bp?.getBusinessPartnerId() && bp?.getBusinessPartnerId() === businessPartnerId);
    return selectedBusinessPartner || this.getBusinessPartners()
      .find(bp => bp?.getBusinessRelationships().some(br => !!br));
  }

  private findBusinessRelationship(vehicleType: string): IBusinessRelationship {
    // find the business relationship with the given vehicle type or any business relationship with a valid vehicle type
    const selectedBusinessRelationship = this.getBusinessPartner()?.getBusinessRelationships()
      ?.find(br => br?.getVehicleType() && br?.getVehicleType() === vehicleType);
    return selectedBusinessRelationship || this.getBusinessPartner()?.getBusinessRelationships()
      ?.find(br => br?.getVehicleType());
  }

  private getMotorcycleBuNo(): string {
    if (this.findBuNoForBrand(Brand.MOT)) {
      return this.findBuNoForBrand(Brand.MOT);
    }
    return this.findFirstBuNoForVehicleType(VehicleType.MOTORCYCLE);
  }

  private getPassengerCarBuNo(): string {
    const orderedPassengerCarBrands = [Brand.BMW, Brand.MINI, Brand.BMW_i, Brand.RR, Brand.ZINORO];
    for (let brand of orderedPassengerCarBrands) {
      if (this.findBuNoForBrand(brand)) {
        return this.findBuNoForBrand(brand);
      }
    }
    return this.findFirstBuNoForVehicleType(VehicleType.PASSENGER_CAR);
  }

  private findBuNoForBrand(brand: Brand): string {
    const partner = this.getBusinessPartner();
    const relationships = partner?.getBusinessRelationships();
    let buNos = relationships?.filter(r => r.getBrands().indexOf(brand) >= 0).map(r => r.getBusinessNumber()) || [];

    // HST legacy BuNo handling: Some dealers have multiple active BuNos for the same vehicle type and brand.
    // Only one of these BuNos is marked as "default", the other ones should be disregarded and HST is working
    // on removing these non-default BuNos from the HST database. Until then, we have to deal with these incorrect
    // legacy BuNos. However, HST does not provide the "default" flag through its library, so no automatic filtering is
    // possible. The following is a hardcoded list of well-known "non-default" BuNos that we should disregard.
    const dpNo = partner?.getDistributionPartnerNumber();
    const outletNo = partner?.getOutletNumber();
    if (dpNo === '04612' && outletNo === '01' && brand === Brand.BMW) {
      buNos = buNos.filter(buNo => buNo !== '20993');
    }

    return buNos[0];
  }

  private findFirstBuNoForVehicleType(vehicleType: VehicleType): string {
    return this.findBusinessRelationship(vehicleType)?.getBusinessNumber();
  }
}
