import {Injectable} from '@angular/core';
import {CustomerInfo, CustomerWithVehicles} from '@service-and-repairs/calm-leads-customer-lib-api';
import {DmsService} from '../../../components/dms-search/dms.service';
import {ServiceCase} from '../../service-case/models/service-case';
import {CustomerService} from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerLoader {
  constructor(private customerService: CustomerService,
              private dmsService: DmsService) {
  }

  loadCustomerLanguageInServiceCase(serviceCase: ServiceCase): Promise<boolean> {
    if (!serviceCase.getVinLong()
      || serviceCase.getCustomerLanguage()
      || !this.dmsService.dmsOrderHasToBeInCustomerLanguage()) {
      return Promise.resolve(false);
    }

    return new Promise(resolve => {
      this.dmsService.getCustomerLanguage(serviceCase.getVinLong())
        .then(language => {
          if (serviceCase.getCustomerLanguage() !== language) {
            serviceCase.setCustomerLanguage(language);
            resolve(true);
          }
          resolve(false);
        })
        .catch(() => resolve(false));
    });
  }

  loadCustomersInServiceCase(serviceCase: ServiceCase): Promise<boolean> {
    if (!serviceCase.getVinLong()) {
      return Promise.resolve(false);
    }

    return new Promise(resolve => {
      this.customerService.fetchAllCustomers(serviceCase.getVinLong())
        .then((customerInfo: CustomerInfo) => resolve(serviceCase.updateCustomers(customerInfo)))
        .catch((error: Error): void => {
          serviceCase.setCustomerLoadingError(error.message);
          resolve(false);
        });
    });
  }

  getCustomersByName(firstName: string, lastName: string): Promise<CustomerWithVehicles[]> {
    return this.customerService.searchByCustomerName(firstName, lastName);
  }

  loadLicensePlateInServiceCase(serviceCase: ServiceCase): Promise<boolean> {
    if (!serviceCase.getVinLong()) {
      return Promise.resolve(false);
    }

    return new Promise(resolve => {
      this.dmsService.getLicensePlateByVin(serviceCase.getVinLong())
        .then((licensePlate: string) => {
            if (serviceCase.getLicensePlate() !== licensePlate) {
              serviceCase.setLicensePlate(licensePlate);
              resolve(true);
            } else {
              resolve(false);
            }
          }
        )
        .catch(() => resolve(false));
    });
  }

  isCustomerLanguageCheckEnabled(): boolean {
    return this.dmsService.dmsOrderHasToBeInCustomerLanguage();
  }
}
