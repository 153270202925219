import {ColDef, ITooltipParams} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';

export class CustomerSearchColumnDefinitions {
  static getDefaultVehicleColumnDefinition(): ColDef {
    return {
      resizable: true,
      sortable: true,
      tooltipValueGetter: (params: ITooltipParams) => params.value,
    };
  }

  static getVehicleColumnDefinitions(translate: TranslateService): ColDef[] {
    return [
      {
        field: 'numberPlate',
        tooltipField: 'numberPlate',
        headerName: translate.instant('case.licensePlate'),
        headerTooltip: translate.instant('case.licensePlate'),
      },
      {
        field: 'brand',
        tooltipField: 'brand',
        headerName: translate.instant('case.brand'),
        headerTooltip: translate.instant('case.brand'),
      },
      {
        field: 'vin',
        tooltipField: 'vin',
        headerName: translate.instant('vehicle.vin'),
        headerTooltip: translate.instant('vehicle.vin'),
      },
      {
        field: 'modelDesignation',
        tooltipField: 'modelDesignation',
        headerName: translate.instant('vehicle.model'),
        headerTooltip: translate.instant('vehicle.model'),
      }
    ];
  }
}
