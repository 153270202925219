import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {Util} from '../util/util';

/**
 * Measures and logs response times for request-response-pairs.
 */
@Injectable()
export class ResponseTimeInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.performanceLoggingNeeded(req.url)) {
      return next.handle(req);
    }

    const reqTimestampMs = Date.now();
    return next.handle(req).pipe(
      tap((res: HttpResponse<any>) => this.logResponseTime(reqTimestampMs, req, res))
    );
  }

  performanceLoggingNeeded(path: string): boolean {
    return path &&
      !path.startsWith('/api/logcollector/') &&
      !path.startsWith('/api/canal/') &&
      !path.startsWith('/oidc/') &&
      !path.startsWith('/ui/') &&
      !path.startsWith('ui/') &&
      !path.startsWith('/assets/') &&
      !path.endsWith('.js') &&
      !path.endsWith('.js.map') &&
      !path.endsWith('.css') &&
      !path.endsWith('.css.map') &&
      !path.endsWith('.svg') &&
      !path.endsWith('.png') &&
      !path.endsWith('.jpg') &&
      !path.endsWith('.jpeg') &&
      !path.endsWith('.gif') &&
      !path.endsWith('.ico');
  }

  logResponseTime(requestTimestampMs: number, request: HttpRequest<any>, response: HttpResponse<any>): void {
    // Preflight request responses have an undefined status and a duration of 1-5ms, so we filter them out.
    if (response.status) {
      const prefix = Util.logPrefix('Network', 'Http-Res');
      const responseTimestampMs = Date.now();
      const method = ('[' + request.method + ']').padStart(9);
      const url = '[' + request.url + ']';
      const status = '[' + response.status + ']';
      const durationMs = '[' + (responseTimestampMs - requestTimestampMs) + ']';
      console.log(prefix + `${method} ${url} ${status} in ${durationMs}ms`);
    }
  }
}
