import {Injectable} from '@angular/core';
import {Route} from '@angular/router';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {
  Brand,
  IBusinessRelationship,
  IPart,
  Part,
  TransmissionType,
  VehicleType
} from '@service-and-repairs/awpintegrationlib';
import {AuthInfoFactory} from './auth/auth-info-factory';
import {AuthenticationTokenBearerService} from './auth/authentication-token-bearer.service';
import {DmsService} from './components/dms-search/dms.service';
import {IframeComponent} from './components/iframe/iframe.component';
import {WebComponentComponent} from './components/web-component/web-component.component';
import {ConfigurationLoader} from './core/configuration/services/configuration.loader';
import {CustomerService} from './core/customer/services/customer.service';
import {KeyData} from './core/keydata/interfaces/key-data';
import {KeyDataLoader} from './core/keydata/services/key-data.loader';
import {MileageLoader} from './core/mileage/services/mileage.loader';
import {FlatRateUnitGroup} from './core/service-case/interfaces/flat-rate-unit-group';
import {ServiceCase} from './core/service-case/models/service-case';
import {ServiceCaseHolder} from './core/service-case/models/service-case-holder';
import {ServiceCaseManager} from './core/service-case/models/service-case-manager';
import {ServiceCaseUpdater} from './core/service-case/models/service-case-updater';
import {UserData} from './core/user/models/user-data';
import {UserService} from './core/user/services/user.service';
import {IIframeApp, IWebComponentApp} from './interfaces/IApp';
import {EnvironmentUrlKey} from './interfaces/IEnvironment';
import {RouteQueryParamService} from './services/route-query-param.service';

type FlatRateUnitsAddEvent = {
  detail: {
    flatrateGroups: FlatRateUnitGroup[]
  }
}

type PartsSearchPart = {partNumber: string, partDescription: string, quantity: number};
type PartsSearchPartsAddEvent = {
  detail: {
    part: PartsSearchPart,
    additionalParts: PartsSearchPart[]
  }
};

@Injectable({
  providedIn: 'root'
})
export class RouteFactoryService {
  user: UserData;

  constructor(private dmsService: DmsService,
              private configurationLoader: ConfigurationLoader,
              private serviceCaseHolder: ServiceCaseHolder,
              private serviceCaseUpdater: ServiceCaseUpdater,
              private serviceCaseManager: ServiceCaseManager,
              private userService: UserService,
              private routeQueryParamService: RouteQueryParamService,
              private translate: TranslateService,
              private keyDataLoader: KeyDataLoader,
              private customerService: CustomerService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService,
              private mileageLoader: MileageLoader) {
    userService.userSubject.subscribe((user: UserData) => this.user = user);
  }

  static isIframeApp(app: IIframeApp | IWebComponentApp): app is IIframeApp {
    return app && (app as IIframeApp).iFrameUrls !== undefined;
  }

  /**
   * Path parameters for iframe urls have to be set here.
   * Will be overwritten if a client passes a path parameter to navigateTo in the AWP Client Lib.
   *
   * @param app The app shown in the iframe.
   */
  private static getPathParamForIframeApp(app: IIframeApp): string {
    if (app.routingPath === 'air') {
      return 'fork';
    }
    return '';
  }

  private static convertMileageUnitForAir(awpMileageUnit: string): string {
    switch (awpMileageUnit) {
      case 'km':
        return 'K';
      case 'mi':
        return 'M';
      default:
        return 'K';
    }
  }

  // TODO: If possible remove and use method from UserData instead
  static getBusinessNumber(businessRelationships: IBusinessRelationship[], brand?: Brand | string): string {
    if (!businessRelationships || businessRelationships.length == 0) {
      console.warn('Using empty BuNo because the list of business relationships is null or empty.');
      return '';
    }
    if (brand) {
      const relationshipWithMatchingBrand = businessRelationships.filter(r => r.getBrands()?.some((b => b === brand)));
      if (relationshipWithMatchingBrand.length > 0) {
        return relationshipWithMatchingBrand[0].getBusinessNumber();
      }
    }
    return businessRelationships[0].getBusinessNumber();
  }

  getRouteForApp(app: IIframeApp | IWebComponentApp): Route {
    if (RouteFactoryService.isIframeApp(app)) {
      return {
        path: app.routingPath,
        component: IframeComponent,
        data: {
          iFrameUrl: this.getIframeUrlForEnvironment(app),
          webAgentUrl: this.getUrlForWebAgent(app),
          pathParam: RouteFactoryService.getPathParamForIframeApp(app),
          getAttributes: this.getAttributesForApp.bind(this, app),
          appConfiguration: app
        }
      };
    } else {
      // web component app
      return {
        path: app.routingPath,
        component: WebComponentComponent,
        data: {
          webComponentUrls: this.getWebComponentUrlsForEnvironment(app),
          getAttributes: () => {
            return this.getWebComponentAttributes(app);
          },
          getEventListeners: this.getWebComponentEventListeners(app),
          appConfiguration: app
        }
      };
    }
  }

  private getWebComponentAttributes(app: IWebComponentApp): object {
    const cavorsSessionId = sessionStorage.getItem('cavors-session-id');
    const attributes = {
      'environment': environment.deploymentEnvironment,
      'auth-info': AuthInfoFactory.makeAuthInfo(this.user),
      'headers': this.getHeaders(),
      'cavors-session-id': cavorsSessionId || '',
      'analytics-id': cavorsSessionId || '' // legacy attribute name
    };
    return Object.assign(attributes, this.getAttributesForApp(app));
  }

  /**
   * Returns the headers a web component should add to its requests.
   */
  private getHeaders(): {[key: string]: string} {
    const headers = this.authenticationTokenBearerService.oidcTokenSubject.getValue().getHeaders();
    headers['Auth-App-Id'] = 'awp';
    headers['Cavors-Session-Id'] = sessionStorage.getItem('cavors-session-id') || '';
    return headers;
  }

  /**
   * Any parameters for an iframe url or a web component have to be set here.
   *
   * @param app The app shown in the iframe / web component.
   */
  private getAttributesForApp(app: IIframeApp | IWebComponentApp): object {
    const locale = this.user?.getLocale();
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const brand = serviceCase?.getBrand();

    switch (app.routingPath) {
      case 'casedetails':
        return this.getServiceCaseDetailsParams();
      case 'caseoverview':
        return this.getServiceCaseOverviewParams();
      case 'feedbackadmin':
        return this.getFeedbackAdminParams();
      case 'vehicledetails':
        return this.getVehicleDetailsParams();
      case 'diagnosticprotocols':
        return {
          vin: serviceCase.getVinLong()
        };
      case 'feedback':
        return this.getFeedbackParams();
      case 'dealercockpit':
        return this.getDealerCockpitParams();
      case 'etk':
        return {caller: 'AWP'};
      case 'partssearch':
        return this.getPartsSearchParams();
      case 'air':
        return this.getAirParams();
      case 'vehiclehistory':
        return this.getVehicleHistoryParams();
      case 'flatratesearch':
        return this.getFlatRateUnitsParams();
      case 'admincockpit':
      case 'usercockpit':
      case 'ispicockpit':
        return {
          language: locale.substring(0, 2),
          caller: 'awp'
        };
      case 'wvi':
        return this.getParamsForWvi();
      case 'partsordering':
      case 'orderoverview':
      case 'partsinfo':
      case 'partsorderdocuments':
        return {
          caller: 'awp'
        };
      case 'serviceleads': {
        const fleetParams = this.getLeadsParameters();
        Object.assign(fleetParams, {
          'outlet-members': this.user.getOutletPeers(),
          'specialist-groups': this.configurationLoader.getSpecialistGroups(),
          'cosy-base-url': this.getUrlFromEnvironmentConfig('cosy'),
          'los-base-url': this.getUrlFromEnvironmentConfig('los'),
          'scc-appointments-base-url': this.getUrlFromEnvironmentConfig('sccAppointmentsService')
        });
        return fleetParams;
      }
      case 'servicedemand': {
        const serviceDemandParams = this.getLeadsParameters(brand);
        Object.assign(serviceDemandParams, {
          'cosy-base-url': this.getUrlFromEnvironmentConfig('cosy'),
          'check-control-message-base-url': this.getUrlFromEnvironmentConfig('checkControlMessage'),
          'service-planning-base-url': this.getUrlFromEnvironmentConfig('servicePlanning'),
          'remote-key-read-base-url': this.getUrlFromEnvironmentConfig('remoteKeyRead'),
          'measure-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'navigation-map-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'esl-document-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'sel-document-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'sib-document-base-url': this.getUrlFromEnvironmentConfig('airServices'),
          'fault-codes-base-url': this.getUrlFromEnvironmentConfig('faultCodes'),
          'esa-base-url': this.getUrlFromEnvironmentConfig('esa'),
          'scc-appointments-base-url': this.getUrlFromEnvironmentConfig('sccAppointmentsService')
        });
        return serviceDemandParams;
      }
      case 'voiceofcustomer':
        return {
          VIN: this.serviceCaseHolder.getActiveCase()?.getVinLong()
        };
      case 'toros':
        return {
          display: 'MINIMAL',
          Vin: this.serviceCaseHolder.getActiveCase().getVinLong()
        };
      case 'marketconfiguration':
      case 'editingaudit':
        return {
          language: this.user.getLocale()
        };
      case 'servicepreparation':
        return this.getServicePreparationParameter(serviceCase);
      default:
        return {};
    }
  }

  private getServicePreparationParameter(serviceCase: ServiceCase): object {
    return {
      gCID: serviceCase?.getGcdmCustomerId(),
      brand: serviceCase?.getBrand(),
      vin: serviceCase?.getVinLong()
    };
  }

  private getFeedbackParams(): object {
    return {
      'app-list': this.userService.getVisibleApps()
        .map(a => (
          {
            id: a.titleResource,
            label: this.translate.instant(a.titleResource)
          }
        ))
    };
  }

  private getFeedbackAdminParams(): object {
    return {
      'app-list': environment.categories
        .flatMap(c => c.apps)
        .map(a => (
          {
            id: a.titleResource,
            label: this.translate.instant(a.titleResource)
          }
        )),
      'page-size': 50,
      language: this.user.getLocale()
    };
  }

  private getDealerCockpitParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const params = {
      LANG_ISO: this.user?.getLocale().substring(0, 2).toLowerCase(),
      COUNTRY_ISO: this.user?.getB2XCountryCode().toLowerCase(),
      applicationIndicator: 'AWP'
    };
    const vin = serviceCase?.getVinLong() ?? serviceCase?.getVinShort();
    if (vin) {
      Object.assign(params, {VIN17: vin});
      Object.assign(params, {BRAND: serviceCase?.getBrand()});
    }
    if (this.user?.getContext() !== 'B2E') {
      Object.assign(params, {
        USER_ID: this.user?.getBusinessPartner()?.getDistributionPartnerNumber() +
          '_' +
          this.user?.getBusinessPartner()?.getOutletNumber()
      });
    }
    return params;
  }

  private getLeadsParameters(brand?: Brand | string): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const businessPartner = this.user?.getBusinessPartner();
    const leadsBusinessPartner = {
      'dpNo': businessPartner?.getDistributionPartnerNumber(),
      'businessRelationships': businessPartner?.getBusinessRelationships().map((r) => ({
        'buno': r.getBusinessNumber(),
        'brands': r.getBrands()
      }))
    };
    const businessPartnerIds = this.user?.getBusinessPartners().map(bp => bp.getBusinessPartnerId());
    const keyData: KeyData = this.keyDataLoader
      .getKeyDataForVin(this.serviceCaseHolder.getActiveCase()?.getVinLong());

    return {
      vin: serviceCase?.getVinLong(),
      'distribution-partner-number': businessPartner?.getDistributionPartnerNumber(),
      'outlet-number': businessPartner?.getOutletNumber(),
      'business-number': RouteFactoryService.getBusinessNumber(businessPartner?.getBusinessRelationships(), brand),
      'distance-unit': this.user.getMileageUnit(),
      country: this.user.getB2XCountryCode(),
      language: this.user.getLocale(),
      brand: brand?.toString(),
      'mileage': serviceCase?.getMileageRecord(),
      'mileage-in-kilometers': serviceCase?.getCurrentMileageInKilometers(),
      'business-partner': leadsBusinessPartner,
      'business-partners': businessPartnerIds.join(','),
      'battery-state-of-charge-in-percent': keyData?.batteryStateOfChargeInPercent,
      'fuel-tank-level-in-liter': keyData?.fuelTankLevelInLiter,
      'mileage-in-kilometers-from-latest-key-read': keyData?.totalDistanceInKm,
      'data-read-date': keyData?.readInDate,
      'data-read-provider': keyData?.keyOrigin,
      'user-full-name': this.user?.getName()
    };
  }

  private getServiceCaseDetailsParams(): object {
    const params = {};
    if (!this.user?.isB2E()) {
      Object.assign(params, {
        'specialist-groups': this.configurationLoader.getSpecialistGroups(),
        'dms-api': this.dmsService.dmsApi,
        'dms-settings': this.dmsService.dmsSettings
      });
    }
    Object.assign(params, {
      'parts-ordering': this.userService.appVisibleForUser('partsordering'),
      'show-flat-rate-unit-amount': this.configurationLoader.showFlatRateUnitValues(),
      'leads-dealer-config': this.configurationLoader.getLeadsDealerConfig(),
      'user-data': this.user,
      'active-case-data': this.serviceCaseHolder.getActiveCase()
    });
    return params;
  }

  private getServiceCaseOverviewParams(): object {
    return {
      'user-data': this.user,
      'specialist-groups': this.configurationLoader.getSpecialistGroups()
    };
  }

  private getVehicleDetailsParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const latestKeyData = this.keyDataLoader.getKeyDataForVin(serviceCase.getVinLong());
    return {
      campaigns: serviceCase?.getCampaigns(),
      'service-contracts': serviceCase?.getServiceContracts(),
      'warranty-restrictions': serviceCase?.getWarrantyRestrictions(),
      'vehicle-warnings': serviceCase?.getWarnings(),
      'kasio-base-url': this.getUrlFromEnvironmentConfig('remoteKeyRead'),
      'user': this.user,
      // required in addition to user as Webapp determines a single business number
      'business-number': this.user?.getBusinessNumber(),
      'key-data-read-date': latestKeyData?.readInDate || '',
      'vehicle': serviceCase?.getVehicle()
    };
  }

  private getAirWebComponentParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const businessNumber = RouteFactoryService
      .getBusinessNumber(this.user?.getBusinessPartner()?.getBusinessRelationships(), serviceCase?.getBrand());
    const token = this.authenticationTokenBearerService.oidcTokenSubject.getValue();

    const airWebComponentParams = {
      language: this.user?.getLocale(),
      'app-id': 'awp',
      vin: serviceCase?.getVinLong(),
      'auth-scheme': token.business_context.substring(0, 3),
      'auth-type': 'WEN_TOKEN',
      'access-token': token.access_token
    };
    if (this.user?.isB2E()) {
      Object.assign(
        airWebComponentParams,
        {brands: AuthInfoFactory.awpBrandsToCsslBrands(this.user.getSelectedBrands()).join(',')}
      );
      Object.assign(airWebComponentParams, {country: this.user?.getB2XCountryCode()});
    } else {
      Object.assign(airWebComponentParams, {'dealer-code': businessNumber});
    }
    return airWebComponentParams;
  }

  private getFlatRateUnitsParams(): object {
    const fruParams = this.getAirWebComponentParams();
    Object.assign(fruParams, {'flatrate-service-base-url': this.getUrlFromEnvironmentConfig('airServices')});
    Object.assign(fruParams, {'show-packages-button': false});
    Object.assign(fruParams, {'show-repair-instructions-button': false});
    Object.assign(fruParams, {'show-defect-codes-button': false});
    Object.assign(fruParams, {'show-flatrate-values': this.configurationLoader.showFlatRateUnitValues()});

    return fruParams;
  }

  private getVehicleHistoryParams(): object {
    const historyParams = this.getAirWebComponentParams();
    Object.assign(historyParams, {'base-url': this.getUrlFromEnvironmentConfig('airServices')});
    Object.assign(
      historyParams,
      {'mileage-unit': RouteFactoryService.convertMileageUnitForAir(this.user?.getMileageUnit())}
    );
    if (this.isWarrantyHistoryDisabledInUserCountry()) {
      Object.assign(historyParams, {'provide-warranty-history': false});
    }
    if (!this.user?.isB2E()) {
      Object.assign(
        historyParams,
        {'allow-delete-service-history-entry': this.configurationLoader.isServiceHistoryDeletionAllowed()}
      );
    }

    return historyParams;
  }

  private isWarrantyHistoryDisabledInUserCountry() {
    // General dealers in the asian markets China, Japan and Korea must not see the warranty history
    return ['KR', 'JP', 'CN'].includes(this.user.getB2XCountryCode());
  }

  private getParamsForWvi(): object {
    const accessToken = this.authenticationTokenBearerService.oidcTokenSubject.getValue().access_token;
    const businessContext = this.user?.getContext();
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    return {
      level: 'LVL01',
      vehicleInfoCB: 'on',
      customerInfoCB: 'on',
      optionInfoCB: 'on',
      inReviewClaimInfoCB: 'on',
      claimInfoCB: 'on',
      refusedClaimInfoCB: 'on',
      correctionClaimInfoCB: 'on',
      mysteryShopperClaimInfoCB: 'on',
      vehicleCommentCB: 'on',
      contractInfoCB: 'on',
      eligibleContractCB: 'on',
      campaignInfoCB: 'on',
      damageDisclosureInfoCB: 'on',
      chassisNumber: serviceCase?.getVinLong(),
      ChassisNumbers: serviceCase?.getVinLong(),
      token: accessToken,
      businessContext: businessContext,
      vinContext: 'N'
    };
  }

  private getAirParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const params = {};
    this.routeQueryParamService.addMandatoryAirParams(params);
    if (serviceCase?.getVinLong()) {
      Object.assign(params, {VIN: serviceCase?.getVinLong()});
    } else if (serviceCase?.getVinShort()) {
      Object.assign(params, {VIN: serviceCase?.getVinShort()});
    } else if (serviceCase?.getTypeCode()) {
      Object.assign(params, {TYPE_CODE: serviceCase?.getTypeCode()});
    }
    return params;
  }

  private getPartsSearchParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const vehicle = serviceCase?.getVehicle();

    const vehicleObject = {};

    if (vehicle?.getVin()) {
      Object.assign(vehicleObject, {vin: vehicle?.getVin()});
    }

    if (vehicle?.getVehicleType() === VehicleType.PASSENGER_CAR) {
      Object.assign(vehicleObject, {productType: 'CAR'});
    } else if (vehicle?.getVehicleType()) {
      Object.assign(vehicleObject, {productType: vehicle?.getVehicleType()});
    }

    if (vehicle?.getBrand() === Brand.RR) {
      Object.assign(vehicleObject, {brand: 'ROLLS_ROYCE'});
    } else if (vehicle?.getBrand() === Brand.BMW_i) {
      Object.assign(vehicleObject, {brand: 'BMW_I'});
    } else if (vehicle?.getBrand() === Brand.MOT) {
      Object.assign(vehicleObject, {brand: 'BMW'});
    } else if (vehicle?.getBrand()) {
      Object.assign(vehicleObject, {brand: vehicle?.getBrand()});
    }

    if (vehicle?.getTransmissionType() === TransmissionType.AUTOMATIC_TRANSMISSION) {
      Object.assign(vehicleObject, {transmissionType: 'AUTOMATIC'});
    } else if (vehicle?.getTransmissionType() === TransmissionType.MANUAL_TRANSMISSION) {
      Object.assign(vehicleObject, {transmissionType: 'MANUAL'});
    } else if (vehicle?.getTransmissionType() === TransmissionType.UNKNOWN) {
      Object.assign(vehicleObject, {transmissionType: 'NEUTRAL'});
    }

    if (vehicle?.getTypeCode()) {
      Object.assign(vehicleObject, {typeCode: vehicle?.getTypeCode()});
    }

    if (vehicle?.getProductionDate()) {
      Object.assign(vehicleObject, {productionDate: vehicle?.getProductionDate()});
    }

    if (vehicle?.getUpholsteryCode()) {
      Object.assign(vehicleObject, {upholsteryCode: vehicle?.getUpholsteryCode()});
    }

    if (vehicle?.getPaint()) {
      Object.assign(vehicleObject, {paint: vehicle?.getPaintNumber()});
    }

    if (vehicle?.getEquipmentOptions()) {
      const equipmentOptionCodes: string[] = vehicle?.getEquipmentOptions().map(option => option.getCode());
      Object.assign(vehicleObject, {equipmentOptions: equipmentOptionCodes});
    }

    return {
      language: this.user.getLocale(),
      vehicle: vehicleObject,
      'base-url': this.getUrlFromEnvironmentConfig('carsServices'),
      'service-case-id': serviceCase?.getExternalId(),
      'session-id': sessionStorage.getItem('cavors-session-id')
    };
  }

  getUrlFromEnvironmentConfig(key: EnvironmentUrlKey): string {
    if (!environment.urls[key]) {
      console.warn('Route Factory Service: URL "' + key + '" not found in environment.ts.');
      return undefined;
    }
    const context = this.user?.getContext();
    switch (context) {
      case 'B2E':
        return environment.urls[key]?.b2e;
      case 'B2D':
        return environment.urls[key]?.b2d;
      case 'B2D_INTERNAL':
        return environment.urls[key]?.b2dIntranet;
      default:
        return undefined;
    }
  }

  private getIframeUrlForEnvironment(app: IIframeApp): string {
    const context = this.user?.getContext();
    if (context === 'B2D') {
      return app.iFrameUrls.b2d;
    } else if (context === 'B2D_INTERNAL') {
      return app.iFrameUrls.b2dIntranet;
    } else {
      return app.iFrameUrls.b2e;
    }
  }

  private getWebComponentUrlsForEnvironment(app: IWebComponentApp): string[] {
    const context = this.user?.getContext();
    let urls: string | string[];
    if (context === 'B2D') {
      urls = app.webComponentUrls.b2d;
    } else if (context === 'B2D_INTERNAL') {
      urls = app.webComponentUrls.b2dIntranet;
    } else {
      urls = app.webComponentUrls.b2e;
    }
    if (!urls) {
      return [];
    } else if (typeof urls === 'string') {
      return [urls];
    } else {
      return urls;
    }
  }

  private getUrlForWebAgent(app: IIframeApp): string {
    if (!app.webAgentSecuredPopupUrls || !this.user) {
      return '';
    }
    if (this.user.getContext() === 'B2D') {
      return app.webAgentSecuredPopupUrls.b2d || '';
    } else if (this.user.getContext() === 'B2D_INTERNAL') {
      return app.webAgentSecuredPopupUrls.b2dIntranet || '';
    } else {
      return app.webAgentSecuredPopupUrls.b2e || '';
    }
  }

  private getWebComponentEventListeners(app: IWebComponentApp): object {
    if (app.routingPath === 'flatratesearch') {
      return {
        addToList: (event: FlatRateUnitsAddEvent): void =>
          this.serviceCaseUpdater.addFlatRateUnitsFromFlatRateSearch(event.detail.flatrateGroups)
      };
    } else if (app.routingPath === 'servicedemand' || app.routingPath === 'serviceleads') {
      return {
        ready: (event: Event): void => {
          // set dmsApi on leads-cockpit-vehicle and leads-cockpit-fleet once it is ready
          event.target['api'].setDmsInstance?.(this.dmsService.dmsApi);

          // also set customer lib once it has loaded
          this.customerService.customerLibLoaded.then(customerLib =>
            event.target['api'].setCustomerLibInstance?.(customerLib));
        },
        requestMileageUpdate: (): void => {
          this.mileageLoader.loadMileage(this.serviceCaseHolder.getActiveCase()).finally();
        }
      };
    } else if (app.routingPath === 'partssearch') {
      return {
        addPartsEvent: (event: PartsSearchPartsAddEvent) => {
          const parts = [this.mapPartFromPartsSearch(event.detail.part)];
          event.detail.additionalParts.forEach(part => parts.push(this.mapPartFromPartsSearch(part)));
          this.serviceCaseUpdater.addPartsFromPartSearch(parts);
        }
      };
    } else if (app.routingPath === 'caseoverview') {
      return {
        metaDataUpdated: (event: CustomEvent) => {
          const serviceCaseId = event.detail;
          if (this.serviceCaseHolder.isServiceCaseOpen(serviceCaseId)) {
            this.serviceCaseManager.reloadServiceCaseById(serviceCaseId, false);
          }
        }
      };
    } else {
      return {};
    }
  }

  private mapPartFromPartsSearch(part: PartsSearchPart): IPart {
    return new Part(
      part.partNumber.slice(0, 2),
      part.partNumber.slice(2, 4),
      part.partNumber.slice(4),
      part.partDescription,
      part.quantity
    );
  }
}
