import {CsslBrands} from './cssl/cssl-brands';

export type AuthRealm = 'internetb2x' | 'intranetb2x';
export type AuthScheme = 'B2D' | 'B2E';

/**
 * This class represents all the authentication information that the AWP is passing on to embedded web components
 * starting in October 2022. Technically the information will be passed as an HTML attribute called "auth-info" and
 * the value will be the serialized form of an AuthInfo object.
 */
export class AuthInfo {
  wenOidcAccessToken: string; // Access token (short string)
  csslToken: string; // JWT (long string)
  distributionPartnerNumber: string; // e.g. 55555, undefined for B2E
  outletNumber: string; // e.g. 01, undefined for B2E
  businessNumber: string; // e.g. 55555, undefined for B2E
  authScheme: AuthScheme;
  authRealm: AuthRealm;
  countryCode: string; // Two-letter, upper-case ISO 3166-1 alpha-2 country code, e.g. DE
  brands: CsslBrands[]; // all brands of selected business partner and vehicle type
  roles: string[]; // all user roles
}
