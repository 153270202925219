import {Component, OnInit} from '@angular/core';
import {IVehicle} from '@service-and-repairs/awpintegrationlib';
import {UserData} from '../../../core/user/models/user-data';
import {UserService} from '../../../core/user/services/user.service';

@Component({
  selector: 'app-vehicle-selection',
  templateUrl: './vehicle-selection-overlay.component.html',
  styleUrls: ['./vehicle-selection-overlay.component.scss']
})
export class VehicleSelectionOverlayComponent implements OnInit {

  visible = false;
  vehicleList: IVehicle[];
  locale: string;

  private selectionCallback: (vehicle: IVehicle) => void;
  private cancelCallback: () => void;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.userSubject.subscribe((user: UserData) => this.locale = user?.getLocale());
  }

  vehicleSelected(vehicle: IVehicle): void {
    this.visible = false;
    this.selectionCallback?.(vehicle);
  }

  show(vehicleList: IVehicle[]): Promise<IVehicle> {
    this.vehicleList = vehicleList;
    this.visible = true;
    return new Promise<IVehicle>((resolve, reject) => {
      this.selectionCallback = resolve;
      this.cancelCallback = reject;
    });
  }

  close(): void {
    this.visible = false;
    this.cancelCallback?.();
  }
}
